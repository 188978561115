<template>
    <div class="flex flex-col gap-4">
        <div class="flex gap-4 justify-between items-baseline">
            <h1 class="heading-md sm:heading-lg">
                {{ $t('general.baskets') }}
            </h1>
            <router-link
                to="/pending-baskets"
                class="underline capitalize"
            >
                {{ $t('general.allPendingBaskets') }}
            </router-link>
        </div>
        <div
            v-if="dataLoaded"
            class="grid grid-cols-[repeat(auto-fit,minmax(33rem,1fr))] sm:grid-cols-[repeat(auto-fit,minmax(36rem,1fr))] gap-8 sm:gap-12 items-center"
        >
            <router-link
                :to="`/customers/${customerNo}/orders/new?type=salesOrder`"
                class="hidden sm:flex flex-col gap-8 items-center justify-center h-[17rem] p-4 sm:p-8 border rounded-3xl text-lg sm:text-xl font-bold"
            >
                <i class="fa fa-plus fa-xl" />
                <span class="text-center truncate w-full">{{ $t('baskets.createNewBasket') }}</span>
            </router-link>
            <router-link
                :to="`/customers/${customerNo}/orders/new?type=salesOrder`"
                class="sm:hidden"
            >
                <Button
                    :text="$t('baskets.createNewBasket')"
                    kind="primary-alt"
                    class="w-full"
                >
                    <i class="fa fa-plus" />
                </Button>
            </router-link>
            <DashboardBasketCard
                v-for="basket in currentBaskets"
                :key="basket.no"
                :basket="basket"
                :is-preorder-templates-enabled="isPreorderTemplatesEnabled"
            />
        </div>
        <Spinner
            v-else
            icon-css-class="fa-lg"
        />
    </div>
</template>

<script>
import Button from '@/components/button/Button.vue';
import Spinner from '@/components/spinner/Spinner.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { getCurrentBasket } from '@/modules/baskets/baskets.api.js';
import DashboardBasketCard from '@/modules/dashboard/DashboardBasketCard.vue';
import { getCurrentPreorderTemplateBasket } from '@/modules/offers/offers.api.js';

export default {
    name: 'DashboardBaskets',

    components: {
        DashboardBasketCard,
        Spinner,
        Button,
    },

    props: {
        customerNo: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            dataLoaded: false,
            currentBaskets: null,
        };
    },

    computed: {
        isPreorderTemplatesEnabled() {
            return this.$store.state.subsidiary.offersEnabled;
        },
    },

    created() {
        this.fetchCurrentBasketPerOrderJourney();
    },

    methods: {
        async fetchCurrentBasketPerOrderJourney() {
            try {
                const getCurrentBasketRequests = this.$store.getters.availableOrderJourneys.map(({ value: orderJourney }) => () => getCurrentBasket({ orderJourney }));

                if (this.isPreorderTemplatesEnabled) {
                    getCurrentBasketRequests.push(() => getCurrentPreorderTemplateBasket());
                }

                const currentBasketResponses = await Promise.allSettled(getCurrentBasketRequests.map(request => request()));

                this.currentBaskets = currentBasketResponses.flatMap(({ status, value }) => status === 'fulfilled' ? [value.data] : []).sort((a, b) => a.orderJourney.localeCompare(b.orderJourney));

                this.dataLoaded = true;
            } catch (error) {
                bugsnag.notify(error);
            }
        },
    },
};
</script>
