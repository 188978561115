<template>
    <DropDownMenu>
        <template #title>
            {{ $t('adminMainMenu.toolBox') }}
        </template>
        <template #dropdown>
            <li>
                <router-link to="/toolbox/e-catalog-management">
                    {{ $t('adminMainMenu.eCatalog') }}
                </router-link>
            </li>
            <li>
                <router-link to="/toolbox/baskets">
                    {{ $t('adminBaskets.menuItem') }}
                </router-link>
            </li>
            <li>
                <router-link to="/toolbox/item-visibility-checker">
                    {{ $t('adminMainMenu.itemcheck') }}
                </router-link>
            </li>
            <li v-if="offersManagementAvailable">
                <router-link to="/toolbox/pre-order-management">
                    {{ $t('offers.offerManagement') }}
                </router-link>
            </li>
            <li v-if="canManageFoc">
                <router-link to="/toolbox/foc-management">
                    {{ $t('adminMainMenu.focManager') }}
                </router-link>
            </li>
            <li v-if="blogManagerAvailable">
                <router-link to="/blog-manager">
                    {{ $t('adminMainMenu.blogManager') }}
                </router-link>
            </li>
            <li v-if="blogAvailable">
                <router-link to="/blog">
                    {{ $t('adminMainMenu.blog') }}
                </router-link>
            </li>
            <li v-if="disputesAvailable">
                <router-link to="/toolbox/dispute-workstation">
                    {{ $t('disputes.disputeWorkstation') }}
                </router-link>
            </li>
            <li v-if="canManageSDP">
                <router-link to="/toolbox/sdp-management">
                    {{ $t('sdpManagement.titleSdpManagement') }}
                </router-link>
            </li>
            <li v-if="canManageTeams">
                <router-link to="/toolbox/teams">
                    {{ $t('adminMainMenu.teams') }}
                </router-link>
            </li>
            <li v-if="canManageWorkflowCases && !canManageWorkflows">
                <router-link to="/toolbox/workflow-cases">
                    {{ $t('workflowCases.titleListWorkflowCases') }}
                </router-link>
            </li>
            <li v-else-if="canManageWorkflows">
                <router-link to="/toolbox/workflow-management">
                    {{ $t('adminMainMenu.workflows') }}
                </router-link>
            </li>
        </template>
    </DropDownMenu>
</template>

<script>
import DropDownMenu from '@/components/menu/DropDownMenu.vue';
import { Const } from '@/utils/constants';

export default {
    name: 'ToolboxDropdownMenu',

    components: {
        DropDownMenu,
    },

    computed: {
        offersManagementAvailable() {
            return this.$store.state.subsidiary.offersEnabled && this.$store.getters.getUserCan(Const.PERMISSION_CAN_MANAGE_OFFERS);
        },

        canManageFoc() {
            return this.$store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_FOC);
        },

        blogManagerAvailable() {
            return this.$store.state.subsidiary.blogEnabled && this.$store.getters.getUserCan(Const.PERMISSION_CAN_MANAGE_BLOG);
        },

        blogAvailable() {
            return this.$store.state.subsidiary.blogEnabled;
        },

        disputesAvailable() {
            return this.$store.state.subsidiary.disputesEnabled;
        },

        canManageSDP() {
            const { PERMISSIONS, SDP_SCORING_STRATEGY } = Const;

            switch (this.$store.state.subsidiary.sdpScoringStrategy) {
                case SDP_SCORING_STRATEGY.QUESTIONNAIRE:
                    return [PERMISSIONS.CAN_MANAGE_CUSTOMER_SDPS, PERMISSIONS.CAN_DOWNLOAD_SDP_REPORTS].some(this.$store.getters.getUserCan);
                case SDP_SCORING_STRATEGY.DIRECT:
                    return [PERMISSIONS.CAN_MANAGE_CUSTOMER_SDPS, PERMISSIONS.CAN_MAINTAIN_CUSTOMER_SDPS, PERMISSIONS.CAN_DOWNLOAD_SDP_REPORTS].some(this.$store.getters.getUserCan);
                default:
                    return false;
            }
        },

        canManageTeams() {
            return this.$store.state.subsidiary.isTeamsManagementEnabled && this.$store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_TEAMS);
        },

        isWorkflowManagementEnabled() {
            return this.$store.state.subsidiary.isWorkflowManagementEnabled;
        },

        canManageWorkflows() {
            return this.isWorkflowManagementEnabled && this.$store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_WORKFLOWS);
        },

        canManageWorkflowCases() {
            return this.isWorkflowManagementEnabled;
        },
    },
};
</script>
