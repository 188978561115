import api from '@/api/api.js';

export function getProducts(params) {
    return api.get('products', { params });
}

export function getOfferProducts(params) {
    return api.get('products/offers', { params });
}

export function getCallOffOrderProducts(params) {
    return api.get('products/call-off-order', { params });
}

export function getProductFilters(params) {
    return api.get('products/filters', { params });
}

export function getProductPrices(payload, config) {
    return api.post('products/prices', payload, config);
}
