import Login from '@/modules/unauthenticated/login/Login.vue';

const unauthenticatedRoutes = [
    {
        path: 'login',
        name: 'login',
        component: Login,
    },
    {
        path: 'forgotten-username',
        name: 'forgottenUsername',
        component: () => import('@/modules/unauthenticated/forgotten-username/ForgottenUsername.vue'),
    },
    {
        path: 'password-reset-request',
        name: 'passwordResetRequest',
        component: () => import('@/modules/unauthenticated/password-reset-request/PasswordResetRequest.vue'),
    },
    {
        path: 'password-reset',
        name: 'passwordReset',
        component: () => import('@/modules/unauthenticated/password-reset/PasswordReset.vue'),
        props: ({ query }) => ({ selectedSubsidiaryKey: query.subsidiary, username: query.username, code: query.code }),
    },
    {
        path: 'legacy-customer-verification',
        name: 'legacyCustomerVerification',
        component: () => import('@/modules/unauthenticated/legacy-customer-verification/LegacyCustomerVerification.vue'),
    },
    {
        path: 'legacy-customer-update/:username',
        name: 'legacyCustomerUpdate',
        component: () => import('@/modules/unauthenticated/legacy-customer-verification/LegacyCustomerUpdate.vue'),
        props: true,
    },
];

export {
    unauthenticatedRoutes,
};
