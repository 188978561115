<template>
    <picture>
        <source :srcset="`${imageSource}&fmt=webp`">
        <img
            :src="`${imageSource}&fmt=jpeg`"
            :class="imgCssClass"
            :loading="loading"
            :decoding="decoding"
            :width="width || undefined"
            :height="height || undefined"
            :alt="alt || undefined"
            :title="title || undefined"
            @mouseenter="onMouseEnter"
            @load="onLoad"
            @error="onError($event)"
        >
    </picture>
</template>

<script>
import { Const } from '@/utils/constants.js';

const SIZE_MULTIPLIER = 0.2;

// For image formatting params see https://experienceleague.adobe.com/docs/dynamic-media-developer-resources/image-serving-api/image-serving-api/http-protocol-reference/command-reference/c-command-reference.html
export default {
    name: 'ProductImage',

    inheritAttrs: false,

    props: {
        brand: {
            type: String,
            required: true,
            validator(value) {
                const isValid = Object.values(Const.BRANDS).includes(value);

                if (!isValid) {
                    console.warn(`Invalid brand provided: '${value}'`);
                }

                return isValid;
            },
        },

        url: {
            type: String,
            default: Const.FAKE_FALLBACK_IMAGE_URL, // It is possible that some products don't have corresponding DAM images so the URL is not stored in B2B database
        },

        width: {
            type: Number,
            default: null,
        },

        height: {
            type: Number,
            default: null,
        },

        fit: {
            type: String,
            default: 'fit',
            validator(value) {
                const isValid = ['fit', 'constrain', 'crop', 'wrap', 'stretch', 'hfit', 'vfit'].includes(value);

                if (!isValid) {
                    console.warn(`Invalid fit prop provided: '${value}'`);
                }

                return isValid;
            },
        },

        resamplingMode: {
            type: String,
            default: 'bisharp',
            validator(value) {
                const isValid = ['bilin', 'bicub', 'sharp2', 'bisharp'].includes(value);

                if (!isValid) {
                    console.warn(`Invalid image resampling format provided: '${value}'`);
                }

                return isValid;
            },
        },

        imageFormat: {
            type: String,
            default: 'webp',
            validator(value) {
                const isValid = ['webp', 'webp-alpha', 'jpg'].includes(value);

                if (!isValid) {
                    console.warn(`Invalid image format provided: '${value}'`);
                }

                return isValid;
            },
        },

        loading: {
            type: String,
            default: 'eager',
            validator(value) {
                const isValid = ['eager', 'lazy'].includes(value);

                if (!isValid) {
                    console.warn(`Invalid loading prop provided: '${value}'`);
                }

                return isValid;
            },
        },

        decoding: {
            type: String,
            default: 'auto',
            validator(value) {
                const isValid = ['sync', 'async', 'auto'].includes(value);

                if (!isValid) {
                    console.warn(`Invalid decoding prop provided: '${value}'`);
                }

                return isValid;
            },
        },

        alt: {
            type: String,
            default: '',
        },

        title: {
            type: String,
            default: '',
        },

        imgCssClass: {
            type: [String, Array],
            default: '',
        },
    },

    emits: [
        'mouseenter',
        'load',
        'error',
    ],

    computed: {
        imageSource() {
            // Requesting larger image defined by SIZE_MULTIPLIER, in order to have higher pixel density and sharper rendered image
            const width = `${this.width ? `&wid=${Math.round(this.width + (this.width * SIZE_MULTIPLIER))}` : ''}`;
            const height = `${this.height ? `&hei=${Math.round(this.height + (this.height * SIZE_MULTIPLIER))}` : ''}`;

            return `${this.url}?${width}${height}&fit=${this.fit}&resMode=${this.resamplingMode}&qlt=80`;
        },

        fallbackImageSource() {
            return this.brand === Const.BRANDS.ALPHA_ONITSUKA_TIGER ? Const.FALLBACK_IMAGE_URL.ONITSUKA_TIGER.SMALL : Const.FALLBACK_IMAGE_URL.ASICS.SMALL;
        },
    },

    methods: {
        onMouseEnter() {
            this.$emit('mouseenter');
        },

        onLoad() {
            this.$emit('load');
        },

        onError(event) {
            event.target.previousElementSibling.srcset = this.fallbackImageSource;

            this.$emit('error');
        },
    },
};
</script>
