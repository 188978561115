<template>
    <transition :name="name">
        <slot />
    </transition>
</template>

<script>
export default {
    name: 'FadeTransition',

    props: {
        name: {
            type: String,
            default: 'fade',
            validator(value) {
                const isValid = ['fade', 'slide-fade'].some(name => name === value);

                if (!isValid) {
                    console.warn(`Invalid transition name '${value}'`);
                }

                return isValid;
            },
        },
    },
};
</script>

<style scoped>
/* transitions opacity property from 0 to 1 */
.fade-enter-active {
    transition: opacity 0.3s ease-in;
}

.fade-leave-active {
    transition: opacity 0.2s ease-out;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

/* Slides an element from the right and transitions opacity property from 0 to 1 */
.slide-fade-enter-active {
    transition: all 0.3s ease-in;
}
.slide-fade-leave-active {
    transition: transform 0.3s ease-out;
}
.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(100%);
    opacity: 0;
}
</style>
