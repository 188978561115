<template>
    <div class="text-base">
        <div
            v-if="selectedSubsidiary"
            class="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-8 md:px-[5rem] md:py-12 w-full md:w-[50rem] md:bg-white md:rounded-3xl md:shadow-2xl z-10"
        >
            <header class="flex flex-col gap-4 items-center mb-[4rem]">
                <AsicsLogoLarge />
                <div class="flex flex-col items-center mt-4">
                    <h1 class="md:heading-lg">
                        {{ $t('authentication.heading') }}
                    </h1>
                    <h2 class="md:heading-md font-normal !m-0">
                        {{ $t(`general.subsidiaryDescription.${selectedSubsidiary.subsidiaryDescriptionKey}`) }}
                    </h2>
                </div>
            </header>

            <router-view :selected-subsidiary-key="selectedSubsidiary.key" />

            <footer class="grid md:grid-cols-2 justify-items-center md:justify-items-start md:justify-between mt-8 relative">
                <div class="flex items-center gap-4">
                    <span class="font-bold">{{ localisedCountryName }}</span>
                    <ButtonPlain
                        class="font-normal underline"
                        @click.native="openSubsidiarySelectionDialog"
                    >
                        {{ $t('general.buttonChange') }}
                    </ButtonPlain>
                </div>
                <LanguageSelector
                    class="justify-center md:ml-auto"
                    :user-locale="userLocale"
                    :subsidiary-locales="selectedSubsidiary.locales"
                />
            </footer>
        </div>
        <div class="bg-gradient-to-t from-[#485CC7] from-[-8.86%] via-[#AB9FBE] via-[113.56%] to-[#221572] to-[65.74%] w-full h-screen opacity-10 md:opacity-100" />

        <SubsidiarySelectionDialog
            v-if="state.subsidiarySelectionDialogVisible"
            :regions="regions"
            @close="changeSubsidiary"
        />

        <CookieConsent v-if="cookieConsentRequired" />
    </div>
</template>

<script setup>
import AsicsLogoLarge from '@/components/asics-logo/AsicsLogoLarge.vue';
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import LanguageSelector from '@/components/footer-menu/common/LanguageSelector.vue';
import { useI18n } from '@/composables/use-i18n.js';
import { bugsnag } from '@/libs/bugsnag.js';
import CookieConsent from '@/modules/unauthenticated/partials/CookieConsent.vue';
import SubsidiarySelectionDialog from '@/modules/unauthenticated/partials/SubsidiarySelectionDialog.vue';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { formatRegionName } from '@/utils/generic.js';
import { computed, reactive } from 'vue';

const state = reactive({
    subsidiarySelectionDialogVisible: false,
});

const { t } = useI18n();

const userLocale = computed(() => store.getters['globalStore/userLocale']);
const regions = computed(() => store.getters['globalStore/regions']);
const selectedSubsidiary = computed(() => store.getters['globalStore/subsidiary']);
const cookieConsentRequired = computed(() => Boolean(selectedSubsidiary.value) && ![Const.SUBSIDIARIES.JAPAN, Const.SUBSIDIARIES.NISHI].includes(selectedSubsidiary.value.key));

const openSubsidiarySelectionDialog = () => {
    state.subsidiarySelectionDialogVisible = !state.subsidiarySelectionDialogVisible;
};

const changeSubsidiary = async subsidiary => {
    state.subsidiarySelectionDialogVisible = false;

    if (!subsidiary) {
        return;
    }

    try {
        await store.dispatch('globalStore/setSubsidiary', subsidiary);
    } catch (error) {
        bugsnag.notify(error);
    }
};

const localisedCountryName = computed(() => selectedSubsidiary.value.countryCode
    ? formatRegionName(userLocale.value, selectedSubsidiary.value.countryCode)
    : t(selectedSubsidiary.value.translationKey));

if (!selectedSubsidiary.value) {
    openSubsidiarySelectionDialog();
}

</script>
