<template>
    <button
        v-show="isNonServicePortalRoute"
        class="appearance-none flex items-center gap-4 fixed top-1/2 right-0 h-[4rem] w-[3.4rem] hover:w-fit p-4 bg-secondary-lightest border border-r-0 rounded-bl-2xl rounded-tl-2xl text-base group transition-[width] delay-200 duration-500 ease-in"
    >
        <i class="fa fa-question-circle" />
        <router-link
            to="/support/service-portal/tickets/new"
            class="font-bold"
        >
            <span>{{ $t('general.help') }}</span>
        </router-link>
    </button>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router/composables';
const route = useRoute();

const isNonServicePortalRoute = computed(() => !route.matched.some(({ path }) => path.includes('/support/service-portal')));

</script>
