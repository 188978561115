<template>
    <DropDownMenu>
        <template #title>
            {{ $t('employeeMainMenu.discounts') }}
        </template>
        <template #dropdown>
            <li>
                <router-link to="/discounts/pending">
                    {{ $t('employeeMainMenu.pendingDiscounts') }}
                </router-link>
            </li>
            <li>
                <router-link to="/discounts/rejected">
                    {{ $t('employeeMainMenu.rejectedDiscounts') }}
                </router-link>
            </li>
            <li>
                <router-link to="/discounts/history">
                    {{ $t('employeeMainMenu.discountHistory') }}
                </router-link>
            </li>
        </template>
    </DropDownMenu>
</template>

<script>
import DropDownMenu from '@/components/menu/DropDownMenu.vue';

export default {
    name: 'DiscountsDropdownMenu',

    components: {
        DropDownMenu,
    },
};
</script>
