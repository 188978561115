import AbstractRouterView from '@/components/layout/AbstractRouterView.vue';
import AbstractRouterViewCached from '@/components/layout/AbstractRouterViewCached.vue';
import CustomerOrdersOverview from '@/modules/customer/orders-overview/CustomerOrdersOverview.vue';
import PolicyDisplay from '@/modules/support/policy/PolicyDisplay.vue';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';

const workflowCaseRoutes = [
    {
        path: 'workflow-cases',
        component: AbstractRouterView,
        children: [
            {
                path: '',
                component: () => import('@/modules/workflow-cases/WorkflowCases.vue'),
            },
            {
                path: 'new',
                component: () => import('@/modules/workflow-cases/workflow-case/WorkflowCaseNew.vue'),
            },
            {
                path: ':id',
                component: () => import('@/modules/workflow-cases/workflow-case/WorkflowCase.vue'),
                props: ({ params }) => ({ id: Number(params.id) }),
            },
        ],
    },
];

const disputeRoutes = [
    {
        path: 'disputes',
        component: () => import('@/modules/disputes/Disputes.vue'),
        redirect: to => `${to.path}/submitted`,
        children: [
            {
                path: 'submitted',
                component: () => import('@/modules/disputes/SapDisputes.vue'),
            },
            {
                path: 'drafts',
                component: () => import('@/modules/disputes/DraftDisputes.vue'),
            },
        ],
    },
    {
        path: 'disputes/new',
        component: () => import('@/modules/disputes/NewDispute.vue'),
    },
    {
        path: 'disputes/:sapDisputeCaseGuid',
        component: () => import('@/modules/disputes/Dispute.vue'),
        props: ({ params }) => ({ sapDisputeCaseGuid: params.sapDisputeCaseGuid }),
    },
    {
        path: 'disputes/:disputeId/product-selection',
        component: () => import('@/modules/disputes/DisputeInvoices.vue'),
        props: ({ params }) => ({ disputeId: Number(params.disputeId) }),
    },
    {
        path: 'disputes/:disputeId/product-preparation',
        component: () => import('@/modules/disputes/DisputeProductPreparation.vue'),
        props: ({ params }) => ({ disputeId: Number(params.disputeId) }),
    },
    {
        path: 'disputes/:disputeId/submit',
        component: () => import('@/modules/disputes/SubmitDispute.vue'),
        props: ({ params }) => ({ disputeId: Number(params.disputeId) }),
    },

];

const adminAndEmployeeCustomerRoutes = [
    {
        // Admin or employee (sales rep, sales manager, customer service, etc.) landing page
        path: 'customers',
        component: AbstractRouterViewCached,
        meta: {
            authorized: () => [Const.ACCOUNT_TYPE_ADMIN, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
        children: [
            {
                path: '',
                component: () => import('@/modules/admin/customers/CustomersOverview.vue'),
            },
            {
                path: ':customerNo',
                component: () => import('@/modules/admin/customers/customer/Customer.vue'),
                props: true,
                children: [
                    {
                        path: 'general-info',
                        component: () => import('@/modules/admin/customers/customer/CustomerGeneralInfo.vue'),
                    },
                    {
                        path: 'user-management',
                        component: AbstractRouterView,
                        props: true,
                        children: [
                            {
                                path: '',
                                component: () => import('@/modules/admin/customers/customer/CustomerB2BUsers.vue'),
                                props: true,
                            },
                            {
                                path: ':username',
                                component: () => import('@/modules/admin/customers/customer/user-management/UserManagementUpdate.vue'),
                                props: true,
                            },
                            {
                                path: ':username/free-of-charge-limits',
                                component: () => import('@/modules/admin/customers/customer/user-management/free-of-charge-limits/UserFreeOfChargeLimits.vue'),
                                props: true,
                            },
                        ],
                    },
                    {
                        path: 'addresses',
                        component: AbstractRouterView,
                        children: [
                            {
                                path: '',
                                component: () => import('@/modules/admin/customers/customer/CustomerAddresses.vue'),
                                props: true,
                            },
                            {
                                path: ':addressNo/sdp-scorecard',
                                component: () => import('@/modules/sdp-management/sdp-scorecard/SdpScorecard.vue'),
                                props: true,
                                meta: {
                                    authorized: () => store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.QUESTIONNAIRE,
                                },
                            },
                            {
                                path: ':addressNo/sdp-scorecard-confirmation',
                                component: () => import('@/modules/sdp-management/sdp-scorecard/SdpScorecardConfirmation.vue'),
                                props: true,
                                meta: {
                                    authorized: () => store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.QUESTIONNAIRE,
                                },
                            },
                        ],
                    },
                    {
                        path: 'discounts',
                        component: () => import('@/modules/admin/customers/customer/CustomerDiscounts.vue'),
                    },
                    {
                        path: 'credit',
                        component: () => import('@/modules/admin/customers/customer/CustomerCreditInfo.vue'),
                    },
                    {
                        path: 'delivery-notes',
                        component: () => import('@/modules/admin/customers/customer/delivery-notes/CustomerDeliveryNotes.vue'),
                        meta: {
                            authorized: () => store.getters.isAEGRegion,
                        },
                    },
                    {
                        path: 'order-history',
                        component: () => import('@/modules/admin/customers/customer/order-history/CustomerOrderHistory.vue'),
                    },
                    {
                        path: 'pre-orders',
                        component: () => import('@/modules/offers/OfferHistory.vue'),
                        redirect: to => `${to.path}/pre-orders-from-template`,
                        children: [
                            {
                                path: 'pre-orders-from-template',
                                component: () => import('@/modules/offers/OfferHistoryList.vue'),
                            },
                            {
                                path: 'pre-order-from-template-summary',
                                component: () => import('@/modules/offers/summary/TemplateSummary.vue'),
                            },
                        ],
                    },
                    {
                        path: 'pricing-group-discounts',
                        component: () => import('@/modules/admin/customers/customer/CustomerPricingGroupDiscounts.vue'),
                    },
                    {
                        path: 'legal',
                        component: () => import('@/modules/admin/customers/customer/CustomerLegalAgreement.vue'),
                    },
                    {
                        path: 'tickets',
                        component: () => import('@/modules/service-portal/tickets/Tickets.vue'),
                    },
                    ...disputeRoutes,
                    {
                        path: 'sdp-management',
                        component: AbstractRouterView,
                        children: [
                            {
                                path: '',
                                component: () => import('@/modules/sdp-management/management/SdpManagement.vue'),
                                props: true,
                                redirect: () => store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.DIRECT
                                    ? 'assortment-maintenance'
                                    : 'milestones',
                                children: [
                                    {
                                        path: 'assortment-maintenance',
                                        component: () => import('@/modules/sdp-management/management/direct-strategy/SdpManagementCustomerGroups.vue'),
                                        props: true,
                                        meta: {
                                            authorized: () => store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.DIRECT,
                                        },
                                    },
                                    {
                                        path: 'milestones',
                                        component: () => import('@/modules/sdp-management/management/questionnaire-strategy/SdpManagementMilestones.vue'),
                                        props: true,
                                        meta: {
                                            authorized: () => store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.QUESTIONNAIRE,
                                        },
                                    },
                                ],
                            },
                            {
                                path: 'activity-logs',
                                component: () => import('@/modules/sdp-management/management/activity-logs/SdpActivityLogs.vue'),
                                props: true,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

const supportRoutes = [
    {
        path: '/help',
        component: () => import('@/modules/support/Help.vue'),
    },
    {
        path: '/policy',
        component: PolicyDisplay, // TODO: use AbstractRouterView (AJ)
        redirect: '/policy/privacy-policy',
        children: [
            {
                path: 'cookie',
                component: () => import('@/modules/support/policy/AsicsCookiePolicy.vue'),
            },
            {
                path: 'privacy-policy',
                component: PolicyDisplay,
                redirect: () => [Const.SUBSIDIARIES.CHILE, Const.SUBSIDIARIES.COLOMBIA].includes(store.state.subsidiary.id)
                    ? `/policy/privacy-policy/${store.state.subsidiary.id}`
                    : '/policy/privacy-policy/general',
                children: [
                    {
                        path: 'general',
                        component: () => import('@/modules/support/policy/AsicsUsaPrivacyPolicy.vue'),
                    },
                    {
                        path: 'acl',
                        component: () => import('@/modules/support/policy/AsicsACLPrivacyPolicy.vue'),
                    },
                    {
                        path: 'aco',
                        component: () => import('@/modules/support/policy/AsicsACOPrivacyPolicy.vue'),
                    },
                ],
            },
            {
                path: 'terms-of-use',
                component: () => import('@/modules/support/policy/AsicsB2BTermsOfUse.vue'),
            },
            {
                path: 'advertising-policy',
                component: () => import('@/modules/support/policy/AsicsAdvertisingPolicy.vue'),
            },
            {
                path: 'dropship-addendum',
                component: () => import('@/modules/support/policy/AsicsDropshipAddendum.vue'),
            },
            {
                path: 'retailer-terms',
                component: () => import('@/modules/support/policy/AsicsTermsAndConditions.vue'),
            },
            {
                path: 'brand-protection-policy',
                component: () => import('@/modules/support/policy/AsicsBrandProtectionPolicy.vue'),
            },
            {
                path: 'umap',
                component: () => import('@/modules/support/policy/AsicsUmap2021.vue'),
            },
            {
                path: 'umap-ot',
                component: () => import('@/modules/support/policy/AsicsUmapOT2021.vue'),
            },
            {
                path: 'sales-conditions',
                component: () => import('@/modules/support/policy/SalesConditions.vue'),
            },
        ],
    },
    {
        path: '/support/service-portal',
        component: () => import('@/modules/service-portal/ServicePortal.vue'),
        redirect: () => '/support/service-portal/tickets',
        props: true,
        children: [
            {
                path: 'tickets',
                component: AbstractRouterView,
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/service-portal/tickets/Tickets.vue'),
                    },
                    {
                        path: 'new/:customerNo?',
                        component: () => import('@/modules/service-portal/tickets/TicketCreate.vue'),
                        props: true,
                    },
                    {
                        path: ':id/:customerNo?',
                        component: () => import('@/modules/service-portal/tickets/TicketEdit.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'categories',
                component: () => import('@/modules/service-portal/categories/ServicePortalCategories.vue'),
            },
        ],
    },
];

const communicationsRoutes = [
    {
        path: '/blog',
        component: AbstractRouterView,
        children: [
            {
                path: '',
                component: () => import('@/modules/news-alerts/viewer/posts/PostList.vue'),
            },
            {
                path: ':id/:slug?',
                component: () => import('@/modules/news-alerts/viewer/posts/PostView.vue'),
                props: true,
            },
        ],
    },
    {
        path: '/training-material',
        component: AbstractRouterView,
        children: [
            {
                path: '',
                component: () => import('@/modules/admin/settings/digital-assets/training-material/viewer/TrainingMaterial.vue'),
            },
        ],
    },
    {
        path: '/contact-us',
        component: () => import('@/modules/content/ContactUs.vue'),
        meta: {
            authorized: () => store.getters.getSubsidiary === Const.SUBSIDIARIES.UNITED_STATES,
        },
    },
    {
        path: '/user-agreement',
        component: AbstractRouterView,
        redirect: () => store.getters.getSubsidiary === Const.SUBSIDIARIES.CANADA ? '/user-agreement/aca' : '/user-agreement/general',
        children: [
            {
                path: 'general',
                component: () => import('@/modules/content/user-agreement/UserAgreementGeneral.vue'),
            },
            {
                path: 'aca',
                component: () => import('@/modules/content/user-agreement/UserAgreementACA.vue'),
            },
        ],
    },
    {
        path: '/privacy-policy',
        component: () => import('@/modules/content/PrivacyPolicy.vue'),
    },
];

const blogManagerRoutes = [
    {
        path: 'blog-manager',
        component: () => import('@/modules/news-alerts/manager/NewsAlertsManager.vue'),
        redirect: '/blog-manager/posts',
        meta: {
            authorized: () => store.state.subsidiary.blogEnabled && store.getters.getUserCan(Const.PERMISSION_CAN_MANAGE_BLOG),
        },
        children: [
            {
                path: 'posts',
                component: AbstractRouterView,
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/news-alerts/manager/posts/PostTable.vue'),
                    },
                    {
                        path: ':id',
                        component: () => import('@/modules/news-alerts/manager/posts/PostForm.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'audience-groups',
                component: () => import('@/modules/news-alerts/manager/audience-groups/AudienceGroups.vue'),
            },
            {
                path: 'categories',
                component: () => import('@/modules/news-alerts/manager/categories/NewsAlertsCategories.vue'),
            },
        ],
    },
];

const toolboxRoutes = [
    {
        path: '/toolbox',
        component: AbstractRouterView,
        meta: {
            authorized: () => [Const.ACCOUNT_TYPE_ADMIN, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
        children: [
            {
                path: 'item-visibility-management',
                component: () => import('@/modules/admin/toolbox/item-visibility-manager/ItemVisibilityManager.vue'),
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_ADMIN,
                },
            },
            {
                path: 'item-visibility-checker',
                component: () => import('@/modules/item-availability-checker/ItemAvailabilityChecker.vue'),
            },
            {
                path: 'e-catalog-management',
                component: () => import('@/modules/admin/toolbox/e-catalog-manager/ECatalogManager.vue'),
            },
            {
                path: 'baskets',
                component: AbstractRouterView,
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/admin/toolbox/baskets/Baskets.vue'),
                    },
                    {
                        path: ':basketNo',
                        component: () => import('@/modules/admin/toolbox/baskets/basket/Basket.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'jobs-monitor',
                component: () => import('@/modules/admin/toolbox/jobs-monitor/JobsMonitor.vue'),
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_ADMIN,
                },
            },
            {
                path: 'pre-order-management',
                name: 'preorderManagement',
                component: () => import('@/modules/offers/Offers.vue'),
            },
            {
                path: 'offers',
                component: () => import('@/modules/offers/OfferDatatablePage.vue'),
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_ADMIN,
                },
            },
            {
                path: 'foc-management',
                name: 'freeOfChargeManagement',
                component: () => import('@/modules/foc-manager/FOCManager.vue'),
                meta: {
                    authorized: () => store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_FOC),
                },
            },
            {
                path: 'sales-rep-management',
                component: () => import('@/modules/sales-rep-manager/SalesRepManager.vue'),
                meta: {
                    // TODO: this should be a subsidiary setting
                    authorized: () => store.state.subsidiary.id === Const.SUBSIDIARIES.UNITED_STATES && store.state.user._type === Const.ACCOUNT_TYPE_ADMIN,
                },
            },
            {
                path: 'dispute-workstation',
                name: 'disputeWorkstation',
                component: () => import('@/modules/disputes/DisputeWorkstation.vue'),
            },
            {
                path: 'sdp-management',
                name: 'sdpManagement',
                component: () => import('@/modules/admin/toolbox/sdp-manager/SdpManager.vue'),
                redirect: () => store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.DIRECT
                    ? '/toolbox/sdp-management/assortment/maintenance'
                    : '/toolbox/sdp-management/scoring-milestones-reset',
                meta: {
                    authorized: () => [Const.PERMISSIONS.CAN_MANAGE_CUSTOMER_SDPS, Const.PERMISSIONS.CAN_MAINTAIN_CUSTOMER_SDPS].some(store.getters.getUserCan),
                },
                children: [
                    {
                        path: 'scoring-milestones-reset',
                        name: 'sdpScoringMilestonesReset',
                        component: () => import('@/modules/admin/toolbox/sdp-manager/questionnaire-strategy/SdpManagerScoringMilestoneReset.vue'),
                        meta: {
                            authorized: () => store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.QUESTIONNAIRE,
                        },
                    },
                    {
                        path: 'report',
                        name: 'sdpScoringReport',
                        component: () => import('@/modules/admin/toolbox/sdp-manager/questionnaire-strategy/SdpManagerReport.vue'),
                        meta: {
                            authorized: () => store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.QUESTIONNAIRE
                                || (store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.DIRECT && store.state.subsidiary.sdpDirectReportEnabled),
                        },
                    },
                    {
                        path: 'assortment',
                        component: () => import('@/modules/admin/toolbox/sdp-manager/direct-strategy/SdpManagerAssortment.vue'),
                        redirect: '/toolbox/sdp-management/assortment/maintenance',
                        meta: {
                            authorized: () => store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.DIRECT,
                        },
                        children: [
                            {
                                path: 'maintenance',
                                name: 'assortmentMaintenance',
                                component: () => import('@/modules/admin/toolbox/sdp-manager/direct-strategy/assortment-maintenance/SdpManagerAssortmentMaintenance.vue'),
                            },
                            {
                                path: 'approvals',
                                name: 'assortmentApprovals',
                                component: () => import('@/modules/admin/toolbox/sdp-manager/direct-strategy/assortment-approvals/SdpManagementAssortmentApprovals.vue'),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'teams',
                component: AbstractRouterView,
                meta: {
                    authorized: () => store.state.subsidiary.isTeamsManagementEnabled && store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_TEAMS),
                },
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/teams/Teams.vue'),
                    },
                    {
                        path: ':teamId',
                        component: () => import('@/modules/teams/Team.vue'),
                        props: route => ({ teamId: route.params.teamId }),
                    },
                    {
                        path: 'new',
                        component: () => import('@/modules/teams/Team.vue'),
                        props: true,
                    },
                ],
            },
            {
                path: 'workflow-management',
                component: () => import('@/modules/workflow-management/WorkflowManagement.vue'),
                redirect: '/workflow-management/workflows',
                meta: {
                    authorized: () => store.state.subsidiary.isWorkflowManagementEnabled && store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_WORKFLOWS),
                },
                children: [
                    {
                        path: 'workflows',
                        component: AbstractRouterView,
                        children: [
                            {
                                path: '',
                                component: () => import('@/modules/workflow-management/workflows/Workflows.vue'),
                            },
                            {
                                path: ':id',
                                component: () => import('@/modules/workflow-management/workflows/Workflow.vue'),
                                props: true,
                            },
                            {
                                path: 'new',
                                component: () => import('@/modules/workflow-management/workflows/Workflow.vue'),
                                props: true,
                            },
                        ],
                    },
                    ...workflowCaseRoutes,
                ],
            },
            ...workflowCaseRoutes,
        ],
    },
];

const accountRoutes = [
    {
        path: '/account',
        name: 'account',
        component: () => import('@/modules/account/Account.vue'),
        redirect: '/account/general',
        children: [
            {
                path: 'general',
                name: 'accountGeneralInfo',
                component: () => import('@/modules/account/AccountGeneralInfo.vue'),
            },
            {
                path: 'trusted-devices',
                name: 'accountTrustedDevices',
                component: () => import('@/modules/account/AccountTrustedDevices.vue'),
                props: true,
                meta: {
                    authorized: () => store.state.subsidiary.loginMfaEnabled,
                },
            },
            {
                path: 'notification-settings',
                name: 'accountNotificationSettings',
                component: () => import('@/modules/account/NotificationSettings.vue'),
                meta: {
                    authorized: () => [Const.ACCOUNT_TYPE_CUSTOMER, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
                },
            },
            {
                path: 'contacts',
                name: 'accountContacts',
                component: () => import('@/modules/account/AccountContacts.vue'),
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER,
                },
            },
            {
                path: ':customerNo/addresses',
                name: 'accountAddresses',
                component: () => import('@/modules/admin/customers/customer/CustomerAddresses.vue'),
                props: true,
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER,
                },
            },
        ],
    },
];

const notificationRoutes = [
    {
        path: '/notifications',
        name: 'notifications',
        component: () => import('@/modules/notifications/NotificationsPage.vue'),
    },
];

const ordersOverviewRoutes = [
    {
        path: '/orders-overview',
        name: 'ordersOverview',
        component: CustomerOrdersOverview,
        redirect: '/orders-overview/order-history',
        meta: {
            authorized: () => [Const.ACCOUNT_TYPE_CUSTOMER, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
        children: [
            {
                path: 'order-history',
                name: 'orderHistory',
                component: () => import('@/modules/admin/customers/customer/order-history/CustomerOrderHistory.vue'),
            },
            {
                path: 'credit-information',
                name: 'creditInfo',
                component: () => import('@/modules/admin/customers/customer/CustomerCreditInfo.vue'),
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER,
                },
            },
            {
                path: 'delivery-notes',
                name: 'deliveryNotes',
                component: () => import('@/modules/admin/customers/customer/delivery-notes/CustomerDeliveryNotes.vue'),
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER && store.getters.isAEGRegion,
                },
            },
            {
                path: 'disputes-old',
                name: 'disputesOld',
                component: () => import('@/modules/customer/orders-overview/disputes/CustomerDisputes.vue'),
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER,
                },
            },
            ...disputeRoutes,
            {
                path: 'pre-orders',
                name: 'preorders',
                component: () => import('@/modules/offers/OfferHistory.vue'),
                redirect: to => `${to.path}/pre-orders-from-template`,
                children: [
                    {
                        path: 'pre-orders-from-template',
                        name: 'preordersFromTemplate',
                        component: () => import('@/modules/offers/OfferHistoryList.vue'),
                    },
                    {
                        path: 'pre-order-from-template-summary',
                        name: 'preorderFromTemplateSummary',
                        component: () => import('@/modules/offers/summary/TemplateSummary.vue'),
                    },
                ],
            },
        ],
    },
    {
        path: '/pending-baskets',
        name: 'pendingBaskets',
        component: () => import('@/modules/baskets/pending/PendingBasketsOverview.vue'),
        meta: {
            authorized: () => [Const.ACCOUNT_TYPE_CUSTOMER, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
    },
];

const customerOrderCreationRoutes = [
    {
        path: '/customers/:customerNo/orders/new',
        name: 'newOrder',
        component: () => import('@/modules/baskets/new/CustomerOrderNew.vue'),
        props: route => ({ customerNo: route.params.customerNo, orderType: route.query.type, workflowCaseId: route.query.workflowCase }),
        meta: {
            authorized: () => [Const.ACCOUNT_TYPE_CUSTOMER, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
    },
    {
        path: '/customers/:customerNo/orders/new-fashion-contract',
        name: 'newFashionContract',
        component: () => import('@/modules/baskets/new/NewFashionContract.vue'),
        props: true,
        meta: {
            authorized: () => [Const.ACCOUNT_TYPE_CUSTOMER, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
    },
    {
        path: '/customers/:customerNo/orders/import',
        name: 'orderImport',
        component: () => import('@/modules/import/ImportPage.vue'),
        props: ({ params, query }) => ({
            customerNo: params.customerNo,
            ...query,
            fashionContractCallOffBlock: query.fashionContractCallOffBlock === 'true',
        }),
        meta: {
            authorized: () => [Const.ACCOUNT_TYPE_CUSTOMER, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
    },
];

const customerOrdersRoutes = [
    {
        path: '/customers/:customerNo/orders/:orderNo',
        component: () => import('@/modules/orders/order/Order.vue'),
        redirect: '/customers/:customerNo/orders/:orderNo/order-contents',
        props: true,
        children: [
            {
                path: 'order-contents',
                name: 'orderContents',
                component: () => import('@/modules/orders/order/order-contents/OrderContents.vue'),
            },
            {
                path: 'delivery-notes',
                name: 'orderDeliveryNotes',
                component: () => import('@/modules/orders/order/delivery-notes/OrderDeliveryNotes.vue'),
            },
            {
                path: 'credit-documents',
                name: 'orderCreditDocuments',
                component: () => import('@/modules/orders/order/credit-documents/OrderCreditDocuments.vue'),
            },
        ],
    },
    {
        path: '/customers/:customerNo/orders/:orderNo/delivery-notes/:deliveryNoteNo',
        component: () => import('@/modules/orders/order/delivery-notes/delivery-note/OrderDeliverNote.vue'),
        props: ({ params, query }) => ({
            ...params,
            ...query,
        }),
    },
];

const productCustomizationRoutes = [
    {
        path: '/product-customization',
        component: () => import('@/modules/customization/ProductCustomization.vue'),
        props: ({ params }) => ({ basketNo: Number(params.basketNo) }),
        meta: {
            authorized: () => [Const.ACCOUNT_TYPE_CUSTOMER, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
        children: [
            {
                path: 'orders/:basketNo/product-designs',
                component: AbstractRouterView,
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/customization/product-designs/ProductDesigns.vue'),
                        props: ({ params }) => ({ basketNo: Number(params.basketNo) }),
                    },
                    {
                        path: 'new',
                        component: () => import('@/modules/customization/product-designs/ProductDesign.vue'),
                        props: ({ params, query }) => ({ basketNo: Number(params.basketNo), sku: query.sku }),
                    },
                    {
                        path: ':recipeId',
                        component: () => import('@/modules/customization/product-designs/ProductDesign.vue'),
                        props: ({ params }) => ({ ...params, basketNo: Number(params.basketNo) }),
                    },
                ],
            },
            {
                path: 'orders/:basketNo/product-sets',
                component: AbstractRouterView,
                children: [
                    {
                        path: '',
                        component: () => import('@/modules/customization/product-sets/CustomProductSets.vue'),
                        props: ({ params }) => ({ basketNo: Number(params.basketNo) }),
                    },
                    {
                        path: ':recipeSetId',
                        component: () => import('@/modules/customization/product-sets/product-set/CustomProductSet.vue'),
                        props: ({ params, query }) => ({ ...params, basketNo: Number(params.basketNo), deliveryDate: query.deliveryDate }),
                    },
                ],
            },
            {
                path: 'orders/:basketNo/design-systems',
                component: () => import('@/modules/customization/design-systems/DesignSystems'),
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER,
                },
            },
        ],
    },
];

const customerDeliveryNotesRoutes = [
    {
        path: '/customers/:customerNo/delivery-notes/:deliveryNoteNo',
        component: () => import('@/modules/admin/customers/customer/delivery-notes/CustomerDeliveryNote.vue'),
        props: true,
        meta: {
            authorized: () => store.getters.isAEGRegion,
        },
    },
];

const statisticsRoutes = [
    {
        path: 'statistics',
        component: AbstractRouterView,
        meta: {
            authorized: () => [Const.ACCOUNT_TYPE_ADMIN, Const.ACCOUNT_TYPE_SALES_REP].includes(store.state.user._type),
        },
        children: [
            {
                path: 'image-availability',
                name: 'imageAvailability',
                component: () => import('@/modules/statistics/NotAvailableImagesComponent.vue'),
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_ADMIN,
                },
            },
            {
                path: 'reports',
                name: 'userReports',
                component: () => import('@/modules/statistics/reports/Reports.vue'),
            },
            {
                path: 'global-administration',
                name: 'globalAdministration',
                component: () => import('@/modules/statistics/global-administration/GlobalAdministration.vue'),
                redirect: 'global-administration/health-checks',
                props: true,
                meta: {
                    authorized: () => store.state.user._type === Const.ACCOUNT_TYPE_ADMIN && store.getters.getUserCan('canViewGlobalAdministration'),
                },
                children: [
                    {
                        path: 'health-checks',
                        name: 'healthChecks',
                        component: () => import('@/modules/statistics/global-administration/health-checks/HealthChecks.vue'),
                    },
                ],
            },
        ],
    },
];

export {
    adminAndEmployeeCustomerRoutes,
    supportRoutes,
    communicationsRoutes,
    toolboxRoutes,
    accountRoutes,
    notificationRoutes,
    ordersOverviewRoutes,
    customerDeliveryNotesRoutes,
    customerOrderCreationRoutes,
    customerOrdersRoutes,
    productCustomizationRoutes,
    workflowCaseRoutes,
    blogManagerRoutes,
    statisticsRoutes,
};
