import api from '@/api/api.js';
import { getPublicTranslations, getTranslations } from '@/libs/i18n/i18n.api.js';
import { setVeeValidateLocale } from '@/libs/vee-validate.js';
import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en-GB',
    messages: {
        'en-GB': null,
    },
});

// Locale is in legacy xx_xx format; vee-validate use xx-XX format (https://datatracker.ietf.org/doc/html/rfc5646)
function toLanguageTag(locale) {
    const [language, region] = locale.split('_');

    return region ? `${language}-${region.toUpperCase()}` : language;
}

const setInObject = (target, path, value, previousPath = '') => {
    if (path === '') {
        return value;
    }

    const [currentKey, ...rest] = path.split('.');
    const remainingKeys = rest.join('.');

    if (target?.[currentKey] && typeof target?.[currentKey] !== 'object') {
        console.warn(`'${previousPath}.${currentKey}' already set to a value, it will be overwritten by '${previousPath}.${currentKey}.${remainingKeys}'`);
    }

    return Object.assign(
        target ?? {},
        { [currentKey]: setInObject(target?.[currentKey], remainingKeys, value, previousPath ? `${previousPath}.${currentKey}` : currentKey) },
    );
};

export async function loadTranslations(languageCode, authenticated) {
    const { data: { data } } = await (authenticated ? getTranslations(languageCode) : getPublicTranslations(languageCode));
    const messages = Object.values(data).reduce((processedMessages, { key, value }) => setInObject(processedMessages, key, value), {});

    i18n.setLocaleMessage(languageCode, messages);
}

export async function switchLanguage(locale, authenticated = false) {
    const languageTag = toLanguageTag(locale);

    api.defaults.headers.common['x-accept-language'] = languageTag; // Every API request will include this language header

    if (!i18n.messages[locale] || authenticated) { // TODO: improve messages overwrite
        await loadTranslations(locale, authenticated);
    }

    i18n.locale = locale;

    document.querySelector('html').setAttribute('lang', languageTag);
    setVeeValidateLocale(languageTag);
}

export default i18n;
