import { isArray, isObject } from '@/utils/collection.js';
import { toSnakeCase, toCamelCase } from '@/utils/generic.js';

function transformToCamelCase(data) {
    if (isObject(data)) {
        const obj = {};

        for (const [key, value] of Object.entries(data)) {
            obj[toCamelCase(key)] = transformToCamelCase(value);
        }

        return obj;
    } else if (isArray(data)) {
        return data.map(item => transformToCamelCase(item));
    }

    return data;
}

function transformToSnakeCase(data) {
    if (isObject(data)) {
        const obj = {};

        for (const [key, value] of Object.entries(data)) {
            obj[toSnakeCase(key)] = transformToSnakeCase(value);
        }

        return obj;
    } else if (isArray(data)) {
        return data.map(item => transformToSnakeCase(item));
    }

    return data;
}

const requestTransformInterceptor = requestConfig => {
    if (requestConfig.params) {
        requestConfig.params = transformToSnakeCase(requestConfig.params);
    }

    if (requestConfig.data) {
        requestConfig.data = transformToSnakeCase(requestConfig.data);
    }

    return requestConfig;
};

const responseTransformInterceptor = (response, options = { wrappedResponse: false }) => {
    if (response.data && response.headers['content-type'] === 'application/json' && !response.config.url.includes('/translations')) {
        response.data = transformToCamelCase(response.data);
    }

    return options.wrappedResponse ? response : response.data;
};

export {
    requestTransformInterceptor,
    responseTransformInterceptor,
    transformToSnakeCase,
    transformToCamelCase,
};
