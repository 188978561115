import api, { createTransformingApiInstance } from '@/api/api.js';

const transformingApi = createTransformingApiInstance();

export function getAsicsContactInfo() {
    return api.get('asics-contact-info');
}

export function getCustomerInfo() {
    return api.get('customerCtrl/getCustomer');
}

export function getCustomerFreeOfChargeSettings(customerNo) {
    return api.get(`customers/${customerNo}/free-of-charge-settings`);
}

export function addTrustedDevice(payload) {
    return transformingApi.post('users/trusted-devices', payload);
}

export function getTrustedDevices() {
    return transformingApi.get('users/trusted-devices');
}

export function removeTrustedDevice(id) {
    return transformingApi.delete(`users/trusted-devices/${id}`);
}

export function isTrustedDevice() {
    return transformingApi.head('users/trusted-devices/current');
}
