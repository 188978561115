<template>
    <tr class="text-base leading-snug align-top">
        <td>
            <div class="flex gap-2 sm:gap-8 text-sm p-4 overflow-x-auto">
                <ProductImage
                    :brand="item.brandName"
                    :url="item.imageUrl"
                    decoding="async"
                    loading="lazy"
                    :width="70"
                    :height="35"
                    img-css-class="max-w-[7rem]"
                />
                <div class="flex flex-col">
                    <router-link
                        v-if="itemLinkEnabled"
                        :to="itemLink(item)"
                        class="font-bold pb-1"
                    >
                        {{ item.itemName }}
                    </router-link>
                    <span
                        v-else
                        class="font-bold pb-1"
                    >
                        {{ item.itemName }}
                    </span>
                    <span class="flex items-center gap-2 text-grey-dark">
                        <span>{{ $t(`general.gender${item.gender}`) }}</span>
                        <span>-</span>
                        <span v-if="itemCatalogCode(item)">{{ itemCatalogCode(item) }}-</span>
                        <span>{{ itemTradingCode(item) }}-{{ item.colorCodeLabel }}</span>
                    </span>
                    <span
                        v-if="item.widthTranslationCode && item.widthTranslationCode !== 'standard'"
                        class="text-grey-dark capitalize"
                    >
                        {{ $t('general.width') }}: {{ item.widthLabel }}
                    </span>
                    <span class="text-grey-dark capitalize">{{ $t('general.labelColor') }}: {{ itemColorName(item) }}</span>
                    <span
                        v-if="showCustomerDistributors"
                        class="text-grey-dark"
                    >
                        {{ itemDistributorDisplayName(item.sizes) }}
                    </span>
                </div>
            </div>
        </td>
        <td
            class="p-4"
            :class="compactView ? 'text-sm' : 'text-base'"
        >
            <div class="flex items-center gap-3">
                <span>{{ formatNumber(item.totalQuantity) }}</span>
                <span v-if="compactView">{{ item.totalQuantity === 1 ? $t('basket.unit') : $t('basket.units') }}</span>
            </div>
        </td>
        <template v-if="shouldShowPrice">
            <td
                class="p-4"
                :class="compactView ? 'hidden' : 'sm:table-cell'"
            >
                <template v-if="!itemHasDistributor(item)">
                    {{ formatPrice(userCan('canSeeItemWholesalePrice') ? item.unitPrice : item.offerPrice) }}
                </template>
            </td>
            <td
                v-if="userCan('canSeeItemDiscount') && shouldShowDiscount"
                class="p-4"
                :class="compactView ? 'hidden' : 'sm:table-cell'"
            >
                <template v-if="!itemHasDistributor(item)">
                    {{ roundDiscount(item.lineDiscount, subsidiary.roundDiscountToNearest) }}%
                </template>
            </td>
            <td
                class="p-4"
                :class="compactView ? 'text-sm' : 'text-base'"
            >
                <template v-if="itemHasDistributor(item)">
                    {{ $t('general.suppliedByDistributor') }}
                </template>
                <template v-else>
                    {{ formatPrice(item.newTotalNetAmount) }}
                </template>
            </td>
        </template>
        <td
            v-if="canRemoveItems && !item.isPrepack"
            class="p-4"
        >
            <div class="action-buttons">
                <ButtonPlain
                    kind="neutral"
                    class="items-start text-sm hover:text-red-dark print:hidden"
                    :disabled="removeItemInProgress"
                    @click.native="removeItem"
                >
                    <i :class="['fa', removeItemInProgress ? 'fa-spinner fa-spin' : 'fa-trash']" />
                </ButtonPlain>
            </div>
        </td>
    </tr>
</template>

<script>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import ProductImage from '@/components/product-image/ProductImage.vue';
import { Const } from '@/utils/constants.js';
import { localisedCurrencyFormat, localisedNumberFormat, roundDiscount } from '@/utils/generic.js';

export default {
    name: 'BasketItem',

    components: {
        ProductImage,
        ButtonPlain,
    },

    props: {
        basketInfo: {
            type: Object,
            required: true,
        },

        item: {
            type: Object,
            required: true,
        },

        shouldShowPrice: {
            type: Boolean,
            required: true,
        },

        compactView: {
            type: Boolean,
            default: false,
        },

        canRemoveItems: {
            type: Boolean,
            default: true,
        },

        itemLinkEnabled: {
            type: Boolean,
            default: true,
        },
    },

    emits: [
        'removeItem',
    ],

    data() {
        return {
            removeItemInProgress: false,
        };
    },

    computed: {
        selectedSizeType() {
            return `size${this.$store.state.preferences.sizeChart}`;
        },

        subsidiary() {
            return this.$store.state.subsidiary;
        },

        shouldShowDiscount() {
            return this.$store.getters.getUserCan('canSeeItemDiscount') && (this.subsidiary.id !== Const.SUBSIDIARIES.UNITED_STATES);
        },

        customerDistributors() {
            return this.basketInfo.customerDistributors;
        },

        showCustomerDistributors() {
            return this.customerDistributors.length > 0;
        },
    },

    methods: {
        roundDiscount,

        formatPrice(value) {
            return localisedCurrencyFormat(value, this.subsidiary.locale, this.basketInfo.currencyCode);
        },

        formatNumber(number) {
            return localisedNumberFormat(number, this.$store.getters.userLocale);
        },

        userCan(permission) {
            return this.$store.getters.getUserCan(permission);
        },

        itemHasDistributor(item) {
            return !this.basketInfo.isCreatedByPayer && item.sizes.some(({ partnerNo }) => Boolean(partnerNo));
        },

        itemLink(item) {
            return {
                path: item.isCustomItem
                    ? `/product-customization/orders/${this.basketInfo.basketNo}/product-sets/${item.recipeSetId}`
                    : `/orders/${this.basketInfo.basketNo}/products/${item.itemNo}`,
                query: {
                    colorCode: item.isCustomItem ? null : item.colorCode,
                    deliveryDate: item.sizes[0].requestedDeliveryDate ?? null,
                },
            };
        },

        itemCatalogCode(item) {
            if (item.isCustomItem && item.isFactory && item.customizationData.catalogCode) {
                return item.customizationData.catalogCode;
            }

            return null;
        },

        itemTradingCode(item) {
            if (item.isCustomItem && item.isFactory && item.customizationData.tradingCode) {
                return item.customizationData.tradingCode;
            }

            return item.itemNo;
        },

        itemColorName(item) {
            if (item.isCustomItem && item.colorCode === 'CUS') {
                return this.$t('general.customColorName');
            }

            return item.colorName;
        },

        itemDistributorDisplayName(sizes) {
            if (!this.showCustomerDistributors) {
                return;
            }

            const matchingDistributor = this.customerDistributors.find(distributor => distributor.no === sizes[0].partnerNo);

            return matchingDistributor ? `${matchingDistributor.no} - ${matchingDistributor.name}` : sizes[0].no;
        },

        async removeItem() {
            this.removeItemInProgress = true;

            this.$emit('removeItem', this.item);
        },
    },
};
</script>
