<template>
    <router-view class="fixed-width-content" />
</template>

<script>
export default {
    name: 'PolicyDisplay',
};
</script>
<style lang="scss">
@import '@/styles/components/support-content/typography';
</style>
