import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import Vue from 'vue';
import VueRouter from 'vue-router';

const { NavigationFailureType, isNavigationFailure } = VueRouter;
const bugsnag = Bugsnag;

function isNavigationError(error) {
    return [NavigationFailureType.cancelled, NavigationFailureType.duplicated, NavigationFailureType.redirected].some(type => isNavigationFailure(error, type));
}

function logErrorToConsole(error, ...args) {
    if (isNavigationError(error)) {
        return;
    }

    console.error(error, args);
}

function shouldIgnoreError(error) {
    const { HTTP_STATUS } = Const;

    switch (true) {
        case (error?.response?.status === HTTP_STATUS.UNAUTHENTICATED): // Don't report authentication errors to bugsnag
        case (error?.response?.status === HTTP_STATUS.SERVICE_UNAVAILABLE): // Don't report service unavailable errors to bugsnag. We might want to differentiate between maintenance mode here
        case (error?.response?.status === HTTP_STATUS.TOO_MANY_ATTEMPTS): // Don't report rate limit responses to bugsnag
        case (error?.code === 'ERR_CANCELED'): // Don't report cancelled requests to bugsnag
        case (error?.name === 'CanceledError'): // Don't report cancelled AXIOS requests to bugsnag
        case (isNavigationError(error)): // Don't report Vue Router navigation errors to bugsnag
            return true;
        default:
            return false;
    }
}

function setupBugsnag({ apiKey, releaseStage }) {
    if (!apiKey) {
        bugsnag.notify = logErrorToConsole;

        return;
    }

    bugsnag.start({
        apiKey,
        plugins: [
            new BugsnagPluginVue(),
        ],
        releaseStage,
        appVersion: import.meta.env?.VITE_BUGSNAG_RELEASE_TAG ?? 'N/A',
        metadata: {
            custom: {
                application: {
                    releaseDate: import.meta.env?.VITE_BUGSNAG_RELEASE_DATE ?? 'N/A',
                    buildUrl: import.meta.env?.VITE_BUGSNAG_BUILD_URL ?? 'N/A',
                },
            },
        },
        onError(event) {
            const { originalError } = event;

            if (shouldIgnoreError(originalError)) {
                return false;
            }

            const customMetadata = {
                subsidiary: store.getters['globalStore/subsidiary']?.key,
                user: {
                    accountType: store.state?.user?._type,
                    permissions: store.state?.user?._rights,
                },
            };

            if (originalError?.response?.data?.message) {
                customMetadata.apiErrorMessage = originalError.response.data.message;
            }

            event.addMetadata('custom', customMetadata);
        },
    });

    bugsnag.getPlugin('vue').installVueErrorHandler(Vue);
}

export {
    setupBugsnag,
    bugsnag,
};
