<script>
import { useI18n } from '@/composables/use-i18n.js';
import { h } from 'vue';

export default {
    name: 'NotificationMessage',

    props: {
        message: {
            type: String,
            required: true,
        },
    },

    emits: [
        'linkClick',
    ],

    setup(props, { emit }) {
        const { t } = useI18n();

        return () => {
            const messageParts = props.message.split(/(?=\bhttp)|(?=\s|$|\))/gi).flatMap( part => {
                if (/^http/i.test(part)) {
                    const href = part.trim();

                    return h('a', {
                        attrs: {
                            href,
                            class: 'underline',
                            title: t('notifications.messageLinkTitle'),
                        },

                        on: {
                            click(event) {
                                event.stopPropagation();
                                event.preventDefault();

                                emit('linkClick', href);
                            },
                        },
                    }, 'view');
                }

                return part;
            });

            return h(
                'div',
                messageParts,
            );
        };
    },
};
</script>
