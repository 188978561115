<template>
    <OrderJourneyMenu :order-journey="orderJourney">
        <template #menu-options="{collapseMenu}">
            <template v-if="orderJourney.isOffer && basket">
                <li
                    v-if="offers.length"
                    class="menu-group"
                >
                    <div
                        class="menu-group-title"
                        @click="toggleExpanded('offers')"
                    >
                        {{ $t('general.offerBaskets') }}
                    </div>
                    <ul
                        v-show="!isMobileViewport || state.expanded.offers"
                        class="menu-group-items"
                    >
                        <li
                            v-for="offer in offers"
                            :key="`${offer.offerId}-${offer.no}`"
                            class="menu-group-item"
                        >
                            <ButtonPlain
                                :text="`${offer.offerTitle} - ${offer.shipToKey}`"
                                class="font-normal"
                                @click.native="goToOffer(offer, collapseMenu)"
                            />
                        </li>
                    </ul>
                </li>
                <li class="menu-group">
                    <router-link
                        to="/orders-overview/pre-orders/pre-orders-from-template"
                        class="menu-group-title"
                    >
                        <ButtonPlain
                            :text="$t('offers.offers')"
                            @click.native="collapseMenu"
                        />
                    </router-link>
                </li>
                <li class="menu-group">
                    <router-link
                        to="/orders-overview/pre-orders/pre-order-from-template-summary"
                        class="menu-group-title"
                    >
                        <ButtonPlain
                            :text="$t('preorder.preorderSummary')"
                            @click.native="collapseMenu"
                        />
                    </router-link>
                </li>
            </template>
            <template v-else-if="!orderJourney.isOffer">
                <li
                    v-if="filters.productTypes.length"
                    class="menu-group"
                >
                    <div
                        class="menu-group-title"
                        @click="toggleExpanded('products')"
                    >
                        {{ $t('general.products') }}
                    </div>
                    <ul
                        v-show="!isMobileViewport || state.expanded.products"
                        class="menu-group-items"
                    >
                        <li
                            v-for="item in filters.productTypes"
                            :key="item.id"
                            class="menu-group-item"
                        >
                            <ButtonPlain
                                :text="$t(item.label)"
                                class="font-normal"
                                @click.native="onFilterSelect('productType', item, collapseMenu)"
                            />
                        </li>
                    </ul>
                </li>
                <li
                    v-if="filters.assortments.length"
                    class="menu-group"
                >
                    <div
                        class="menu-group-title"
                        @click="toggleExpanded('assortments')"
                    >
                        {{ $t('general.assortments') }}
                    </div>
                    <ul
                        v-show="!isMobileViewport || state.expanded.assortments"
                        class="menu-group-items"
                    >
                        <li
                            v-for="item in filters.assortments"
                            :key="item.id"
                            class="menu-group-item"
                        >
                            <ButtonPlain
                                :text="$t(item.label)"
                                class="font-normal"
                                @click.native="onFilterSelect('brand', item, collapseMenu)"
                            />
                        </li>
                    </ul>
                </li>
                <li
                    v-if="filters.seasons.length"
                    class="menu-group"
                >
                    <div
                        class="menu-group-title"
                        @click="toggleExpanded('seasons')"
                    >
                        {{ $t('general.seasons') }}
                    </div>
                    <ul
                        v-show="!isMobileViewport || state.expanded.seasons"
                        class="menu-group-items"
                    >
                        <li
                            v-for="item in filters.seasons"
                            :key="item.id"
                            class="menu-group-item"
                        >
                            <ButtonPlain
                                :text="item.label"
                                class="font-normal"
                                @click.native="onFilterSelect('seasons', item, collapseMenu)"
                            />
                        </li>
                    </ul>
                </li>
                <li class="menu-group">
                    <div class="menu-group-title">
                        <ButtonPlain @click.native="onShopAllPreOrder(collapseMenu)">
                            <span>{{ $t('topNavMenu.shopAllPreOrder') }}</span>
                            <i class="fa fa-arrow-right" />
                        </ButtonPlain>
                    </div>
                </li>
            </template>
        </template>
    </OrderJourneyMenu>
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import OrderJourneyMenu from '@/components/menu/customer/OrderJourneyMenu.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { findOrCreateBasketOffer, getOffers } from '@/modules/offers/offers.api.js';
import router from '@/router/router.js';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { computed, reactive } from 'vue';

const props = defineProps({
    availableOrderJourneys: {
        type: Array,
        required: true,
    },

    isMobileViewport: {
        type: Boolean,
        required: true,
    },

    filters: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits([
    'onSelect',
]);

const state = reactive({
    offers: [],
    expanded: {
        products: false,
        assortments: false,
        seasons: false,
        offers: false,
    },
});

const basket = computed(() => store.getters.getCurrentBasket);
const offers = computed(() => state.offers.filter(({ shipToKey }) => shipToKey === basket.value.shipTo.no));
const orderJourney = props.availableOrderJourneys.find(({ value }) => value === Const.ORDER_JOURNEY.PRE_ORDER);

const onFilterSelect = (group, { id }, collapseMenu) => {
    emit('onSelect', {
        orderJourney: orderJourney.value,
        filter: {
            group,
            id,
        },
    });
    collapseMenu();
};

const toggleExpanded = type => {
    if (!props.isMobileViewport) {
        return;
    }

    state.expanded[type] = !state.expanded[type];
};

const onShopAllPreOrder = collapseMenu => {
    emit('onSelect', { orderJourney: orderJourney.value });
    collapseMenu();
};

const goToOffer = async (offer, collapseMenu) => {
    collapseMenu();

    try {
        if (offer.no && offer.compoundOfferStatus?.link) {
            await router.push(offer.compoundOfferStatus.link);
        } else {
            const { data } = await findOrCreateBasketOffer(offer.customerNo, offer.shipToKey || offer.shipTo.no, offer.offerId);

            await router.push(data.compoundOfferStatus.link);
        }
    } catch (error) {
        bugsnag.notify(error);
    }
};

const getOfferBaskets = async () => {
    try {
        const { data } = await getOffers({
            params: {
                customerNo: store.getters.getRealCustomerNo,
            },
        });

        state.offers = data.offers.filter(({ offerStatus, hiddenOfferBasketOfferId }) => offerStatus === Const.OFFER_STATUS_RELEASED && !hiddenOfferBasketOfferId);
    } catch (error) {
        bugsnag.notify(error);
    }
};

if (orderJourney.isOffer) {
    getOfferBaskets();
}

</script>

<style scoped>
.menu-group {
    @apply flex flex-col;
}

.menu-group-title {
    @apply items-start font-bold py-4 w-full;
}

.menu-group-items {
    @apply divide-y divide-grey-light md:divide-none w-full;
}

.menu-group-item {
    @apply py-4 md:py-1;
}
</style>
