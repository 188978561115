import mitt from 'mitt';

const mittInstance = mitt();

export const emitter = {
    $on: (...args) => mittInstance.on(...args),
    $once: (...args) => mittInstance.once(...args),
    $off: (...args) => mittInstance.off(...args),
    $emit: (...args) => mittInstance.emit(...args),
};
