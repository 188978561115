<template>
    <div>
        <div class="flex flex-col gap-1 sm:gap-3 px-6 py-4 sm:py-8">
            <div
                v-if="canUseDistributors && totalQuantityByDistributors > 0"
                class="flex items-center justify-between"
            >
                <span>{{ $t('baskets.quantityByDistributor') }}</span>
                <span>{{ formatUnits(totalQuantityByDistributors) }}</span>
            </div>
            <div class="flex items-center justify-between">
                <span>{{ $t('orderDetails.quantity') }}</span>
                <span>{{ formatUnits(totalQuantityByAsics) }}</span>
            </div>
            <template v-if="shouldShowPrice && showTaxExclusivePrices">
                <div class="flex items-center justify-between">
                    <span>{{ $t('orderDetails.amountExcl') }}</span>
                    <span>{{ formatPrice(netAmount) }}</span>
                </div>
                <div class="flex items-center justify-between">
                    <span>{{ $t('orderDetails.serviceCharge') }}</span>
                    <span>{{ formatPrice(netServiceCharge + addAuthorizationDollar) }}</span>
                </div>
                <div class="flex items-center justify-between">
                    <span>{{ $t('orderDetails.totalVATAmount') }}</span>
                    <span>{{ formatPrice(totalVATAmount) }}</span>
                </div>
            </template>
        </div>
        <div
            v-if="shouldShowPrice"
            class="flex items-center justify-between px-6 py-2 sm:py-8 font-bold bg-secondary-lightest"
        >
            <span>{{ $t('orderDetails.totalAmountIncl') }}</span>
            <span>{{ formatPrice(amountToPay + addAuthorizationDollar) }}</span>
        </div>
    </div>
</template>

<script>
import { Const } from '@/utils/constants.js';

export default {
    name: 'BasketSummaryTotals',

    props: {
        shouldPayInAdvance: {
            type: Boolean,
            required: true,
        },

        totalQuantityByAsics: {
            type: Number,
            required: true,
        },

        totalQuantityByDistributors: {
            type: Number,
            required: true,
        },

        netAmount: {
            type: Number,
            required: true,
        },

        netServiceCharge: {
            type: Number,
            required: true,
        },

        grossServiceCharge: {
            type: Number,
            required: true,
        },

        vatAmount: {
            type: Number,
            required: true,
        },

        amountToPay: {
            type: Number,
            required: true,
        },

        shouldShowPrice: {
            type: Boolean,
            required: true,
        },

        formatUnits: {
            type: Function,
            required: true,
        },

        formatPrice: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            canUseDistributors: false,
        };
    },

    computed: {
        addAuthorizationDollar() { // temporary fix for authorization dollar in Canada subsidiary. TODO: what is a permanent solution?
            return (this.$store.getters.getSubsidiary === Const.SUBSIDIARIES.CANADA && this.shouldPayInAdvance) ? 1 : 0;
        },

        totalVATAmount() {
            return this.vatAmount + this.grossServiceCharge - this.netServiceCharge;
        },

        showTaxExclusivePrices() {
            return this.$store.getters.getSubsidiarySettings.showTaxExclusivePrices;
        },
    },

    created() {
        this.canUseDistributors = Boolean(this.$store.getters.getSubsidiarySettings?.usePartnerDistributors);
    },
};
</script>
