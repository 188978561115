import api from '@/api/api.js';

export function updateCustomerLogo(customerNo, payload) {
    return api.post(`customerCtrl/${customerNo}/logo`, payload);
}

export function deleteCustomerLogo(customerNo) {
    return api.delete(`customerCtrl/${customerNo}/logo`);
}

export function getCustomerLogo(customerNo) {
    return api.get(`customerCtrl/${customerNo}/logo`);
}
