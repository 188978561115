import api from '@/api/api.js';

export function getNotifications() {
    return api.get('notifications');
}

export function markNotificationAsRead(id, payload) {
    return api.patch(`notifications/${id}`, payload);
}

export function getNotificationSettings() {
    return api.get('notifications/settings');
}

export function saveNotificationSettings(payload) {
    return api.post('notifications/settings', payload);
}
