<template>
    <div class="flex flex-col z-10">
        <ButtonPlain
            class="justify-between w-full heading-lg px-8"
            @click.native="toggleMenu"
        >
            <span>{{ $t(activeMenuItem.title) }}</span>
            <i
                class="text-base fa"
                :class="state.expanded ? 'fa-chevron-up' : 'fa-chevron-down'"
            />
        </ButtonPlain>
        <FadeTransition>
            <div
                v-show="state.expanded"
                class="border border-secondary-lightest rounded-md shadow-md divide-y divide-secondary-lightest absolute top-[4.5rem] left-[2rem] right-[2rem] bg-white"
            >
                <slot name="context-actions" />
                <div class="flex flex-col divide-y divide-secondary-lightest">
                    <router-link
                        v-for="{title, path, icon} in menuItems"
                        :key="title"
                        class="px-8 focus:text-primary hover:text-secondary transition-[background-color] duration-300 ease-in"
                        :to="path"
                        active-class="sidebar-menu-item--selected"
                    >
                        <ButtonPlain
                            class="items-center justify-between gap-6 w-full h-[5rem] font-normal"
                            @click.native="toggleMenu"
                        >
                            <span>{{ $t(title) }}</span>
                            <i :class="icon" />
                        </ButtonPlain>
                    </router-link>
                </div>
            </div>
        </FadeTransition>
    </div>
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import FadeTransition from '@/components/fade-transition/FadeTransition.vue';
import { reactive, computed } from 'vue';
import { useRoute } from 'vue-router/composables';

const props = defineProps({
    menuItems: {
        type: Array,
        required: true,
    },
});

const emit = defineEmits([
    'toggle',
]);

const route = useRoute();
const state = reactive({
    expanded: false,
});

const activeMenuItem = computed(() => props.menuItems.find(({ path }) => route.path.includes(path))
    ?? props.menuItems.find(({ path }) => path.includes(route.matched.at(-1).parent.path)));

const toggleMenu = () => {
    state.expanded = !state.expanded;

    emit('toggle', state.expanded);
};

</script>
