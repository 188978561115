import { isObject, unique } from '@/utils/collection.js';
import { normalizeAndRemoveDiacritics } from '@/utils/generic.js';
import _ from 'lodash';
import { Const } from '@/utils/constants.js';

class FuzzySearch {

    constructor() {
        this.fuzzyCache = {};
    }

    splitText(text, withRegex = true) {
        text = _.deburr(text).normalize('NFKC');

        const result = [];
        const parts = text.replace(/\\/g, '\\\\').split(/\s+/);

        parts.forEach(part => {
            if (!withRegex) {
                result.push(part);

                return;
            }

            if (['.', '_', '/', '-'].includes(part)) {
                result.push({
                    part: result[result.length - 1].part + part,
                    regex: new RegExp(`(?:^.*)${result[result.length - 1].part}${part}`, 'i'),
                    and: false,
                });
            } else {
                result.push({
                    part,
                    regex: new RegExp(`(?:^.*)${part}`, 'i'),
                    and: false,
                });
            }
        });

        return result;
    }

    // calculate maximum score for a set of texts (ss) and text (q)
    anyPartCompare(q, ss) {
        let result = 0;
        let score = 0;

        for (let i = 0; i < ss.length; i++) {
            score = this.partCompare(q, ss[i]);
            result = Math.max(result, score);
        }

        return result;
    }

    // calculate distance between texts if the text are both longer than 2 characters
    partCompare(q, s) {
        let result = 0; // no match
        let diff = 0;
        let mi = '';

        if (q.length < 3) {
            if (s.length < q.length) {
                result = 0; // no match
            } else {
                mi = s.substr(0, q.length);
                result = (q === mi) ? 1 : 0;
            }
        } else if (q.length <= 5) {
            mi = s.substr(0, q.length);
            diff = this.fuzzyMatched(q, mi);
            if (diff <= 1) {
                result = 1 - (diff / q.length); // full or partial match
            }
        } else {
            mi = s.substr(0, q.length);
            diff = this.fuzzyMatched(q, mi);
            if (diff <= 2) {
                result = 1 - (diff / q.length); // full or partial match
            }
        }

        return result;
    }

    // inspired by ansarisufiyan777 on 12 Jul 2017 (via github andrei-m/levenshtein.js)
    // calculate distance between texts
    fuzzyMatched(comparer, comparitor) {
        let fuzzyDistance = (this.fuzzyCache[comparer] || {})[comparitor];

        if (isNaN(fuzzyDistance)) {
            const a = comparer.trim().toLowerCase();
            const b = comparitor.trim().toLowerCase();

            if (a.length === 0) {
                return b.length;
            }

            if (b.length === 0) {
                return a.length;
            }

            const matrix = [];

            // increment along the first column of each row
            let i;

            for (i = 0; i <= b.length; i++) {
                matrix[i] = [i];
            }

            // increment each column in the first row
            let j;

            for (j = 0; j <= a.length; j++) {
                matrix[0][j] = j;
            }

            // Fill in the rest of the matrix
            for (i = 1; i <= b.length; i++) {
                for (j = 1; j <= a.length; j++) {
                    if (b.charAt(i - 1) === a.charAt(j - 1)) {
                        matrix[i][j] = matrix[i - 1][j - 1];
                    } else {
                        matrix[i][j] = Math.min(matrix[i - 1][j - 1] + 1, // substitution
                            Math.min(matrix[i][j - 1] + 1, // insertion
                                matrix[i - 1][j] + 1)); // deletion
                    }
                }
            }

            fuzzyDistance = matrix[b.length][a.length];

            if (!this.fuzzyCache[comparer]) {
                this.fuzzyCache[comparer] = {};
            }

            this.fuzzyCache[comparer][comparitor] = fuzzyDistance;
        }

        return fuzzyDistance;
    }
}

class CollectionSearcher {

    constructor($translationFunc) {
        this.fuzzySearchObject = new FuzzySearch();
        this.translationFunc = $translationFunc;

        this.categoryCache = {};
        this.genderCache = {};
        this.productTypeCache = {};
        this.segmentCache = {};
        this.regexes = {};
    }

    evaluate(element, parts) {
        // initialize
        element.colorSearched = false;

        const self = this;

        const result = _.reduce(parts, (carry, part) => {
            if (!carry) {
                return 0;
            }

            const exactMatch =
                self.itemNameCompareFunc(element, part)
                || self.itemCodeCompareFunc(element, part)
                || self.itemTagCompareFunc(element, part)
                || self.collectionCompareFunc(element, part)
                || self.genderCompareFunc(element, part)
                || self.categoryCompareFunc(element, part)
                || self.subCategoryCompareFunc(element, part)
                || self.itemCommentCompareFunc(element, part)
                || self.itemSegmentCompareFunc(element, part)
                || self.eanCodeCompareFunc(element, part);

            if (exactMatch) {
                return carry; // exact match
            }

            const fuzzyScore = self.fuzzyItemNameFunc(element, part);

            if (fuzzyScore > 0) {
                return Math.min(carry, fuzzyScore);
            }

            return Math.min(carry, self.fuzzyColorsFunc(element, part) * 0.99);
        }, 1);

        element.itemMatch = result;

        return result;
    }

    clear() {
        this.categoryCache = {};
        this.genderCache = {};
        this.productTypeCache = {};
        this.segmentCache = {};
        this.regexes = {};
    }

    categoryCompareFunc(item, term) {
        const categories = Array.isArray(item.colors) ? unique(item.colors.map(({ category }) => Object.keys(category)).flat()) : [];

        if (!categories.length) {
            return false;
        }

        let result = false;

        for (const category of categories) {
            const cacheKeys = Object.keys(this.categoryCache);
            const cacheKey = `${category}|${term.part}`;

            if (cacheKeys.includes(cacheKey)) {
                result = this.categoryCache[cacheKey];
            }

            let translatedKeyWord = '';

            if (this.translationFunc) {
                translatedKeyWord = this.translationFunc(`sports.${category}`);

                if (translatedKeyWord === `sports.${category}`) {
                    translatedKeyWord = '';
                }
            }

            result = term.regex.test(`${category} ${translatedKeyWord}`);

            if (result) {
                this.categoryCache[cacheKey] = result;

                break;
            }
        }

        return result;
    }

    collectionCompareFunc(elm, term) {
        const collectionsSet = {
            3100: 'Footwear',
            3200: 'Apparel',
            3300: 'Accessories',
        };

        const collection = collectionsSet[elm['collection']];
        let translatedSelection = collection;

        if (this.translationFunc) {
            translatedSelection = this.translationFunc(`general.category${collection}`);
        }

        return term.regex.test(collection.toLowerCase()) || term.regex.test(translatedSelection.toLowerCase());
    }

    genderCompareFunc(elm, term) {
        const keyword = elm['gender'];
        const cacheKeys = Object.keys(this.genderCache);
        const cacheKey = `${keyword}|${term.part}`;

        if (_.includes(cacheKeys, cacheKey)) {
            return this.genderCache[cacheKey];
        }

        const genderSet = {
            [Const.GENDER_MEN]: 'Men',
            [Const.GENDER_WOMEN]: 'Women',
            [Const.GENDER_KIDS]: 'Kids',
            [Const.GENDER_UNISEX]: 'Unisex',
        };

        const gender = genderSet[elm['gender']];
        let translatedGender = gender;

        if (this.translationFunc) {
            translatedGender = this.translationFunc(`general.gender${elm['gender']}`);
            if (translatedGender === `general.gender${elm['gender']}`) {
                translatedGender = '';
            }
        }

        const result = term.regex.test(gender.toLowerCase()) || term.regex.test(translatedGender.toLowerCase());

        this.genderCache[cacheKey] = result;

        return result;
    }

    itemNameCompareFunc(elm, term) {
        let regex = null;
        const q = term.part;

        if (this.regexes.hasOwnProperty(q)) {
            regex = this.regexes[q];
        } else {
            regex = new RegExp(`(\\bgel)?${q}`, 'i');
            this.regexes[q] = regex;
        }

        const result = regex.test(this.getItemName(elm));

        return result;
    }

    getItemCode(element) {
        return element.code;
    }

    itemCodeCompareFunc(elm, term) {
        return term.regex.test(this.getItemCode(elm));
    }

    itemTagCompareFunc(elm, term) {
        return term.regex.test(elm.tag);
    }

    itemCommentCompareFunc(elm, term) {
        if (!elm.colors || !elm.colors.length) {
            return false;
        }

        if (elm.colors[0].comment) {
            return term.regex.test(elm.colors[0].comment);
        }
    }

    subCategoryCompareFunc(product, query) {
        const { subCategory } = product;
        const cacheKeys = Object.keys(this.productTypeCache);
        const cacheKey = `${subCategory}|${query.part}`;

        if (_.includes(cacheKeys, cacheKey)) {
            return this.productTypeCache[cacheKey];
        }

        let translatedKeyWord = '';

        if (this.translationFunc) {
            const key = `subCategory.${subCategory}`;

            translatedKeyWord = this.translationFunc(key);
            if (key === translatedKeyWord) {
                translatedKeyWord = ''; // undo, translation not found
            }
        }

        const result = query.regex.test(subCategory) || query.regex.test(translatedKeyWord);

        this.productTypeCache[cacheKey] = result;

        return result;
    }

    itemSegmentCompareFunc(product, query) {

        if (!product.colors) {
            // getAddressItemCollectionForSearch does not have colors as a separate array.
            return false;
        }

        const segments = product.colors
            .map(color => Object.values(color.category))
            .flat(2);

        const uniqueSegments = [...new Set(segments)];

        for (const segment of uniqueSegments) {
            const cacheKey = `${segment}|${query.part}`;

            if (this.segmentCache[cacheKey] != null) {
                if (this.segmentCache[cacheKey]) {
                    return true;
                }

                continue;
            }

            const segmentTranslation = this.translationFunc(`segmentsDescriptions.${segment}`);

            this.segmentCache[cacheKey] = query.regex.test(segmentTranslation);

            if (this.segmentCache[cacheKey]) {
                return true;
            }
        }

        return false;
    }

    eanCodeCompareFunc(product, query) {

        if (!product.colors) {
            return false;
        }

        // mvn : note for Audrius that he should change this, as this creates "side effects".
        // I think this note will be still here, if you read this in 2024 ;)
        const colors = product.colors.filter(color => Boolean(color.compactSizes.includes(`@${query.part}|`)));

        if (colors.length) {
            product.colors = colors;
        }

        return Boolean(colors.length);
    }

    getItemName(element) {
        return element.itemName ?? element.name;
    }

    fuzzyItemNameFunc(element, term) {
        // fuzzy match for item name (if part length > 2) * must match all parts *

        // adds words starting with "gel" without 'gel' (if part length > 5)
        const m = this.getItemName(element).trim().toLowerCase().split(/[^a-z0-9]+/).map(s => {
            return s.trim();
        }).filter(s => {
            return (s.length > 2);
        });

        for (let k = 0; k < m.length; k++) {
            if (m[k].length > 5 && m[k].substr(0, 3) === 'gel') {
                m.push(m[k].substr(3));
            }
        }

        return this.fuzzySearchObject.anyPartCompare(term.part, m);
    }

    fuzzyColorScore(colorName, colorCode, term) {
        // exact match for words in color name, color code
        if (term.regex.test(colorName) || term.regex.test(colorCode)) {
            return 1;
        }

        // fuzzy match for color name (if part length > 2)
        const cns = String(colorName).split(/\W/).filter(s => s.length > 2);

        if (!cns.length) {
            return 0;
        }

        return this.fuzzySearchObject.anyPartCompare(term.part, cns);
    }

    fuzzyColorsFunc(element, term) {
        element.colors = element.colors.filter(color => term.regex.test(color.itemNoColorCode) || color.mainColor.toLowerCase().startsWith(term.part.toLowerCase()));

        return Boolean(element.colors.length);
    }
}

class CollectionSeasonSearcher extends CollectionSearcher {
    getItemCode(element) {
        return element.itemNo;
    }

    getItemName(element) {
        return element.itemName;
    }

    fuzzyColorsFunc(element, term) {
        return this.fuzzyColorScore(element.colorName, element.colorCode, term);
    }
}

class SimpleSearch {
    constructor() {
        this.fuzzySearchObject = new FuzzySearch();
    }

    fuzzyTextFunc(text, term) {
        const m = this.fuzzySearchObject.splitText(text, false);

        return this.fuzzySearchObject.anyPartCompare(term.part, m);
    }

    #getTokenizedString(item) {
        let tokenizedString = '';

        for (const key in item) {
            const value = item[key];

            if (key === 'itemMatch') {
                continue;
            }

            if (isObject(value)) {
                tokenizedString += ` ${this.#getTokenizedString(value)}`;
            } else {
                tokenizedString += ` ${value}`;
            }
        }

        return normalizeAndRemoveDiacritics(tokenizedString);
    }

    evaluate(item, parts) {
        const result = parts.reduce((result, part) => {
            // No result, skip
            if (!result) {
                return result;
            }

            const tokenizedString = this.#getTokenizedString(item);

            // Matches 1 whole token in tokenized string
            if (part.regex.test(tokenizedString)) {
                return 1;
            }

            // String part contains numbers only. Do not apply fuzzy search
            if (/^\d+$/.test(part.part)) {
                return 0;
            }

            // Matches any part of a tokenized string
            if (this.fuzzyTextFunc(tokenizedString, part) > 0) {
                return 0.9 * result;
            }

            // No matches
            return 0;
        }, 1);

        item.itemMatch = result; // Mutating side effect

        return result;
    }
}

class CustomerSearcher {

    constructor() {
        this.fuzzySearchObject = new FuzzySearch();
        this.categoryCache = {};
        this.genderCache = {};
        this.productTypeCache = {};
        this.regexes = {};
    }

    fuzzyTextFunc(text, term) {
        const m = this.fuzzySearchObject.splitText(text, false);

        return this.fuzzySearchObject.anyPartCompare(term.part, m);
    }

    evaluate(customer, parts) {
        // initialize

        const result = parts.reduce((result, part) => {
            if (!result) {
                return result;
            }

            const trimmedCustomerNo = customer.no?.substr(-part.part.length) || customer.customer_no?.substr(-part.part.length); // end of string

            let searchText = _.deburr(`${customer.name} ${customer.name2} ${customer.no} ${trimmedCustomerNo} ${customer.postCode} ${customer.address1} ${
                customer.addressName} ${customer.city} ${customer.status} ${customer?.accountTypeName}`).normalize('NFKC');

            if (part.regex.test(searchText)) {
                return result;
            }

            searchText = _.deburr(`${customer.oldCustomerNo} ${customer.accountType} ${customer.countryCode} ${customer.currencyCode}`).normalize('NFKC');
            if (part.regex.test(searchText)) {
                return 0.95 * result;
            }

            let fuz = this.fuzzyTextFunc(_.deburr(`${customer.name} ${customer.name2} ${customer.address1} ${customer.addressName} ${customer.city}`).normalize('NFKC'), part);

            if (fuz > 0) {
                return 0.9 * result;
            }

            fuz = this.fuzzyTextFunc(_.deburr(`${customer.accountType} ${customer.countryCode}`).normalize('NFKC'), part);
            if (fuz > 0) {
                return 0.85 * result;
            }

            return 0;
        }, 1);

        customer.itemMatch = result;

        return result;
    }
}

export { CollectionSearcher, CollectionSeasonSearcher, CustomerSearcher, SimpleSearch };

export default FuzzySearch;
