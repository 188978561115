<template>
    <div class="flex flex-col gap-2 sm:gap-4 max-w-[100vw] sm:max-w-[45rem] px-6">
        <DropDownSelect
            v-if="deliveryAddresses.length > 1"
            v-model="deliveryAddressNo"
            :label="$t('shipToAddress.labelShipToAddress')"
            :options="deliveryAddressOptions"
        />

        <DropDownSelect
            v-if="hasDistributionCenters"
            v-model="distributionCenterNo"
            :label="$t('shipToAddress.labelDistributionCenter')"
            :options="distributionCenterOptions"
        />

        <address
            v-if="selectedDistributionCenter"
            class="hidden sm:flex m-0"
        >
            <span>{{ selectedDistributionCenter.address1 }}</span>
            <span v-if="selectedDistributionCenter.address2">{{ selectedDistributionCenter.address2 }}</span>
            <span>{{ selectedDistributionCenter.postCode }}, {{ selectedDistributionCenter.city }}</span>
            <span>{{ selectedDistributionCenter.countryCode }}</span>
        </address>

        <div
            v-if="deliveryAddressNo !== basketDeliveryAddressNo || (hasDistributionCenters && distributionCenterNo !== basketDistributionCenterNo)"
            class="flex justify-center py-4"
        >
            <Button
                kind="primary-alt"
                :text="$t('general.buttonConfirm')"
                :disabled="addressChangeInProgress"
                @click.native="changeAddress"
            >
                <span :class="['fa', addressChangeInProgress ? 'fa-spin fa-spinner' : 'fa-check']" />
            </Button>
        </div>
    </div>
</template>

<script>
import Button from '@/components/button/Button.vue';
import DropDownSelect from '@/components/drop-down-select/DropDownSelect.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { mapActions } from 'vuex';

export default {
    name: 'MiniBasketAddressSelection',

    components: {
        Button,
        DropDownSelect,
    },

    props: {
        deliveryAddresses: {
            type: Array,
            required: true,
        },

        distributionCenters: {
            type: Array,
            required: true,
        },

        selectedDeliveryAddress: {
            type: Object,
            required: true,
        },

        selectedDistributionCenter: {
            type: Object,
            default: null,
        },

        basketDeliveryCustomerNo: {
            type: String,
            required: true,
        },

        basketDeliveryAddressNo: {
            type: String,
            required: true,
        },

        basketDistributionCenterNo: {
            type: String,
            default: null,
        },

        customerNo: {
            type: String,
            required: true,
        },
    },

    emits: [
        'deliveryAddressSelected',
        'distributionCenterSelected',
    ],

    data() {
        return {
            addressChangeInProgress: false,
        };
    },

    computed: {
        deliveryAddressNo: {
            get() {
                return this.selectedDeliveryAddress.no;
            },

            set(selectedAddressNo) {
                this.$emit('deliveryAddressSelected', selectedAddressNo);
            },
        },

        distributionCenterNo: {
            get() {
                return this.selectedDistributionCenter?.addressNo ?? null;
            },

            set(selectedAddressNo) {
                this.$emit('distributionCenterSelected', selectedAddressNo || null);
            },
        },

        deliveryAddressOptions() {
            return this.deliveryAddresses.filter(({ customerNo }) => customerNo === this.basketDeliveryCustomerNo)
                .map(({ no, name, address1 }) => ({ value: no, text: `${no} - ${name} - ${address1}` }));
        },

        hasDistributionCenters() {
            return this.availableDistributionCenters.length > 0;
        },

        availableDistributionCenters() {
            return this.filterDistributionCenters(this.distributionCenters);
        },

        distributionCenterOptions() {
            return [
                { value: null, text: 'shipToAddress.optionDistributionCenterNone', translate: true },
                ...this.availableDistributionCenters.map(({ addressNo, addressName }) => ({ value: addressNo, text: `${addressNo} - ${addressName}` })),
            ];
        },
    },

    methods: {
        ...mapActions(['fetchCustomerBasketByAddressNo']),

        async changeAddress() {
            this.addressChangeInProgress = true;

            try {
                const orderJourney = this.$store.getters.getCurrentBasket.orderJourney;

                await this.fetchCustomerBasketByAddressNo({
                    addressNo: this.deliveryAddressNo,
                    distributionCenterNo: this.hasDistributionCenters ? (this.distributionCenterNo || 'none') : null,
                    orderJourney,
                });

                if (Number(this.$route.params.basketNo) !== this.$store.getters.getCurrentBasket.no) {
                    await this.$router.replace({ params: { basketNo: this.$store.getters.getCurrentBasket.no }, query: this.$route.query });
                }
            } catch (error) {
                bugsnag.notify(error);
            } finally {
                this.addressChangeInProgress = false;
            }
        },

        filterDistributionCenters(distributionCenters) {
            const currentAddress = this.selectedDeliveryAddress;
            const hasDirect = currentAddress.hasDirect;
            const hasIndirect = currentAddress.hasIndirect;
            const customerNo = currentAddress.customerNo;

            return distributionCenters.filter(distributionCenter => (currentAddress.no !== distributionCenter.addressNo) &&
                ((distributionCenter.clientNo === '') || (distributionCenter.clientNo.search(customerNo) >= 0) ) &&
                (
                    // customer's mark-for with customer's ship-to
                    ((distributionCenter.customerNo === customerNo) && ((distributionCenter.hasIndirect === hasIndirect) || (distributionCenter.hasDirect === hasDirect))) ||
                    // customer's mark-for with payer's ship-to
                    ((distributionCenter.customerNo !== customerNo) && (hasIndirect && distributionCenter.hasDirect))
                ),
            );
        },
    },
};
</script>
