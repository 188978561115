<template>
    <ValidationProvider
        v-slot="{ errors, required, validate, reset }"
        ref="observer"
        tag="div"
        :vid="vid"
        :name="name ?? label ?? id"
        :rules="rules"
        :mode="validationMode"
    >
        <label
            v-if="label"
            :for="id"
            class="flex items-center capitalize mb-1 text-primary font-bold"
            :class="{'text-red-dark': errors.length || $slots.errors}"
        >
            {{ label }}
            <span
                v-if="!required && displayOptional"
                class="ml-2 text-grey-dark font-normal"
            >
                ({{ $t('general.optional') }})
            </span>
            <slot name="label-extra" />
        </label>
        <div
            class="flex items-center border rounded-[0.2rem] focus-within:border-secondary transition h-[3.8rem] w-full leading-[1.6rem]"
            :class="errors.length || $slots.errors ? 'border-red text-red-dark' : 'border-grey text-primary'"
        >
            <span
                v-if="$slots.prefix"
                class="py-4 pl-4"
                :class="{'text-grey': disabled}"
            >
                <slot name="prefix" />
            </span>
            <input
                :id="id"
                v-model.trim="displayValue"
                :type="type"
                :min="type === 'number' ? min : undefined"
                :step="type === 'number' ? step : undefined"
                :autofocus="autofocus"
                :disabled="disabled"
                :readonly="readonly"
                :required="readonly ? false : required"
                :placeholder="placeholder"
                :maxlength="type === 'text' ? maxLength : undefined"
                class="py-4 px-4 h-full w-full"
                autocomplete="off"
                @focusin="reset"
                @blur="onBlur(validate, reset)"
                @keyup="onKeyUp(validate, reset)"
            >
            <span
                v-if="$slots.suffix"
                class="py-4 pr-4"
                :class="{'text-grey': disabled}"
            >
                <slot name="suffix" />
            </span>
        </div>
        <span
            v-if="$slots.note"
            class="text-grey text-sm italic inline-block mt-1 leading-snug print:hidden"
        >
            <slot name="note" />
        </span>
        <span class="text-red-dark text-sm italic inline-block mt-1 leading-snug print:hidden">
            <slot
                v-show="errors.length || $slots.errors"
                name="errors"
            >
                {{ errors[0] }}
            </slot>
        </span>
    </ValidationProvider>
</template>

<script>
export default {
    name: 'TextInput',

    props: {
        id: {
            type: String,
            required: true,
        },

        vid: {
            type: String,
            default: null,
        },

        label: {
            type: String,
            default: null,
        },

        name: {
            type: String,
            default: null,
        },

        type: {
            type: String,
            default: 'text',
            validator(value) {
                const isValid = ['url', 'text', 'password', 'tel', 'number', 'email'].some(type => type === value);

                if (!isValid) {
                    console.warn(`Invalid input type "${value}"`);
                }

                return isValid;
            },
        },

        value: {
            type: [String, Number],
            default: null,
        },

        placeholder: {
            type: String,
            default: null,
        },

        rules: {
            type: [Object, String],
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        readonly: {
            type: Boolean,
            default: false,
        },

        maxLength: {
            type: Number,
            default: 256,
        },

        min: {
            type: Number,
            default: 0,
        },

        step: {
            type: String,
            default: '1',
        },

        displayOptional: {
            type: Boolean,
            default: true,
        },

        autofocus: {
            type: Boolean,
            default: false,
        },

        prefix: {
            type: String,
            default: null,
        },

        suffix: {
            type: String,
            default: null,
        },

        validationMode: {
            type: String,
            default: 'eager',
            validator(value) {
                const isValid = ['aggressive', 'passive', 'lazy', 'eager'].some(type => type === value);

                if (!isValid) {
                    console.warn(`Invalid validation mode "${value}"`);
                }

                return isValid;
            },
        },
    },

    emits: [
        'input',
        'blur',
        'keyup',
    ],

    computed: {
        displayValue: {
            get() {
                return this.value;
            },

            set(value) {
                const isNumberType = this.type === 'number';
                const numberValue = Math.max(this.step === '1' ? Number(value) : parseFloat(value), this.min);

                this.$emit('input', isNumberType && !Number.isNaN(numberValue) ? numberValue : value);
            },
        },
    },

    methods: {
        async inputIsValid(validate) {
            const validation = await validate();

            return validation.valid;
        },

        async onBlur(validate, reset) {
            if (this.readonly) {
                return reset();
            }

            if (!await this.inputIsValid(validate)) {
                return;
            }

            this.$emit('blur', this.displayValue);
        },

        async onKeyUp(validate, reset) {
            if (this.readonly) {
                return reset();
            }

            if (!await this.inputIsValid(validate)) {
                return;
            }

            this.$emit('keyup', this.displayValue);
        },
    },
};
</script>
