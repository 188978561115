<template>
    <Alert
        v-if="!minimumOrderValue.reached"
        kind="warning"
        size="small"
        :message="$t('baskets.minimumOrderValue.thresholdNotReached', {minValue: formatCurrency(minimumOrderValue.threshold), basketValue: formatCurrency(basketValue)})"
    />
</template>

<script setup>
import Alert from '@/components/alert/Alert.vue';
import store from '@/store/store.js';
import { localisedCurrencyFormat } from '@/utils/generic.js';

const props = defineProps({
    minimumOrderValue: {
        type: Object,
        required: true,
    },

    basketValue: {
        type: Number,
        required: true,
    },

    currencyCode: {
        type: String,
        required: true,
    },
});

const formatCurrency = value => localisedCurrencyFormat(value, store.state.subsidiary.locale, props.currencyCode);

</script>
