<template>
    <div
        class="flex items-center justify-center fixed top-0 right-0 left-0 w-full z-[1000] shadow-lg transition-opacity ease-in-out duration-300"
        :class="[
            notificationExists ? 'opacity-100 p-4' : 'opacity-0',
            {
                'bg-green text-white': notificationExists && notification.type === 'success',
                'bg-red-dark text-white': notificationExists && notification.type === 'error'
            }
        ]"
    >
        <template v-if="notificationExists">
            <div class="flex items-center justify-center gap-4 w-full">
                <i :class="['fa fa-lg', state.iconCssClassMap[notification.type]]" />
                <span>{{ notification.message || $t(state.notificationMessageMap[notification.type]) }}</span>
            </div>
            <ButtonPlain
                class="ml-auto"
                kind="white"
                @click.native="hideToastNotification(notification.id)"
            >
                <i class="fa fa-close fa-lg" />
            </ButtonPlain>
        </template>
    </div>
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import { useToastNotification } from '@/composables/use-toast-notification.js';
import { reactive } from 'vue';

const state = reactive({
    iconCssClassMap: {
        success: 'fa-check-circle',
        error: 'fa-exclamation-circle',
    },

    notificationMessageMap: {
        success: 'general.updatedSuccessfully',
        error: 'general.error',
    },
});

const { notification, notificationExists, hideToastNotification } = useToastNotification();

</script>
