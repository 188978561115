<template>
    <div
        v-if="canShow"
        class="chat-overlay print:hidden"
        :class="{'chat-overlay-open': isChatOpen, 'chat-overlay-closed': !isChatOpen, 'full-screen': isFullScreen, '!w-fit': isMobileViewport && !isChatOpen}"
    >
        <div
            class="chat-overlay-wrapper"
            :class="{'full-screen': isFullScreen}"
        >
            <div
                v-show="isChatOpen"
                class="chat-overlay-header-mobile"
                @click.stop="toggleChatOverlay"
            >
                <i class="fa fa-times fa-lg chat-overlay-header-icon" />
            </div>
            <div
                v-show="isChatOpen"
                class="chat-heading"
            >
                <h1>{{ $t('chatbot.heading') }}</h1>
                <button
                    class="btn-lg btn-link chat-full-screen-toggle"
                    @click="toggleFullScreen"
                >
                    <i
                        class="fa"
                        :class="isFullScreen ? 'fa-compress' : 'fa-expand'"
                    />
                </button>
            </div>
            <Spinner
                v-if="!chatUrl"
                :align-center="true"
                icon-css-class="fa-2x"
            />
            <iframe
                v-else
                id="chat-iframe"
                height="100%"
                width="100%"
                :src="chatUrl"
                loading="lazy"
                sandbox="allow-scripts"
                credentialless
                :class="{'open': isChatOpen, 'closed': !isChatOpen}"
            />
            <div
                class="chat-overlay-header"
                @click.stop="toggleChatOverlay"
            >
                <i
                    v-show="isChatOpen"
                    class="fa fa-times fa-2x chat-overlay-header-icon"
                />
                <i
                    v-show="!isChatOpen"
                    class="fa fa-comment fa-2x chat-overlay-header-icon"
                />
            </div>
        </div>
    </div>
</template>

<script>
import api from '@/api/api.js';
import Spinner from '@/components/spinner/Spinner.vue';
import { bugsnag } from '@/libs/bugsnag.js';

export default {
    name: 'SupportChat',

    components: {
        Spinner,
    },

    data() {
        return {
            isChatting: false,
            isChatOpen: false,
            isFullScreen: false,
            chatUrl: '',
        };
    },

    computed: {
        userType() {
            return this.$store.getters.getUserType;
        },

        subsidiary() {
            return this.$store.getters.getSubsidiary;
        },

        canShow() {
            if ((this.subsidiary !== 'auk' && this.subsidiary !== 'asa') || !this.userType) {
                return false;
            }

            switch (this.userType) {
                case 'M':
                case 'Z':
                    return false;
                default:
                    return true;
            }
        },

        isMobileViewport() {
            return this.$store.getters.isMobileViewport;
        },
    },

    methods: {
        toggleChatOverlay() {
            if (this.isChatOpen) {
                this.closeSupportChat();
            } else {
                this.openSupportChat();
            }
        },

        toggleFullScreen() {
            this.isFullScreen = !this.isFullScreen;
        },

        async openSupportChat() {
            if (this.isChatting && !this.isChatOpen) {
                this.isChatOpen = true;

                return;
            }

            this.isChatOpen = true;

            try {
                const { data: { url, subsidiary, token } } = await api.get('chat-url');

                this.chatUrl = `${url}?sub=${subsidiary}&token=${token}`;
                this.isChatting = true;
            } catch (error) {
                bugsnag.notify(error);
            }
        },

        closeSupportChat() {
            this.isChatOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@use '~@variables' as variables;

.chat-overlay {
    position: fixed;
    width: 36rem;
    height: 50rem;
    bottom: 2.4rem;
    right: 2.4rem;
    z-index: 100;
}

.chat-overlay-open {
    height: 57.1rem;

    &.full-screen {
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
    }
}

.chat-overlay-closed {
    width: 5.6rem;
    height: 7.9rem;
}

.chat-overlay-wrapper {
    width: 36rem;
    height: 44.8rem;

    .chat-overlay-closed & {
        width: 5.6rem;
    }

    &.full-screen {
        width: 100%;
        height: 100%;
        right: 0;
        bottom: 0;
    }
}

.chat-overlay-header-mobile {
    display: none;
}

.chat-overlay-header {
    position: relative;
    height: 5.6rem;
    width: 5.6rem;
    border: 1px solid variables.$asics-black;
    background-color: variables.$asics-blue;
    margin-left: auto;
    margin-top: 1rem;
    border-radius: 50%;
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.chat-heading {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: variables.$asics-white;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding: 1rem 3rem;
    box-shadow: 0 2rem 5rem 0 rgba(0, 0, 0, .5);

    h1 {
        font-size: 1.4rem;
        margin: 0;
    }

    button {
        margin: 0;
        padding: 0;
        border: none;
    }
}

#chat-iframe {
    position: relative;
    transition: opacity 1s ease-in-out;
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 1rem 1rem 5rem rgba(0, 0, 0, 0.5);
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
}

#chat-iframe.closed {
    height: 0;
    opacity: 0;
    overflow: hidden;
}

#chat-iframe.open {
    height: 100%;
    opacity: 1;
    overflow: hidden;
    background-color: variables.$asics-white;
}

.chat-overlay-header-icon {
    position: absolute;
    transition: opacity 1s ease-in-out;
    opacity: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: variables.$asics-white;
}

.chat-overlay-header-icon.open {
    opacity: 0;
}

@media only screen and (max-width: 48rem) {
    .chat-overlay {
        width: 100%;
        position: fixed;
        height: 100%;
    }

    .chat-full-screen-toggle {
        display: none;
    }

    .chat-overlay-header-mobile {
        display: flex;
        width: inherit;
        height: 5rem;
        background-color: variables.$asics-blue;

        .chat-overlay-header-icon {
            position: absolute;
            transition: opacity 1s ease-in-out;
            opacity: 1;
            left: 50%;
            top: 3rem;
            transform: translate(-50%, -50%);
            color: variables.$asics-white;
        }
    }

    #chat-iframe {
        border-radius: 0;
    }

    #chat-iframe.closed {
        height: 0;
        opacity: 0;
        overflow: hidden;
    }

    #chat-iframe.open {
        height: 91%;
        opacity: 1;
        overflow: hidden;
    }

    .chat-overlay-open {
        height: 100%;
        bottom: 0;
        right: 0;
    }

    .chat-overlay-closed {
        height: 7rem;
        bottom: 2.4rem;
        right: 2.4rem;
    }

    .chat-overlay-wrapper {
        width: 100%;
        height: 100%;
    }
}
</style>
