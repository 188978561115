<template>
    <ModalDialog
        v-show="state.showModal"
        :closable="false"
        size="small"
    >
        <template #header-title>
            {{ $t('cookieConsentModal.title') }}
        </template>
        <template #body>
            <div>
                {{ $t('cookieConsentModal.body') }} <span><router-link
                    to="/cookie-policy"
                    target="_blank"
                    class="underline"
                >{{ $t('menu.cookiePolicy') }}</router-link></span>.
            </div>
        </template>
        <div class="action-buttons">
            <Button
                :text="$t('general.buttonReject')"
                kind="danger"
                @click.native.stop="reject"
            />
            <Button
                :text="$t('general.buttonAccept')"
                @click.native.stop="accept"
            />
        </div>
    </ModalDialog>
</template>

<script setup>
import Button from '@/components/button/Button.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import router from '@/router/router.js';
import { reactive } from 'vue';

const state = reactive({
    showModal: false,
});

const storedConsentState = localStorage.getItem('ASICS_B2B.GA_COOKIE_ACCEPTED');
const hasAcceptedOrRejected = typeof storedConsentState === 'string' ? ['true', 'false'].some(state => state === storedConsentState) : false;

state.showModal = router.currentRoute.path !== '/cookie-policy' && !hasAcceptedOrRejected && !window.navigator.webdriver; // webdriver is `true` when browser is controlled by automation

const accept = async () => {
    window.localStorage.setItem('ASICS_B2B.GA_COOKIE_ACCEPTED', 'true');

    state.showModal = false;
};

const reject = () => {
    window.localStorage.setItem('ASICS_B2B.GA_COOKIE_ACCEPTED', 'false');

    state.showModal = false;
};

</script>
