<template>
    <table class="text-grey-dark text-sm table-fixed block">
        <thead>
            <tr>
                <th v-if="showChanges" />
                <th
                    v-for="size in item.sizes"
                    :key="size.sizeKey"
                    class="border border-grey bg-grey-light text-center font-normal w-[3.5rem] h-[2.5rem]"
                >
                    {{ size[selectedSizeType] }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-if="showChanges">
                <td class="px-2 h-[2.5rem]">
                    {{ $t('baskets.requestedQuantity') }}
                </td>
                <td
                    v-for="size in item.sizes"
                    :key="size.sizeKey"
                    class="border border-grey bg-white text-center font-normal w-[3.5rem] h-[2.5rem]"
                >
                    {{ size.requestedQuantity }}
                </td>
            </tr>
            <tr>
                <td
                    v-if="showChanges"
                    class="px-2 h-[2.5rem]"
                >
                    {{ $t('baskets.availableQuantity') }}
                </td>
                <td
                    v-for="size in item.sizes"
                    :key="size.sizeKey"
                    class="border border-grey text-center font-normal w-[3.5rem] h-[2.5rem]"
                    :class="size.approvedByUser ? 'bg-white' : (size.quantity === 0 ? 'bg-red-lightest' : 'bg-yellow-lightest')"
                >
                    {{ size.quantity }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'BasketItemSizeAndQuantityGrid',

    props: {
        item: {
            type: Object,
            required: true,
        },

        showBasketChanges: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        selectedSizeType() {
            return `size${this.$store.state.preferences.sizeChart}`;
        },

        showChanges() {
            return this.showBasketChanges && !this.item.approvedByUser;
        },
    },
};
</script>
