<template>
    <header class="print:hidden">
        <MainMenu :key="uniqueUserKey" />
        <ContextMenu />
    </header>
</template>

<script setup>
import MainMenu from '@/components/menu/MainMenu.vue';
import ContextMenu from '@/components/context-menu/ContextMenu.vue';
import store from '@/store/store.js';
import { computed } from 'vue';

const uniqueUserKey = computed(() => `${store.state.subsidiary.id}-${store.getters.username}`);

</script>
