<template>
    <div
        class="flex items-center gap-1 h-[4rem] px-6 py-4 text-white w-full"
        :class="orderJourney.color"
    >
        <span class="text-lg">{{ $t('baskets.basket') }}</span>
        <span class="text-lg">#</span>
        <span class="text-xl truncate capitalize">{{ basket.no }} - {{ $t(orderJourney.translationKey) }}</span>
        <MiniBasketActions
            v-if="showBasketActions"
            :basket="basket"
            @showCopyBasketModal="showCopyBasketModal"
        />
    </div>
</template>

<script setup>
import MiniBasketActions from '@/modules/baskets/mini-basket/MiniBasketActions.vue';
import { Const } from '@/utils/constants.js';
import { getOrderJourneyMap } from '@/utils/generic.js';
import { computed } from 'vue';

const props = defineProps({
    basket: {
        type: Object,
        required: true,
    },

    showBasketActions: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits([
    'showCopyBasketModal',
]);

const orderJourneyMap = getOrderJourneyMap();
const basketOrderJourney = computed(() => props.basket.orderJourney);
const orderJourney = computed(() => {
    switch (true) {
        case props.basket.isPreOrderFromTemplate:
            return orderJourneyMap[Const.ORDER_JOURNEY.PRE_ORDER];
        case props.basket.isFashionContract:
            return orderJourneyMap[Const.ORDER_JOURNEY.FASHION_CONTRACT];
        default:
            return orderJourneyMap[basketOrderJourney.value] ?? orderJourneyMap[Const.ORDER_JOURNEY.AT_ONCE];
    }
});

const showCopyBasketModal = () => {
    emit('showCopyBasketModal');
};

</script>
