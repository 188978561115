import store from '@/store/store.js';
import { computed } from 'vue';

/**
 * Sets up toast notification instance per component
 * @param {number} [timeout=3000] - if value is `-1`, notification will never expire
 * @returns {Object}
 */
export const useToastNotification = (timeout = 3000) => {
    /**
     * Shows toast notification at the top of the page
     * @param {Object} notification - object with `type` and optional `message`
     * @param {number} [customTimeout=0] - if value is `-1`, notification will never expire, if `0` then the default timeout from  `useToastNotification()` will be used
     * @returns {number} - id that can be used to hide notification later
     */
    const showToastNotification = (notification, customTimeout = 0) => {
        const id = store.state.toastNotifications.length + 1;

        store.commit('setToastNotification', { ...notification, id });

        if (timeout !== -1 && customTimeout !== -1) {
            setTimeout(() => {
                hideToastNotification(id);
            }, customTimeout || timeout);
        }

        return id;
    };

    const hideToastNotification = id => {
        store.commit('hideToastNotification', id);
    };

    const notification = computed(() => store.state.toastNotifications.slice().pop());
    const notificationExists = computed(() => store.getters.toastNotificationExists);

    return {
        notification,
        notificationExists,
        showToastNotification,
        hideToastNotification,
    };
};
