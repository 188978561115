<template>
    <div class="flex items-center gap-10 !font-normal">
        <div class="relative flex items-center h-[3.7rem]">
            <div class="absolute top-0 left-6">
                <span
                    v-show="indicateBasketQuantityChange"
                    class="animate-ping absolute top-0 h-full w-full rounded-full opacity-70"
                    :class="resolvedOrderJourney.color"
                />
                <span
                    class="min-w-[1.7rem] h-[1.7rem] flex items-center justify-center text-white text-xs rounded-full p-2 leading-6"
                    :class="resolvedOrderJourney.color"
                >
                    {{ formatNumber(totalQuantity) }}
                </span>
            </div>
            <i class="fa fa-cart-shopping fa-lg" />
        </div>
        <div class="flex flex-col h-[4.25rem] text-sm overflow-hidden">
            <address class="m-0 truncate">
                <span class="w-full">{{ deliveryAddress.name }} - {{ deliveryAddress.customerNo }}</span>
            </address>
            <address class="m-0 truncate">
                <span>{{ deliveryAddress.address1 }} - {{ deliveryAddress.no }}</span>
            </address>
            <span
                v-if="shouldShowPrice"
                class="truncate w-full font-bold"
            >
                {{ $t('baskets.subtotal') }}: {{ netAmount }}
            </span>
        </div>
    </div>
</template>

<script>
import { Const } from '@/utils/constants.js';
import { emitter } from '@/utils/event-emitter.js';
import { getOrderJourneyMap } from '@/utils/generic.js';

export default {
    name: 'MiniBasketSummary',

    props: {
        deliveryAddress: {
            type: Object,
            required: true,
        },

        netAmount: {
            type: String,
            required: true,
        },

        totalQuantity: {
            type: Number,
            required: true,
        },

        shouldShowPrice: {
            type: Boolean,
            required: true,
        },

        orderJourney: {
            type: String,
            required: true,
        },

        isPreOrderFromTemplate: {
            type: Boolean,
            required: true,
        },

        isFashionContract: {
            type: Boolean,
            required: true,
        },

        formatNumber: {
            type: Function,
            required: true,
        },
    },

    data() {
        return {
            indicateBasketQuantityChange: false,
        };
    },

    computed: {
        resolvedOrderJourney() {
            const orderJourneyMap = getOrderJourneyMap();

            switch (true) {
                case this.isPreOrderFromTemplate:
                    return orderJourneyMap[Const.ORDER_JOURNEY.PRE_ORDER];
                case this.isFashionContract:
                    return orderJourneyMap[Const.ORDER_JOURNEY.FASHION_CONTRACT];
                default:
                    return orderJourneyMap[this.orderJourney] ?? orderJourneyMap[Const.ORDER_JOURNEY.AT_ONCE];
            }
        },
    },

    created() {
        emitter.$on('basketUpdated', this.onBasketUpdated);
    },

    methods: {
        onBasketUpdated({ basket }) {
            if (basket.totalQuantity === this.totalQuantity) {
                return;
            }

            this.indicateBasketQuantityChange = true;

            setTimeout(() => {
                this.indicateBasketQuantityChange = false;
            }, 1000);
        },
    },
};
</script>
