<template>
    <div>
        <SettingsDropdownMenu />
        <CustomersDropdownMenu />
        <EmployeesDropdownMenu />
        <StatisticsDropdownMenu />
        <CommunicationsDropdownMenu v-if="blogAvailable" />
        <ToolboxDropdownMenu />
        <SupportMenu />
        <UserProfileMenu
            mobile
            @closeMenu="$emit('closeMenu')"
        />
    </div>
</template>

<script>
import CustomersDropdownMenu from '@/components/menu/admin/CustomersDropdownMenu.vue';
import SettingsDropdownMenu from '@/components/menu/admin/SettingsDropdownMenu.vue';
import EmployeesDropdownMenu from '@/components/menu/admin/EmployeesDropdownMenu.vue';
import StatisticsDropdownMenu from '@/components/menu/admin/StatisticsDropdownMenu.vue';
import CommunicationsDropdownMenu from '@/components/menu/CommunicationsDropdownMenu.vue';
import ToolboxDropdownMenu from '@/components/menu/admin/ToolboxDropdownMenu.vue';
import SupportMenu from '@/components/menu/SupportMenu.vue';
import UserProfileMenu from '@/components/menu/user-profile-menu/UserProfileMenu.vue';

export default {
    name: 'AdminMenu',

    components: {
        CommunicationsDropdownMenu,
        ToolboxDropdownMenu,
        StatisticsDropdownMenu,
        EmployeesDropdownMenu,
        SettingsDropdownMenu,
        CustomersDropdownMenu,
        SupportMenu,
        UserProfileMenu,
    },

    computed: {
        blogAvailable() {
            return this.$store.state.subsidiary.blogEnabled;
        },
    },
};
</script>
