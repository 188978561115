<template>
    <ModalDialog
        size="small"
        :closable="false"
    >
        <template #header-title>
            {{ $t('trustedDevices.addToListDialog.title') }}
        </template>
        <template #body>
            <ValidationObserver
                v-slot="{ invalid }"
                tag="form"
                novalidate
                class="flex flex-col gap-4"
                @submit.prevent="submit"
            >
                <p class="leading-snug">
                    {{ $t('trustedDevices.addToListDialog.info') }}
                </p>
                <TextInput
                    id="trusted-device-name"
                    v-model="state.payload.name"
                    :max-length="255"
                    :label="$t('trustedDevices.addToListDialog.name')"
                    rules="required|min:3"
                >
                    <template
                        v-if="state.errors.name"
                        #errors
                    >
                        {{ state.errors.name[0] }}
                    </template>
                </TextInput>
                <div class="action-buttons">
                    <Button
                        :disabled="state.submitInProgress"
                        :text="$t('general.buttonSkip')"
                        kind="primary-alt"
                        @click.native="skip"
                    />
                    <Button
                        :disabled="invalid || state.submitInProgress"
                        :text="$t('general.buttonAdd')"
                        type="submit"
                    >
                        <i
                            v-if="state.submitInProgress"
                            class="fa fa-spinner fa-spin"
                        />
                    </Button>
                </div>
            </ValidationObserver>
        </template>
    </ModalDialog>
</template>

<script setup>

import Button from '@/components/button/Button.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import { addTrustedDevice } from '@/modules/account/account.api.js';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { reactive } from 'vue';
import TextInput from '@/components/text-input/TextInput.vue';
import { bugsnag } from '@/libs/bugsnag';

const emit = defineEmits([
    'close',
]);

const state = reactive({
    submitInProgress: false,
    payload: {
        name: null,
    },
    errors: {},
});

const skip = () => {
    emit('close');
};

const submit = async () => {
    state.submitInProgress = true;

    try {
        await addTrustedDevice({ ...state.payload });

        emit('close');
    } catch (error) {
        if (error.response?.status === Const.HTTP_STATUS.UNPROCESSABLE_CONTENT) {
            state.errors = store.getters.apiErrors;
        } else {
            bugsnag.notify(error);
        }
    }

    state.submitInProgress = false;
};

</script>
