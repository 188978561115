import api, { createTransformingApiInstance } from '@/api/api.js';

export function getCustomerSeasons(customerNo) {
    return api.get(`customers/${customerNo}/seasons`, {
        params: {
            sort: 'desc',
            limit: 4,
        },
    });
}

export function getCustomerAddresses(customerNo, params) {
    const transformingApi = createTransformingApiInstance();

    return transformingApi.get(`customers/${customerNo}/addresses`, { params });
}

export function getCustomerAddress(customerNo, addressNo) {
    const transformingApi = createTransformingApiInstance();

    return transformingApi.get(`customers/${customerNo}/addresses/${addressNo}`);
}

export function getOrderJourneys(customerNo) {
    return api.get(`customers/${customerNo}/order-journeys`);
}
