<template>
    <ModalDialog
        :closable="false"
        size="small"
    >
        <template #header-title>
            {{ $t('mfa.setupMfa') }}
        </template>

        <template #body>
            <div class="flex flex-col gap-4">
                <div
                    v-if="state.mfaPopupContent"
                    v-html="state.mfaPopupContent"
                />
                <div>
                    {{ $t('mfa.setupMfaDescription') }}
                </div>
                <UserMfaEmail
                    :username="props.username"
                    :mfa-email="props.mfaEmail"
                    :mfa-email-required="true"
                    :autofocus="true"
                    :show-success-snackbar-on-updated="false"
                    @updated="updateMfaEmail"
                />
            </div>
        </template>
    </ModalDialog>
</template>

<script setup>

import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import UserMfaEmail from '@/components/user-mfa-email/UserMfaEmail.vue';
import store from '@/store/store.js';
import { getSettings } from '@/modules/admin/settings/mfa/mfa-settings.api.js';
import { bugsnag } from '@/libs/bugsnag.js';
import { reactive } from 'vue';

const props = defineProps({
    username: {
        type: String,
        required: true,
    },

    mfaEmail: {
        type: String,
        default: null,
    },
});

const state = reactive({
    mfaPopupContent: null,
});

const updateMfaEmail = mfaEmail => {
    store.commit('updateMfaEmail', mfaEmail);
};

const fetchPopupContent = async () => {
    try {
        const { data } = await getSettings();

        state.mfaPopupContent = data.dropShipPopupContent;
    } catch (error) {
        bugsnag.notify(error);
    }
};

fetchPopupContent();

</script>
