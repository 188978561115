<template>
    <FooterDropDownMenu>
        <template #title>
            {{ $t('adminBlogs.communicationsMenu') }}
        </template>
        <template #dropdown>
            <li v-if="canManageBlog">
                <router-link to="/blog-manager">
                    {{ $t('adminMainMenu.blogManager') }}
                </router-link>
            </li>
            <li>
                <router-link to="/blog">
                    {{ $t('adminMainMenu.blog') }}
                </router-link>
            </li>
        </template>
    </FooterDropDownMenu>
</template>

<script setup>
import FooterDropDownMenu from '@/components/footer-menu/FooterDropDownMenu.vue';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';

const canManageBlog = store.getters.getUserCan(Const.PERMISSION_CAN_MANAGE_BLOG);

</script>
