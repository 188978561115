<template>
    <div
        v-if="!dataLoadingState.loading"
        class="flex flex-col items-center sm:flex-row gap-4 sm:gap-8 sm:justify-between"
    >
        <template v-for="transitionState in state.transitionStates">
            <Button
                v-if="!transitionState.disabled && minimumOrderValueReached"
                :key="transitionState.nextStatus"
                :text="transitionState.nextStatusText"
                :disabled="basketIsEmpty || state.transitionInProgress"
                class="w-full sm:w-auto"
                @click.native="transition(transitionState)"
            >
                <i
                    v-show="transitionState.transitionInProgress"
                    class="fa fa-spinner fa-spin"
                />
            </Button>
            <Alert
                v-else-if="!basketIsEmpty && minimumOrderValueReached"
                :key="transitionState.nextStatus"
                :message="transitionState.nextStatusText"
                size="small"
            />
        </template>
        <BasketMinimumOrderValue
            v-if="minimumOrderValueRequired"
            :minimum-order-value="basket.minimumOrderValue"
            :basket-value="basket.totals.amountToPay"
            :currency-code="basket.currencyCode"
        />
        <ConfirmationDialog
            v-if="state.confirmationDialogVisible"
            :message="confirmationMessage"
            :no-button-label="$t('general.buttonCancel')"
            :yes-button-label="$t('general.buttonOk')"
            @cancel="hideConfirmationDialog"
            @confirm="onConfirm"
        />
    </div>
</template>

<script setup>
import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import { useDataLoading } from '@/composables/use-data-loading.js';
import { bugsnag } from '@/libs/bugsnag.js';
import BasketMinimumOrderValue from '@/modules/baskets/basket/BasketMinimumOrderValue.vue';
import { getBasket } from '@/modules/baskets/baskets.api.js';
import { getTransitionStates, transitionToNextState } from '@/modules/baskets/mini-basket/pre-order-template.api.js';
import router from '@/router/router.js';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { computed, reactive } from 'vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import { useI18n } from '@/composables/use-i18n.js';

const { t } = useI18n();

const props = defineProps({
    basket: {
        type: Object,
        required: true,
    },

    basketIsEmpty: {
        type: Boolean,
        required: true,
    },
});

const state = reactive({
    transitionStates: [
        {
            nextStatus: null,
            nextStatusText: 'loading',
            disabled: true,
            transitionInProgress: false,
        },
    ],
    errorStateData: {
        nextStatus: null,
        nextStatusText: 'error',
        disabled: true,
    },
    transitionPendingConfirmation: null,
    dataLoaded: false,
    transitionInProgress: false,
    confirmationDialogVisible: false,
});

const { loadData, dataLoadingState } = useDataLoading();
const minimumOrderValueRequired = computed(() => Boolean(props.basket.minimumOrderValue));
const minimumOrderValueReached = computed(() => minimumOrderValueRequired.value ? props.basket.minimumOrderValue.reached : true);
const confirmationMessage = computed(() => {
    if (store.state.subsidiary.preorderTemplateSubmissionGracePeriod) {
        return t('preorderBasketCheckout.finalizeConfirmationWithGracePeriod', { hours: store.state.subsidiary.preorderTemplateSubmissionGracePeriod / 60 });
    } else {
        return t('preorderBasketCheckout.finalizeConfirmationDefault');
    }
});

const showConfirmationDialog = () => state.confirmationDialogVisible = true;

const hideConfirmationDialog = () => state.confirmationDialogVisible = false;

const redirectToCheckout = async () => {
    try {
        await router.push(`/preorders/${props.basket.no}/checkout`);
    } catch (error) {
        bugsnag.notify(error);
    }
};

const transition = async transitionState => {
    if (transitionState.nextStatus !== Const.BASKET_OFFER_STATUS.FINALIZED) {
        await submitTransition(transitionState);

        return;
    }

    if (store.state.subsidiary.canUserEditOfferPurchaseOrderNumber) {
        await redirectToCheckout();
    } else {
        showConfirmationDialog();

        state.transitionPendingConfirmation = transitionState;
    }
};

const submitTransition = async transitionState => {
    state.transitionInProgress = true;
    transitionState.transitionInProgress = true;

    try {
        await transitionToNextState(props.basket.no, { nextStatus: transitionState.nextStatus });
        await updatePreOrderFromTemplateBasket();
        await fetchStates();
    } catch (error) {
        state.transitionStates = [state.errorStateData];

        bugsnag.notify(error);
    } finally {
        state.transitionInProgress = false;
        transitionState.transitionInProgress = false;
    }
};

const onConfirm = async () => {
    hideConfirmationDialog();

    await submitTransition(state.transitionPendingConfirmation);
};

const updatePreOrderFromTemplateBasket = async () => {
    try {
        const { data } = await getBasket(props.basket.no);

        store.commit('updateOffer', data);

        if ([Const.BASKET_OFFER_STATUS.MULTIPLEXING, Const.BASKET_OFFER_STATUS.COMPLETE].includes(data.offerStatus)) {
            return router.push(`/offers/${props.basket.no}`);
        }
    } catch (error) {
        bugsnag.notify(error);
    }
};

const fetchStates = async () => {
    try {
        const { data } = await getTransitionStates(props.basket.no);

        state.transitionStates = data.map(state => ({
            ...state,
            transitionInProgress: false,
        }));
        state.dataLoaded = true;
    } catch (error) {
        bugsnag.notify(error);
    }
};

loadData([
    fetchStates,
]);

</script>
