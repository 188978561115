<template>
    <DropDownMenu>
        <template #title>
            {{ $t('adminBlogs.communicationsMenu') }}
        </template>
        <template #dropdown>
            <li v-if="blogManagerAvailable">
                <router-link to="/blog-manager">
                    {{ $t('adminMainMenu.blogManager') }}
                </router-link>
            </li>
            <li>
                <router-link to="/blog">
                    {{ $t('adminMainMenu.blog') }}
                </router-link>
            </li>
        </template>
    </DropDownMenu>
</template>

<script>
import DropDownMenu from '@/components/menu/DropDownMenu.vue';
import { Const } from '@/utils/constants';

export default {
    name: 'CommunicationsDropdownMenu',

    components: {
        DropDownMenu,
    },

    computed: {
        blogManagerAvailable() {
            return this.$store.state.subsidiary.blogEnabled && this.$store.getters.getUserCan(Const.PERMISSION_CAN_MANAGE_BLOG);
        },
    },
};
</script>
