<template>
    <ValidationObserver
        ref="validationObserver"
        tag="form"
        novalidate
        class="flex flex-col w-full"
        @submit.prevent="submit"
    >
        <TextInput
            id="username"
            v-model="state.payload.username"
            autofocus
            rules="required|min:3"
            autocomplete="username"
            validation-mode="passive"
            :label="$t('general.username')"
        />
        <TextInput
            id="password"
            v-model="state.payload.password"
            type="password"
            rules="required|min:1"
            autocomplete="current-password"
            validation-mode="passive"
            :label="$t('general.labelPassword')"
        />

        <div
            v-if="state.errorMessageTranslationKey"
            class="mb-4 text-red-dark"
        >
            {{ $t(state.errorMessageTranslationKey) }}
        </div>

        <div class="flex items-center gap-4 ml-auto mb-6">
            <router-link
                to="/authentication/password-reset-request"
                class="underline"
            >
                {{ $t('authentication.resetPassword') }}
            </router-link>
            <router-link
                to="/authentication/forgotten-username"
                class="underline"
            >
                {{ $t('authentication.forgotUsernameLink') }}
            </router-link>
        </div>

        <Button
            type="submit"
            :text="$t('general.buttonLogIn')"
            :disabled="state.submitInProgress"
            class="w-full"
        >
            <i
                v-show="state.submitInProgress"
                class="fa fa-spinner fa-spin"
            />
        </Button>

        <MultiFactorAuthenticationEmailDialog
            v-if="state.showUserMfaEmailDialog"
            :mfa-popup-content="state.mfaPopupContent"
            @emailAdded="hideUserMfaEmailDialog"
        />
        <MultiFactorAuthenticationDialog
            v-if="state.showMultiFactorAuthenticationDialog"
            :subsidiary="selectedSubsidiaryKey"
            :username="state.payload.username"
            :mfa-email="state.mfaEmail"
            @verified="submit"
            @emailChangeRequested="showUserMfaEmailDialog"
        />
        <MultiFactorAuthenticationTrustedDeviceDialog
            v-if="state.showTrustedDeviceDialog"
            @close="hideMfaTrustedDeviceDialog"
        />
    </ValidationObserver>
</template>

<script setup>
import Button from '@/components/button/Button.vue';
import TextInput from '@/components/text-input/TextInput.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { initializeCsrfProtection } from '@/modules/unauthenticated/login/login.api.js';
import { isTrustedDevice } from '@/modules/account/account.api.js';
import MultiFactorAuthenticationDialog from '@/modules/unauthenticated/login/MultiFactorAuthenticationDialog.vue';
import MultiFactorAuthenticationEmailDialog from '@/modules/unauthenticated/login/MultiFactorAuthenticationEmailDialog.vue';
import MultiFactorAuthenticationTrustedDeviceDialog from '@/modules/unauthenticated/login/MultiFactorAuthenticationTrustedDeviceDialog.vue';
import router from '@/router/router.js';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { reactive, ref } from 'vue';

const props = defineProps({
    selectedSubsidiaryKey: {
        type: String,
        required: true,
    },
});

const state = reactive({
    errorMessageTranslationKey: null,
    submitInProgress: false,
    showUserMfaEmailDialog: false,
    showMultiFactorAuthenticationDialog: false,
    mfaPopupContent: null,
    showTrustedDeviceDialog: false,
    redirectToRoute: null,
    mfaEmail: null,
    payload: {
        username: null,
        password: null,
    },
});

const validationObserver = ref(null);

const showUserMfaEmailDialog = () => {
    state.showUserMfaEmailDialog = true;
    state.showMultiFactorAuthenticationDialog = false;
};

const hideUserMfaEmailDialog = email => {
    state.mfaEmail = email;
    state.showUserMfaEmailDialog = false;
    state.showMultiFactorAuthenticationDialog = true;
};

const checkIfMfaDeviceIsTrusted = async () => {
    try {
        await isTrustedDevice();
        await redirect();
    } catch (error) {
        if (error.response?.status === Const.HTTP_STATUS.NOT_FOUND) {
            state.showTrustedDeviceDialog = true;
        } else {
            bugsnag.notify(error);
        }
    } finally {
        state.showMultiFactorAuthenticationDialog = false;
    }
};

const hideMfaTrustedDeviceDialog = async () => {
    state.showTrustedDeviceDialog = false;

    await redirect();
};

const completeAuthenticationSteps = async (mfaEmailRequired, mfaRequired, mfaChallengeId, mfaPopupContent) => {

    if (mfaEmailRequired) {
        state.mfaPopupContent = mfaPopupContent;

        showUserMfaEmailDialog();

        return;
    }

    if (mfaRequired) {
        state.showMultiFactorAuthenticationDialog = true;
    } else if (mfaChallengeId) {
        await checkIfMfaDeviceIsTrusted();
    } else {
        await redirect();
    }
};

const redirect = async () => {
    try {
        await router.push(state.redirectToRoute);
    } catch (error) {
        bugsnag.notify(error);
    }
};

const initializeCsrf = async () => {
    try {
        await initializeCsrfProtection();
    } catch (error) {
        bugsnag.notify(error);

        await router.go(0);
    }
};

const submit = async ({ mfaChallengeId }) => {
    if (!await validationObserver.value.validate()) {
        return;
    }

    state.showMultiFactorAuthenticationDialog = false;
    state.submitInProgress = true;

    try {
        // Before logging in, we need to initialize CSRF protection on the server. See https://laravel.com/docs/11.x/sanctum#csrf-protection
        await initializeCsrf();

        const { url, mfaRequired, mfaEmailRequired, mfaPopupContent } = await store.dispatch('globalStore/authenticate', {
            ...state.payload,
            subsidiary: props.selectedSubsidiaryKey,
            mfaChallengeId,
        });

        // url is returned in case of legacy customer verification for AJP and NISHI
        state.redirectToRoute = url ?? { name: 'authenticated', query: { redirectTo: router.currentRoute.query.redirectTo ?? null } };

        await completeAuthenticationSteps(mfaEmailRequired, mfaRequired, mfaChallengeId, mfaPopupContent);
    } catch (error) {
        bugsnag.notify(error);

        // TODO: Check if we no longer get 404 errors in bugsnag and remove this `if` statement
        if (error?.response?.status === Const.HTTP_STATUS.NOT_FOUND) {
            await store.dispatch('globalStore/setUnauthenticated');
            await router.go(0);
        }

        state.errorMessageTranslationKey = error?.response?.data?.message ?? null;
        state.submitInProgress = false;
    }
};

</script>
