<template>
    <FooterDropDownMenu :columns="columns">
        <template #title>
            {{ $t('footerMenu.support') }}
        </template>
        <template #dropdown>
            <li v-if="canViewFaq">
                <router-link
                    to="/help"
                    target="_blank"
                    role="menuitem"
                >
                    {{ $t('footerMenu.faqs') }}
                </router-link>
            </li>
            <template v-if="state.subsidiary === state.SUBSIDIARIES.UNITED_STATES">
                <li>
                    <a
                        href="https://legal.asics.com/en-us/legal/cookie-policy"
                        target="_blank"
                        role="menuitem"
                    >
                        {{ $t('menu.cookiePolicy') }}
                    </a>
                </li>
                <li>
                    <a
                        href="https://legal.asics.com/en-us/legal/privacy-policy"
                        target="_blank"
                        role="menuitem"
                    >
                        ASICS Privacy Policy (Updated)
                    </a>
                </li>
                <li class="break-all">
                    <a
                        href="https://legal.asics.com/en-us/legal/california-laws"
                        target="_blank"
                        role="menuitem"
                    >
                        California Privacy Notice (for California residents)
                    </a>
                </li>
                <li>
                    <router-link
                        to="/policy/terms-of-use"
                        target="_blank"
                        role="menuitem"
                    >
                        Terms of Use
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/policy/umap"
                        target="_blank"
                        role="menuitem"
                    >
                        UMAP Policy ASICS
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/policy/advertising-policy"
                        target="_blank"
                        role="menuitem"
                    >
                        Advertising Policy
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/policy/brand-protection-policy"
                        target="_blank"
                        role="menuitem"
                    >
                        {{ $t('legal.menuBrandProtectionPolicy') }}
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/policy/retailer-terms"
                        target="_blank"
                        role="menuitem"
                    >
                        {{ $t('customerMainMenu.termAndConditions') }}
                    </router-link>
                </li>
            </template>
            <template v-else>
                <li>
                    <router-link
                        to="/policy/cookie"
                        target="_blank"
                        role="menuitem"
                    >
                        {{ $t('menu.cookiePolicy') }}
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/policy/sales-conditions"
                        target="_blank"
                        role="menuitem"
                    >
                        {{ $t('footerMenu.termsAndConditions') }}
                    </router-link>
                </li>
                <li v-if="isInternalUser">
                    <router-link
                        to="/settings/release-notes"
                        role="menuitem"
                    >
                        {{ $t('releaseNotes.pageHeader') }}
                    </router-link>
                </li>
            </template>
            <template v-if="showLegalDocuments">
                <li v-if="showSignableLegalDocument">
                    <ButtonPlain
                        :text="$t('legal.menuToLegalAgreementModal')"
                        role="menuitem"
                        class="font-normal text-left"
                        @click.native="openLegalApprovementModal"
                    />
                </li>
                <li>
                    <ButtonPlain
                        :text="$t('legal.menuToLegalAgreementDownload')"
                        role="menuitem"
                        class="font-normal text-left"
                        @click.native="downloadAgreementPdf"
                    />
                </li>
            </template>
            <li v-if="[state.SUBSIDIARIES.CHILE, state.SUBSIDIARIES.COLOMBIA].includes(state.subsidiary)">
                <router-link
                    :to="`/policy/privacy-policy/${state.subsidiary.id}`"
                    target="_blank"
                    role="menuitem"
                >
                    {{ $t('menu.privacyPolicy') }}
                </router-link>
            </li>
            <li v-if="state.subsidiary === state.SUBSIDIARIES.UNITED_STATES">
                <router-link
                    to="/policy/dropship-addendum"
                    target="_blank"
                    role="menuitem"
                >
                    {{ $t('footerMenu.dropShipAddendum') }}
                </router-link>
            </li>
            <li v-if="state.subsidiary === state.SUBSIDIARIES.CANADA">
                <router-link
                    to="/user-agreement"
                    target="_blank"
                    role="menuitem"
                >
                    {{ $t('footerMenu.userAgreement') }}
                </router-link>
            </li>
            <li v-if="state.subsidiary === state.SUBSIDIARIES.UNITED_STATES">
                <router-link
                    to="/contact-us"
                    target="_blank"
                    role="menuitem"
                >
                    {{ $t('footerMenu.contact') }}
                </router-link>
            </li>
            <li v-if="canViewTickets">
                <router-link
                    to="/support/service-portal"
                    role="menuitem"
                >
                    {{ $t('adminTickets.servicePortal') }}
                </router-link>
            </li>
        </template>
    </FooterDropDownMenu>
</template>

<script setup>
import api from '@/api/api.js';
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import FooterDropDownMenu from '@/components/footer-menu/FooterDropDownMenu.vue';
import { useFileDownload } from '@/composables/use-file-download.js';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { emitter } from '@/utils/event-emitter.js';
import { computed, reactive } from 'vue';

const state = reactive({
    accountType: store.state.user._type,
    subsidiary: store.state.subsidiary.id,
    SUBSIDIARIES: Const.SUBSIDIARIES,
});

const columns = computed(() => state.subsidiary === Const.SUBSIDIARIES.UNITED_STATES ? 3 : 1);
const canViewFaq = computed(() => {
    switch (state.accountType) {
        case Const.ACCOUNT_TYPE_CUSTOMER:
            return ['adg', 'aag', 'apo', 'abx', 'ase', 'ano', 'adk', 'auk', 'afr', 'aib', 'ait', 'aop', 'aac', 'aca'].includes(state.subsidiary);
        case Const.ACCOUNT_TYPE_SALES_REP:
            return state.subsidiary === Const.SUBSIDIARIES.CANADA;
        default:
            return false;
    }
});

const isInternalUser = computed(() => [Const.ACCOUNT_TYPE_SALES_REP, Const.ACCOUNT_TYPE_ADMIN].includes(state.accountType));
const isLegalApproverForCustomer = computed(() => state.subsidiary === Const.SUBSIDIARIES.UNITED_STATES && store.getters.isLegalApproverForCustomer);
const canViewLegalDocuments = computed(() => store.getters.getUserCan(Const.PERMISSIONS.CAN_VIEW_LEGAL_DOCUMENTS));
const legalDocumentHasBeenSigned = computed(() => Boolean(store.getters.getLegalApproval.customerNo));
const showLegalDocuments = computed(() => state.subsidiary === state.SUBSIDIARIES.UNITED_STATES && (isLegalApproverForCustomer.value || canViewLegalDocuments.value));
const showSignableLegalDocument = computed(() => isLegalApproverForCustomer.value || (canViewLegalDocuments.value && legalDocumentHasBeenSigned.value));

const canViewTickets = computed(() => store.state.subsidiary.ticketsEnabled);

const openLegalApprovementModal = () => {
    emitter.$emit('openAgreementModal');
};

const downloadAgreementPdf = async () => {
    const { download } = useFileDownload(() => api.get('legal/online-seller-agreement', {
        responseType: 'blob',
        headers: {
            Accept: Const.MIME_TYPE.PDF,
        },
    }));

    await download();
};

</script>
