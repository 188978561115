<template>
    <div class="flex flex-col">
        <div class="flex items-center justify-between py-4 px-6 bg-secondary-lightest">
            <div class="flex items-center gap-8">
                <div class="flex items-center gap-2 font-bold text-sm">
                    <span>{{ $t('orderDetails.requestedDeliveryOn') }}:</span>
                    <span>{{ formatDate(date) }}</span>
                </div>
                <ButtonPlain
                    class="text-grey-dark"
                    @click.native="toggleItemView"
                >
                    <i :class="['fa', expanded ? 'fa-minus' : 'fa-plus']" />
                </ButtonPlain>
            </div>
            <ButtonPlain
                kind="neutral"
                :title="$t('general.buttonRemove')"
                class="text-sm hover:text-red-dark"
                :disabled="removeItemsPerDateInProgress"
                @click.native="removeItemsPerDate(date)"
            >
                <i :class="['fa', removeItemsPerDateInProgress ? 'fa-spinner fa-spin' : 'fa-trash']" />
            </ButtonPlain>
        </div>

        <FadeTransition>
            <div
                v-show="expanded"
                class="overflow-auto px-2"
            >
                <table class="w-full">
                    <tbody>
                        <template v-for="(item, key, index) in items">
                            <BasketItem
                                :key="`${item.itemNo}-${basketInfo.isCustomizedOrder ? item.recipeId : item.colorCode}`"
                                :basket-info="basketInfo"
                                :item="item"
                                :should-show-price="shouldShowPrice"
                                :compact-view="true"
                                :class="{'border-t border-grey-light': item.approvedByUser && index > 0}"
                                @removeItem="removeItem(date, $event)"
                            />
                            <tr :key="`size-and-quantity-grid-${item.itemNo}-${basketInfo.isCustomizedOrder ? item.recipeId : item.colorCode}`">
                                <td colspan="3">
                                    <div class="p-4 pt-0">
                                        <BasketItemSizeAndQuantityGrid :item="item" />
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </FadeTransition>

        <slot name="totals" />
    </div>
</template>

<script>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import FadeTransition from '@/components/fade-transition/FadeTransition.vue';
import BasketItem from '@/modules/baskets/basket/basket-items/BasketItem.vue';
import BasketItemSizeAndQuantityGrid from '@/modules/baskets/basket/basket-items/BasketItemSizeAndQuantityGrid.vue';
import { Const } from '@/utils/constants.js';
import { localisedDateFormat } from '@/utils/generic.js';

export default {
    name: 'MiniBasketItemsByDate',

    components: {
        BasketItemSizeAndQuantityGrid,
        FadeTransition,
        BasketItem,
        ButtonPlain,
    },

    props: {
        basketInfo: {
            type: Object,
            required: true,
        },

        date: {
            type: String,
            required: true,
        },

        items: {
            type: Object,
            required: true,
        },

        shouldShowPrice: {
            type: Boolean,
            required: true,
        },
    },

    emits: [
        'removeItemsPerDate',
        'removeItem',
    ],

    data() {
        return {
            expanded: true,
            removeItemsPerDateInProgress: false,
        };
    },

    computed: {
        subsidiary() {
            return this.$store.state.subsidiary;
        },

        shouldShowDiscount() {
            return this.$store.getters.getUserCan('canSeeItemDiscount') && (this.subsidiary.id !== Const.SUBSIDIARIES.UNITED_STATES);
        },
    },

    methods: {
        formatDate(date) {
            return localisedDateFormat(new Date(date), this.$store.getters.userLocale, { dateStyle: 'medium' });
        },

        toggleItemView() {
            this.expanded = !this.expanded;
        },

        removeItemsPerDate(date) {
            this.removeItemsPerDateInProgress = true;

            this.$emit('removeItemsPerDate', date);
        },

        removeItem(date, item) {
            this.$emit('removeItem', date, item);
        },
    },
};
</script>
