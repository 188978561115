import { bugsnag } from '@/libs/bugsnag.js';
import { reactive } from 'vue';

export const useDataLoading = () => {
    const dataLoadingState = reactive({
        loading: false,
    });

    const loadData = async requests => {
        dataLoadingState.loading = true;

        try {
            await Promise.all(requests.map(request => request()));

            dataLoadingState.loading = false;
        } catch (error) {
            bugsnag.notify(error);
        }
    };

    return {
        dataLoadingState,
        loadData,
    };
};
