import { wrapDynamicImport } from '@/router/wrap-dynamic-import.js';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { guards } from '@/router/middleware/middleware-runner.js';
import {
    subsidiaryMiddleware,
    authenticationMiddleware,
    redirectMiddleware,
    authorizationMiddleware,
    currentBasketMiddleware,
    orderJourneyMiddleware,
    pageTitleMiddleware,
} from '@/router/middleware/middlewares.js';
import routes from '@/router/routes/routes.js';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: routes.reduce(wrapDynamicImport, []),
});

const onRouteError = error => {
    const pageRefreshedDueToChunkLoadError = sessionStorage.getItem('ASICS_B2B.page-refreshed') === 'true';

    // New chunk is deployed and previous one is gone on a server but still cached in webpack, so we need to reload the page to get the latest
    if (error.name === 'ChunkLoadError' && !pageRefreshedDueToChunkLoadError) {
        sessionStorage.setItem('ASICS_B2B.page-refreshed', 'true');

        router.go(0);
    }
};

// NOTE: the order in which middlewares are processed is important; `subsidiaryMiddleware` should always run first
router.beforeEach(guards([
    subsidiaryMiddleware,
    authenticationMiddleware,
    authorizationMiddleware,
    redirectMiddleware,
    orderJourneyMiddleware,
    currentBasketMiddleware,
    pageTitleMiddleware,
]));

router.onError(onRouteError);

export default router;
