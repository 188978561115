<template>
    <DropDownMenu>
        <template #title>
            {{ $t('adminMainMenu.settings') }}
        </template>
        <template #dropdown>
            <li>
                <router-link to="/settings/homepage">
                    {{ $t('adminMainMenu.homepage') }}
                </router-link>
            </li>
            <li>
                <router-link to="/settings/slideshow">
                    {{ $t('adminMainMenu.slideshowManager') }}
                </router-link>
            </li>
            <li>
                <router-link to="/settings/translations">
                    {{ $t('adminMainMenu.translations') }}
                </router-link>
            </li>
            <li
                v-if="isDiscountsEnabled"
                class="divider"
            />
            <li v-if="isDiscountsEnabled">
                <router-link to="/settings/tth-management">
                    {{ $t('adminMainMenu.tthManagementMenu') }}
                </router-link>
            </li>
            <li class="divider" />
            <li>
                <router-link to="/settings/product-manager/descriptions">
                    {{ $t('adminMainMenu.productDescriptionManager') }}
                </router-link>
            </li>
            <li>
                <router-link to="/settings/product-manager/collection">
                    {{ $t('adminMainMenu.productCollectionManager') }}
                </router-link>
            </li>
            <li v-if="canManageBetchuDiscounts">
                <router-link to="/settings/pricing-group-discounts">
                    {{ $t('adminCustomers.titleBetchuDiscounts') }}
                </router-link>
            </li>
            <li class="divider" />
            <li v-if="isSeasonManagerEnabled">
                <router-link to="/settings/seasons/blocks/at-once">
                    {{ $t('adminMainMenu.seasonManager') }}
                </router-link>
            </li>
            <li v-if="!preOrderFromTemplateEnabled">
                <router-link to="/settings/preorder-deadlines">
                    {{ $t('adminMainMenu.preorderDeadlines') }}
                </router-link>
            </li>
            <li>
                <router-link to="/settings/limit-management/maximum-availability">
                    {{ $t('adminMainMenu.manageMaximums') }}
                </router-link>
            </li>
            <li v-if="preOrderFromTemplateEnabled">
                <router-link to="/settings/preorder-template/metadata">
                    {{ $t('adminMainMenu.templateMetadata') }}
                </router-link>
            </li>
            <li class="divider" />
            <li>
                <router-link to="/settings/digital-assets">
                    {{ $t('digitalAssets.title') }}
                </router-link>
            </li>
            <li class="divider" />
            <li>
                <router-link to="/settings/basket-failed-reports/recipients">
                    {{ $t('failedBasketNotification.failedBasketRecipients') }}
                </router-link>
            </li>
            <li v-if="isDisputesEnabled">
                <router-link to="/settings/dispute-root-causes">
                    {{ $t('general.disputeReasonsManagement') }}
                </router-link>
            </li>
            <li class="divider" />
            <li>
                <router-link to="/settings/mfa">
                    {{ $t('general.mfa') }}
                </router-link>
            </li>
            <li class="divider" />
            <li class="capitalize">
                <router-link to="/settings/release-notes">
                    {{ $t('releaseNotes.pageHeader') }}
                </router-link>
            </li>
        </template>
    </DropDownMenu>
</template>

<script>
import DropDownMenu from '@/components/menu/DropDownMenu.vue';
import { Const } from '@/utils/constants.js';

export default {
    name: 'SettingsDropdownMenu',

    components: {
        DropDownMenu,
    },

    computed: {
        isDiscountsEnabled() {
            return Boolean(this.$store.state.subsidiary.conditionContracts);
        },

        isSeasonManagerEnabled() {
            return Boolean(this.$store.state.subsidiary.seasonManagerEnabled);
        },

        preOrderFromTemplateEnabled() {
            return Boolean(this.$store.state.subsidiary.offersEnabled);
        },

        isDisputesEnabled() {
            return this.$store.state.subsidiary.disputesEnabled;
        },

        canManageBetchuDiscounts() {
            return Boolean(this.$store.getters.getUserCan('canManageBetchuDiscounts'));
        },

        canManageTrainingMaterial() {
            return Boolean(this.$store.getters.getUserCan(Const.PERMISSION_CAN_MANAGE_BLOG));
        },
    },
};
</script>
