<template>
    <ul class="flex flex-col divide-y divide-grey">
        <NotificationItem
            v-for="notification in infiniteFilteredNotifications"
            :key="notification.id"
            :notification="notification"
            @urlClick="emit('urlClick')"
        />
        <li
            v-if="infiniteFilteredNotifications.length === 0"
            class="flex justify-center py-4"
        >
            <slot name="no-results" />
        </li>
        <li
            v-if="canShowMore"
            class="flex justify-center py-4"
        >
            <slot name="show-all" />
        </li>
    </ul>
</template>

<script setup>
import FuzzySearch from '@/components/search/fuzzy-search.js';
import { useI18n } from '@/composables/use-i18n.js';
import NotificationItem from '@/modules/notifications/partials/NotificationItem.vue';
import { isObject } from '@/utils/collection.js';
import { computed } from 'vue';
import store from '@/store/store.js';

const fuzzySearch = new FuzzySearch();

const props = defineProps({
    searchText: {
        type: String,
        default: '',
    },

    shownItemCount: {
        type: Number,
        default: 100000,
    },

    canShowMore: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits([
    'urlClick',
]);

const { t } = useI18n();

const list = computed(() => parseRawNotifications(store.getters.notifications));

const filteredNotifications = computed(() => {
    const parts = fuzzySearch.splitText(props.searchText);

    return list.value.filter(notification => fuzzyMatch(parts, notification.message));
});

const infiniteFilteredNotifications = computed(() => filteredNotifications.value.slice(0, props.shownItemCount));

const fuzzyMatch = (parts, text) => {
    return parts.reduce((score, part) => {
        const exactMatch = part.regex.test(text);

        if (exactMatch) {
            return score;
        }

        const substrings = text.split(/\W/).filter(substring => substring.length > 2);
        const fuzzyScore = fuzzySearch.anyPartCompare(part.part, substrings);

        return Math.min(score, fuzzyScore);
    }, 1);
};

const parseRawNotifications = notifications => {
    const parsedNotifications = notifications.map(notification => {
        const payload = Array.isArray(notification.payload) ? notification.payload.map(prop => isObject(prop) && prop.translate ? this.$t(prop.value) : prop) : [];

        const mappedNotification = {
            id: notification.id,
            message: t(notification.msg, payload), // TODO: return additional property `translatable: true/false` to indicate when `notification.msg` is a translation string and when already translated
            createdAt: notification.created_at,
            read: Boolean(notification.read),
            comments: props.canShowMore ? null : notification.comments,
        };

        switch (notification.msg) {
            case 'notification.discount_status_pending':
            case 'notification.discount_status_approved':
            case 'notification.discount_status_rejected':
                mappedNotification.iconCss = 'fa-percent text-grey-dark';
                break;
            case 'notification.offer_status_review':
                mappedNotification.iconCss = 'fa-bullseye text-grey-dark';
                break;
            case 'notification.foc_basket_review':
            case 'notification.foc_basket_status_change_approved':
            case 'notification.foc_basket_status_change_rejected':
            case 'importOrder.bellNotificationSuccessMsg':
            case 'importOrder.bellNotificationFailedMsg':
                mappedNotification.iconCss = 'fa-cart-shopping text-grey-dark';
                break;
            case 'notification.shared_catalog_with_user':
                mappedNotification.iconCss = 'fa-file-pdf text-red';
                break;
            case 'notification.shared_xlsx_catalog_with_user':
            case 'notification.shared_pricat_file_with_user':
                mappedNotification.iconCss = 'fa-file-excel text-green';
                break;
            case 'notification.release_notes_published':
                mappedNotification.iconCss = 'fa-note-sticky text-grey-dark';
                break;
            default:
                mappedNotification.iconCss = 'fa-bookmark text-grey-dark';
        }

        return mappedNotification;
    });

    return parsedNotifications.sort((a, b) => a.createdAt - b.createdAt);
};

</script>
