<template>
    <footer class="flex flex-col items-center bg-secondary-lightest text-primary p-8 print:hidden">
        <div class="flex flex-col items-center justify-between md:grid md:grid-flow-col md:gap-x-20 md:items-start w-full mx-auto 2xl:container">
            <div class="flex flex-col items-center md:items-start">
                <p class="font-bold mb-2">
                    <span>© {{ state.currentYear }} ASICS Global</span>
                </p>
                <LanguageSelector
                    v-if="showLanguageSelector"
                    :user-locale="userLocale"
                    :subsidiary-locales="subsidiaryLocales"
                />
            </div>

            <FooterMenu />

            <p class="text-sm text-center md:text-right px-8 md:px-0 w-full md:max-w-3/12">
                {{ $t('footerMenu.copyright') }}
            </p>
        </div>
    </footer>
</template>

<script setup>
import LanguageSelector from '@/components/footer-menu/common/LanguageSelector.vue';
import FooterMenu from '@/components/footer-menu/FooterMenu.vue';
import { computed, reactive } from 'vue';
import store from '@/store/store.js';

const state = reactive({
    currentYear: new Date().getFullYear(),
});

const showLanguageSelector = computed(() => Object.keys(store.getters['globalStore/subsidiary'].locales.length > 1));
const userLocale = computed(() => store.getters.userLocale);
const subsidiaryLocales = computed(() => store.state.subsidiary.locales);

</script>
