import actions from '@/store/actions.js';

// Default store dependencies
import getters from '@/store/getters.js';

// Store modules
import customerLogo from '@/store/modules/customer-logo/customer-logo.store.js';
import globalStore from '@/store/modules/global/global.store.js';
import mutations from '@/store/mutations.js';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state() {
        return {
            user: null,
            subsidiary: null,
            preferences: null,
            customer: null,
            legal: null,
            customization: null,
            customerDeliveryAddresses: {},
            customerDistributionCenters: {},
            basket: null,
            customizedBasket: null,
            offer: null,
            plp: {
                filterState: [],
                sort: '',
                search: '',
                orderJourney: '',
                scrollTop: 0,
            },
            notifications: [],
            isLoggedOut: false,
            breadcrumbs: [],
            orderGridClipboard: null,
            apiErrors: {},
            isMobileViewport: false,
            availableOrderJourneys: [],
            toastNotifications: [],
            atOnceProductFilters: null,
            preOrderProductFilters: null,
            isProductionSap: false,
        };
    },

    getters,

    actions,

    mutations,

    modules: {
        globalStore,
        customerLogo,
    },

    strict: import.meta.env.PROD,
});
