<template>
    <div class="hidden md:flex items-center w-full h-12 mx-auto 2xl:container">
        <Breadcrumbs :items="breadcrumbs" />
    </div>
</template>

<script setup>
import Breadcrumbs from '@/components/context-menu/Breadcrumbs.vue';
import { computed } from 'vue';
import store from '@/store/store.js';

const breadcrumbs = computed(() => store.state.breadcrumbs);

</script>
