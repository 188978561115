<template>
    <div class="w-full min-w-[10rem] mx-1">
        <label
            v-if="label"
            :for="id"
            class="capitalize mr-2 text-primary font-bold inline-block"
        >
            <span>{{ label }}</span>
        </label>
        <div class="relative">
            <input
                :id="id"
                v-model.trim="displayValue"
                type="search"
                :placeholder="placeholder || $t('general.labelSearch')"
                class="text-primary leading-[2.3rem] py-3 pl-6 pr-14 placeholder:text-primary focus:placeholder:text-grey border border-grey focus:border-secondary transition rounded-full h-[3.8rem] w-full"
                @keyup.stop=""
            >
            <i class="absolute top-4 right-6 text-xl fa fa-search" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'SearchInput',

    props: {
        id: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            default: null,
        },

        value: {
            type: [String, Number],
            default: null,
        },

        placeholder: {
            type: String,
            default: null,
        },
    },

    emits: [
        'input',
        'change',
    ],

    computed: {
        displayValue: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);

                if (this.value !== value) {
                    this.$emit('change', value);
                }
            },
        },
    },
};
</script>
