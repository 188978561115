<template>
    <div class="grid grid-cols-1 md:grid-cols-4 md:gap-12 text-primary md:px-[5rem] md:pb-8">
        <div class="flex flex-col gap-4">
            <div
                v-for="{key, subsidiaries} in leftColumn"
                :key="key"
            >
                <h2 class="heading-md pb-1 border-b border-secondary !normal-case">
                    {{ $t(`general.subsidiaryRegion.${key}`) }}
                </h2>
                <RegionSubsidiaries
                    :subsidiaries="subsidiaries"
                    :stored-subsidiary="storedSubsidiary"
                    @select="emit('select', $event)"
                />
            </div>
        </div>
        <div class="flex flex-col gap-4 md:col-span-2">
            <div
                v-for="{key, subsidiaries} in middleColumn"
                :key="key"
            >
                <h2 class="heading-md pb-1 border-b border-secondary !normal-case">
                    {{ $t(`general.subsidiaryRegion.${key}`) }}
                </h2>
                <RegionSubsidiaries
                    :subsidiaries="subsidiaries"
                    :stored-subsidiary="storedSubsidiary"
                    class="md:gap-x-12 md:columns-2"
                    @select="emit('select', $event)"
                />
            </div>
        </div>
        <div class="flex flex-col gap-4">
            <div
                v-for="{key, subsidiaries} in rightColumn"
                :key="key"
            >
                <h2 class="heading-md pb-1 border-b border-secondary !normal-case">
                    {{ $t(`general.subsidiaryRegion.${key}`) }}
                </h2>
                <RegionSubsidiaries
                    :subsidiaries="subsidiaries"
                    :stored-subsidiary="storedSubsidiary"
                    @select="emit('select', $event)"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import RegionSubsidiaries from '@/modules/unauthenticated/partials/RegionSubsidiaries.vue';
import { computed } from 'vue';

const props = defineProps({
    regions: {
        type: Array,
        required: true,
    },

    storedSubsidiary: {
        type: Object,
        default: null,
    },
});

const emit = defineEmits([
    'select',
]);

const leftColumn = computed(() => props.regions.filter(({ key }) => ['americas', 'africa', 'middleEast'].includes(key)));
const middleColumn = computed(() => props.regions.filter(({ key }) => key === 'europe'));
const rightColumn = computed(() => props.regions.filter(({ key }) => key === 'asia'));

</script>
