import VueGtm from '@gtm-support/vue2-gtm';
import Vue from 'vue';

const isProd = import.meta.env.PROD;

export function setupGtm(id, nonce) {
    if (!id) {
        Vue.prototype.$gtm = {
            trackEvent: () => {},
        };

        return;
    }

    Vue.use(VueGtm, {
        id, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        // Below query params are used for previewing GTM changes on staging
        queryParams: {
            gtm_auth: 'Ss_CtVVOgJjXkItH2HZqqw',
            gtm_preview: 'env-6',
            gtm_cookies_win: 'x',
        },
        nonce, // Will add `nonce` to the script tag. Needed for CSP
        defer: true, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        enabled: isProd && window.localStorage.getItem('ASICS_B2B.GA_COOKIE_ACCEPTED') === 'true', // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: false, // Whether to display console logs debugs (optional)
        loadScript: true, // Whether to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    });
}
