<template>
    <div
        class="flex h-full overflow-hidden"
        :class="{'relative flex-col': isMobileViewport}"
    >
        <MobileContextMenu
            v-if="isMobileViewport"
            id="customer-mobile-context-menu"
            :menu-items="enabledMenuItems"
            @toggle="onMobileContextMenuToggle"
        />
        <SidebarMenu
            v-else
            id="customer-sidebar"
            :menu-items="enabledMenuItems"
        />

        <div
            class="flex flex-col relative w-full p-8 overflow-auto"
            :class="{'pt-0': isMobileViewport, 'min-h-[68rem]': isMobileViewport && state.mobileContextMenuExpanded}"
        >
            <router-view
                :key="$route.path"
                :customer-no="customerNo"
                parent-path="/orders-overview"
            />
        </div>
    </div>
</template>

<script setup>
import MobileContextMenu from '@/components/layout/MobileContextMenu.vue';
import SidebarMenu from '@/components/layout/SidebarMenu.vue';
import store from '@/store/store.js';
import { Const } from '@/utils/constants';
import { reactive, computed } from 'vue';

const customerNo = store.getters.getRealCustomerNo;
const subsidiary = store.state.subsidiary;
const state = reactive({
    menuItems: [
        {
            title: 'customerOrderHistory.title',
            icon: 'fa fa-history',
            path: '/orders-overview/order-history',
            enabled: () => true,
        },
        {
            title: 'adminCustomers.titleOfferHistory',
            icon: 'fa fa-shopping-cart',
            path: '/orders-overview/pre-orders',
            enabled: () => subsidiary.offersEnabled && isCustomer.value && store.getters.getUserCan(Const.PERMISSION_CAN_USE_OFFERS),
        },
        {
            title: 'customerOrderHistory.deliveryNotes',
            icon: 'fa fa-truck',
            path: '/orders-overview/delivery-notes',
            enabled: () => isCustomer.value && store.getters.isAEGRegion,
        },
        {
            title: 'creditInformation.title',
            icon: 'fa fa-file-text',
            path: '/orders-overview/credit-information',
            enabled: () => isCustomer.value && store.getters.getUserCan(Const.PERMISSIONS.CAN_VIEW_CREDIT_DOCUMENTS),
        },
        {
            title: 'general.disputes',
            icon: 'fa fa-comments',
            path: '/orders-overview/disputes',
            enabled: () => isCustomer.value && store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_DISPUTES),
        },
        {
            title: 'customerOrderHistory.disputesText',
            icon: 'fa fa-comments',
            path: '/orders-overview/disputes-old',
            enabled: () => isCustomer.value && subsidiary.oldDisputesEnabled,
        },
    ],
    mobileContextMenuExpanded: false,
});

const enabledMenuItems = computed(() => state.menuItems.filter(item => item.enabled()));
const isMobileViewport = computed(() => store.getters.isMobileViewport);
const isCustomer = computed(() => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER);

const onMobileContextMenuToggle = expanded => {
    state.mobileContextMenuExpanded = expanded;
};

</script>
