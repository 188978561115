<template>
    <div class="flex flex-col gap-16 p-8">
        <DashboardBaskets :customer-no="customerNo" />
        <DashboardQuickActions />
        <DashboardSlideshow />
    </div>
</template>

<script>
import DashboardBaskets from '@/modules/dashboard/DashboardBaskets.vue';
import DashboardQuickActions from '@/modules/dashboard/DashboardQuickActions.vue';
import DashboardSlideshow from '@/modules/dashboard/DashboardSlideshow.vue';

export default {
    name: 'Dashboard',

    components: {
        DashboardSlideshow,
        DashboardQuickActions,
        DashboardBaskets,
    },

    computed: {
        customerNo() {
            return this.$store.getters.getRealCustomerNo;
        },
    },
};
</script>
