<template>
    <div
        ref="actionButtons"
        class="relative ml-auto h-full"
    >
        <ButtonPlain
            kind="white"
            class="h-full ml-auto px-4 -mr-4"
            @click.native="toggleActionButtonVisibility"
        >
            <i class="fa fa-ellipsis-vertical fa-xl" />
        </ButtonPlain>
        <FadeTransition>
            <ul
                v-show="state.actionButtonsVisible"
                class="absolute top-[2.5rem] right-0 w-max flex flex-col justify-start divide-y divide-grey-light bg-white z-10 rounded-md shadow-md"
            >
                <template v-if="preOrderFromTemplateEnabled && basket.isPreOrderFromTemplate">
                    <li class="py-4 px-6">
                        <span @click.stop="onNavigation">
                            <router-link
                                :to="isCustomer ? '/orders-overview/pre-orders/pre-orders-from-template' : `/customers/${basket.customerNo}/pre-orders/pre-orders-from-template`"
                                class="flex items-center gap-4 capitalize"
                            >
                                <i class="fa fa-cart-shopping" />
                                <span>{{ $t('baskets.changeBasket') }}</span>
                            </router-link>
                        </span>
                    </li>
                    <li class="py-4 px-6">
                        <span @click.stop="onNavigation">
                            <router-link
                                :to="isCustomer ? '/orders-overview/pre-orders/pre-order-from-template-summary' : `/customers/${basket.customerNo}/pre-orders/pre-order-from-template-summary`"
                                class="flex items-center gap-4 capitalize"
                            >
                                <i class="fa fa-cart-shopping" />
                                <span>{{ $t('preorder.preorderSummary') }}</span>
                            </router-link>
                        </span>
                    </li>
                </template>
                <template v-else>
                    <li
                        v-if="canCopyBasket"
                        class="py-4 px-6"
                    >
                        <ButtonPlain
                            :text="$t('baskets.copyBasket')"
                            class="!font-normal gap-4"
                            @click.native="copyBasket"
                        >
                            <i class="fa fa-copy" />
                        </ButtonPlain>
                    </li>
                    <li
                        v-if="!customerIsStore"
                        class="py-4 px-6"
                    >
                        <span @click.stop="onNavigation">
                            <router-link
                                :to="`/customers/${basket.customerNo}/orders/new?type=salesOrder`"
                                class="flex items-center gap-4 capitalize"
                            >
                                <i class="fa fa-plus" />
                                <span>{{ $t('baskets.createNewBasket') }}</span>
                            </router-link>
                        </span>
                    </li>
                    <li class="py-4 px-6">
                        <span @click.stop="onNavigation">
                            <router-link
                                to="/pending-baskets"
                                class="flex items-center gap-4 capitalize"
                            >
                                <i class="fa fa-cart-shopping" />
                                <span>{{ $t('baskets.viewAllBaskets') }}</span>
                            </router-link>
                        </span>
                    </li>
                    <li
                        v-if="!basketIsEmpty"
                        class="py-4 px-6"
                    >
                        <ButtonPlain
                            kind="danger"
                            :text="$t('baskets.clearBasket')"
                            class="!font-normal gap-4"
                            @click.native="showConfirmationModal"
                        >
                            <i class="fa fa-trash" />
                        </ButtonPlain>
                    </li>
                </template>
            </ul>
        </FadeTransition>

        <ConfirmationDialog
            v-if="state.confirmationModalVisible"
            @cancel="hideConfirmationModal"
            @confirm="removeItemsFromBasket"
        />
    </div>
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import FadeTransition from '@/components/fade-transition/FadeTransition.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { removeAllItemsFromBasket } from '@/modules/baskets/baskets.api.js';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { computed, onBeforeUnmount, onMounted, reactive, ref } from 'vue';

const props = defineProps({
    basket: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits([
    'showCopyBasketModal',
]);

const state = reactive({
    showBasketCopyModal: false,
    confirmationModalVisible: false,
    actionButtonsVisible: false,
});

const preOrderFromTemplateEnabled = computed(() => store.getters.getSubsidiarySettings.offersEnabled);
const canCopyBasket = computed(() => !basketIsEmpty.value && props.basket?.foc_status !== Const.FOC_STATUS.REVIEW);
const isCustomer = computed(() => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER);
const customerIsStore = computed(() => store.getters.customerIsStore);
const basketIsEmpty = computed(() => props.basket.totalRequestedQuantity === 0);
const actionButtons = ref(null);

const toggleActionButtonVisibility = () => {
    state.actionButtonsVisible = !state.actionButtonsVisible;
};

const hideActionButtons = () => {
    state.actionButtonsVisible = false;
};

const showConfirmationModal = () => {
    state.confirmationModalVisible = true;
};

const hideConfirmationModal = () => {
    state.confirmationModalVisible = false;

    hideActionButtons();
};

const copyBasket = () => {
    hideActionButtons();
    emit('showCopyBasketModal');
};

const onNavigation = () => {
    hideActionButtons();
};

const handleClickOutside = event => {
    if (!state.actionButtonsVisible || actionButtons.value.contains(event.target)) {
        return;
    }

    state.actionButtonsVisible = false;
};

const removeItemsFromBasket = async () => {
    try {
        await removeAllItemsFromBasket(props.basket.no);
    } catch (error) {
        bugsnag.notify(error);
    } finally {
        hideConfirmationModal();
    }
};

onMounted(() => {
    document.addEventListener('click', handleClickOutside, {
        passive: true,
    });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
});

</script>
