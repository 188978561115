<template>
    <div :class="[mobile ? 'flex md:hidden' : 'hidden md:flex']">
        <template v-if="mobile">
            <div class="fixed top-0 left-0 right-0 bottom-0 h-[6.5rem] flex items-center justify-between bg-primary text-white p-8">
                <div class="flex items-center">
                    <div class="menu-btn-container !px-0 mr-6">
                        <button class="menu-btn !bg-white text-primary font-bold !p-0 uppercase">
                            {{ userInitials }}
                        </button>
                    </div>
                    <span class="font-bold">
                        {{ username }}
                    </span>
                </div>
                <i
                    class="fa fa-close fa-xl font-normal"
                    @click="closeMenu"
                />
            </div>
            <ul class="menu w-full">
                <template v-for="{link, translationKey} in menuItems">
                    <li
                        :key="link"
                        class="px-8 py-3.5"
                    >
                        <router-link
                            :to="link"
                            class="menu-btn"
                        >
                            {{ $t(translationKey) }}
                        </router-link>
                    </li>
                </template>
                <li class="px-8 py-3.5 border-t border-secondary-lightest">
                    <button
                        class="menu-btn hover:text-secondary"
                        :title="$t('mainMenu.logoutLabel')"
                        @click="toggleShowLogout(true)"
                    >
                        <span class="capitalize">{{ $t('general.buttonLogout') }}</span>
                    </button>
                </li>
            </ul>
        </template>

        <DropDownMenu
            v-else
            :secondary="true"
        >
            <template #secondary-menu-title>
                <div class="menu-btn-container group">
                    <button class="menu-btn uppercase group-hover:!bg-white">
                        {{ userInitials }}
                    </button>
                </div>
            </template>
            <template #dropdown>
                <li class="border-b border-secondary-lightest">
                    <div class="font-bold normal-case mb-4">
                        {{ username }}
                    </div>
                </li>
                <template v-for="{link, translationKey} in menuItems">
                    <li :key="link">
                        <router-link
                            :to="link"
                            class="menu-btn"
                        >
                            {{ $t(translationKey) }}
                        </router-link>
                    </li>
                </template>
                <li class="border-t border-secondary-lightest">
                    <button
                        class="menu-btn hover:text-secondary mt-4"
                        :title="$t('mainMenu.logoutLabel')"
                        @click="toggleShowLogout(true)"
                    >
                        <span class="capitalize">{{ $t('general.buttonLogout') }}</span>
                    </button>
                </li>
            </template>
        </DropDownMenu>

        <LogoutModal
            v-show="state.showLogout"
            @close="toggleShowLogout(false)"
        />
    </div>
</template>

<script setup>
import DropDownMenu from '@/components/menu/DropDownMenu.vue';
import LogoutModal from '@/components/menu/LogoutModal.vue';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { computed, reactive } from 'vue';

/**
 * NOTE: Do not add any data fetching code to this component. Otherwise, 2 HTTP requests will be made due to the component being initialised twice, for desktop and mobile
 */

defineProps({
    mobile: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits([
    'closeMenu',
]);

const isCustomer = computed(() => store.getters.getUserType === Const.ACCOUNT_TYPE_CUSTOMER);
const username = computed(() => store.getters.username);
const userInitials = computed(() => username.value.charAt(0));
const menuItems = computed(() => [
    {
        link: '/account',
        translationKey: 'general.account',
        visible: () => true,
    },
    {
        link: '/orders-overview/order-history',
        translationKey: 'general.orders',
        visible: () => isCustomer.value,
    },
    {
        link: '/pending-baskets',
        translationKey: 'general.baskets',
        visible: () => isCustomer.value,
    },
    {
        link: '/orders-overview/delivery-notes',
        translationKey: 'customerMainMenu.deliveryNotes',
        visible: () => isCustomer.value && store.getters.isAEGRegion,
    },
    {
        link: '/orders-overview/credit-information',
        translationKey: 'customerMainMenu.creditInfo',
        visible: () => isCustomer.value && store.getters.getUserCan(Const.PERMISSIONS.CAN_VIEW_CREDIT_DOCUMENTS),
    },
    {
        link: '/orders-overview/disputes',
        translationKey: 'customerMainMenu.disputes',
        visible: () => isCustomer.value && store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_DISPUTES),
    },
    {
        link: '/orders-overview/disputes-old',
        translationKey: 'customerMainMenu.disputes',
        visible: () => isCustomer.value && store.getters.getSubsidiarySettings.oldDisputesEnabled,
    },
    {
        link: '/selective-distribution-policy',
        translationKey: 'general.selectiveDistributionPolicy',
        visible: () => store.state.subsidiary.sdpManagementEnabled && store.state.subsidiary.sdpScoringStrategy === Const.SDP_SCORING_STRATEGY.QUESTIONNAIRE,
    },
    {
        link: '/training-material',
        translationKey: 'trainingMaterial.title',
        visible: () => store.getters.hasTrainingMaterial,
    },
].filter(({ visible }) => visible()));

const state = reactive({
    showLogout: false,
});

const toggleShowLogout = value => {
    state.showLogout = value;
};

const closeMenu = () => {
    emit('closeMenu');
};

</script>
