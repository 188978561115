<template>
    <div
        class="option-circle"
        :class="{checked}"
        @click.stop="circleClicked"
    >
        <svg
            id="circle-layer"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 164 164"
            xml:space="preserve"
        >
            <circle
                class="circle-fill"
                cx="82.5"
                cy="82.5"
                r="78"
            />
            <circle
                class="circle-hover"
                cx="82.5"
                cy="82.5"
                r="72.6"
            />
            <circle
                class="circle-outer"
                cx="82.5"
                cy="82.5"
                r="78.3"
            />
            <path
                v-if="checked"
                class="checkmark"
                d="M40.1,77.3v5.2l32.7,31.7h5l47.5-50v-5.3l-7-6.8h-4.7L75.9,91h-2L52.7,72l-6.3-0.3C40.1,77.3,40.1,77.3,40.1,77.3z"
            />
        </svg>
    </div>
</template>

<script>
export default {
    name: 'OptionCircle',
    props: {
        checked: {
            type: Boolean,
            required: true,
        },
    },
    methods: {
        circleClicked() {
            this.$emit('circleClicked', this.checked);
        },
    },
};
</script>

<style lang="scss" scoped>
@use '~@variables' as variables;

svg {
    width: 22.5px;

    .circle-fill {
        fill: #FFFFFF;
        stroke: none;
        stroke-width: 0;
        stroke-miterlimit: 10;
        cursor: pointer;
    }

    .circle-hover {
        fill: none;
        stroke: none;
        stroke-width: 6;
        stroke-miterlimit: 10;
        cursor: pointer;
    }

    .circle-outer {
        fill: none;
        stroke: #D3CFC8;
        stroke-width: 6;
        stroke-miterlimit: 10;
        cursor: pointer;
    }

    .checkmark {
        cursor: pointer;
        fill: variables.$asics-white;
    }

    &:hover {
        .circle-outer {
            stroke: #9CC2CB;
        }

        .circle-hover {
            stroke: #9CC2CB;
        }
    }
}

.option-circle {
    display: block;
    text-align: center;
    margin-top: 6px;

    &.checked svg .circle-fill {
        fill: variables.$asics-blue;
    }
}
</style>
