import { createTransformingApiInstance } from '@/api/api.js';

const transformingApi = createTransformingApiInstance();

export function getSettings() {
    return transformingApi.get('settings/mfa');
}

export function updateSettings(payload) {
    return transformingApi.post('settings/mfa', payload);
}

