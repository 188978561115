import store from '@/store/store.js';
import { localisedDateFormat } from '@/utils/generic.js';
import { extend, localize, setInteractionMode, ValidationObserver, ValidationProvider } from 'vee-validate';
import cs from 'vee-validate/dist/locale/cs.json';
import de from 'vee-validate/dist/locale/de.json';
import en from 'vee-validate/dist/locale/en.json';
import es from 'vee-validate/dist/locale/es.json';
import fr from 'vee-validate/dist/locale/fr.json';
import hu from 'vee-validate/dist/locale/hu.json';
import it from 'vee-validate/dist/locale/it.json';
import ja from 'vee-validate/dist/locale/ja.json';
import nl from 'vee-validate/dist/locale/nl.json';
import pl from 'vee-validate/dist/locale/pl.json';
import br from 'vee-validate/dist/locale/pt_BR.json';
import pt from 'vee-validate/dist/locale/pt_PT.json';
import ru from 'vee-validate/dist/locale/ru.json';
import sk from 'vee-validate/dist/locale/sk.json';
import { confirmed, digits, email, length, max, min, min_value, numeric, regex, required, required_if } from 'vee-validate/dist/rules';
import Vue from 'vue';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

const localeMap = {
    'es-ES': es,
    'fr-FR': fr,
    'it-IT': it,
    'pt-PT': pt,
    'pt-BR': br,
    'cs-CS': cs,
    'pl-PL': pl,
    'sk-SK': sk,
    'nl-NL': nl,
    'ru-RU': ru,
    'de-DE': de,
    'en-US': en,
    'en-GB': en,
    'en-AU': en,
    'en-JP': en,
    'en-CA': en,
    'hu-HU': hu,
    'ja-JP': ja,
    'en-LA': en,
    'es-LA': es,
    'es-CO': es,
    'es-CL': es,
};

const formatDate = dateString => localisedDateFormat(new Date(dateString), store.getters.userLocale, { year: 'numeric', month: 'numeric', day: 'numeric' });

export function setVeeValidateLocale(locale) {
    const [languageCode] = locale.split('-');

    localize(locale, localeMap[locale] ?? localeMap[`${languageCode}-${languageCode.toUpperCase()}`] ?? en);
}

export function setupVeeValidate() {
    extend('required', required);
    extend('required_if', required_if);
    extend('numeric', numeric);
    extend('email', email);
    extend('min', min);
    extend('max', max);
    extend('regex', regex);
    extend('length', length);
    extend('digits', digits);
    extend('min_value', min_value);
    extend('confirmed', confirmed);

    extend('required_without', {
        params: [
            {
                name: 'target',
                isTarget: true,
            },
            {
                name: 't',
            },
        ],
        validate(value, { target }) {
            const valueIsEmpty = ['', false, null].includes(value);
            const targetIsEmpty = ['', false, null].includes(target);

            return {
                required: targetIsEmpty,
                valid: !targetIsEmpty || !valueIsEmpty,
            };
        },
        computesRequired: true,
        message: (_, { t, target }) => t('validationErrorMessage.requiredWithout', { target }),
    });

    extend('date_between', {
        params: ['min', 'max', 't'],
        validate: (value, { min, max }) => value >= min && value <= max,
        message: (_, { t, min, max }) => t('validationErrorMessage.dateBetween', { min: formatDate(min), max: formatDate(max) }),
    });

    // It first validates when the user leaves the text-input (on blur or change) then if the text-input is invalid it will behave aggressively until the text-input is valid again and it will go back to being lazy.
    setInteractionMode('eager');
}
