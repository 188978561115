<template>
    <ModalDialog
        :closable="false"
        size="small"
    >
        <template #header-title>
            {{ $t('mfa.setupMfa') }}
        </template>

        <template #body>
            <ValidationObserver
                v-slot="{ invalid }"
                tag="form"
                autocomplete="off"
                novalidate
                class="flex flex-col gap-4"
                @submit.prevent="submit"
            >
                <div
                    v-if="mfaPopupContent"
                    v-html="mfaPopupContent"
                />
                <p class="leading-snug">
                    {{ $t('mfa.setupMfaDescription') }}
                </p>
                <TextInput
                    id="mfa-email"
                    v-model="state.email"
                    :label="$t('mfa.mfaEmail')"
                    :rules="{required: true, email: true, max:255}"
                    :autofocus="true"
                />
                <div class="action-buttons">
                    <Button
                        :disabled="invalid"
                        :text="$t('userMfaEmail.buttonAccept')"
                        type="submit"
                    />
                </div>
            </ValidationObserver>
        </template>
    </ModalDialog>
</template>

<script setup>
import Button from '@/components/button/Button.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import TextInput from '@/components/text-input/TextInput.vue';
import { reactive } from 'vue';

defineProps({
    mfaPopupContent: {
        type: String,
        default: null,
    },
});

const emit = defineEmits([
    'emailAdded',
]);

const state = reactive({
    email: null,
});

const submit = () => {
    emit('emailAdded', state.email);
};

</script>
