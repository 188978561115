<template>
    <ValidationProvider
        v-slot="{ errors, required, reset }"
        ref="observer"
        tag="div"
        :vid="vid"
        :name="label || String(id)"
        :rules="rules"
        :class="[inline ? 'flex flex-none items-center' : 'block', $attrs.class]"
    >
        <label
            v-if="label"
            :for="id"
            class="flex items-center capitalize text-primary w-full"
            :class="[inline ? 'mr-2 !mb-0' : '!mb-1', rounded ? 'font-normal' : 'font-bold', {'text-red-dark': errors.length || $slots.errors}]"
        >
            {{ label }}
        </label>
        <div
            class="flex flex-none items-center h-[3.8rem] border bg-white focus-within:border-secondary transition"
            :class="[
                inline ? 'overflow-hidden' : 'w-full',
                rounded ? 'rounded-full' : 'rounded-[0.2rem]',
                errors.length || $slots.errors ? 'border-red text-red-dark' : 'border-grey text-primary',
            ]"
        >
            <select
                :id="id"
                v-model="selected"
                class="py-2 px-4 w-full h-full"
                :disabled="disabled"
                :required="required"
                @focusin="reset"
                @change="onChange"
            >
                <option
                    v-for="option in options"
                    :key="option.value"
                    :value="option.value"
                    :disabled="option.disabled"
                >
                    {{ option.translate ? $t(option.text) : option.text }}
                </option>
            </select>
            <span
                v-if="$slots.suffix"
                class="py-4 pr-4"
            >
                <slot name="suffix" />
            </span>
        </div>
        <span class="text-red-dark text-sm italic inline-block mt-1 leading-snug print:hidden">
            <slot
                v-show="errors.length || $slots.errors"
                name="errors"
            >
                {{ errors[0] }}
            </slot>
        </span>
    </ValidationProvider>
</template>

<script>
export default {
    name: 'DropDownSelect',

    inheritAttrs: false,

    props: {
        options: {
            type: Array,
            default() {
                return [];
            },
        },

        value: {
            type: [String, Number, Boolean],
            default: null,
        },

        id: {
            type: [String, Number],
            default() {
                return Date.now();
            },
        },

        vid: {
            type: String,
            default: null,
        },

        label: {
            type: String,
            default: null,
        },

        inline: {
            type: Boolean,
            default: false,
        },

        rounded: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        rules: {
            type: [Object, String],
            default: null,
        },
    },

    emits: [
        'input',
        'change',
    ],

    computed: {
        selected: {
            get() {
                return this.value;
            },

            set(value) {
                this.$emit('input', value);
            },
        },
    },

    methods: {
        onChange(event) {
            this.$emit('change', event.target.value);
        },
    },
};
</script>
