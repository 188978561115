import { Const } from '@/utils/constants.js';
import { deburr } from 'lodash';

export function randomPassword() {
    const password = [];
    const numAmt = Math.round(Math.random() + 1);

    for (let chars = 0; chars < 8 - numAmt; chars++) {
        password.push('ABCDEFGHJKLMNPQRSTUVWXYZ'[Math.floor((Math.random() * 23))]);
    }

    for (let nums = 0; nums < numAmt; nums++) {
        password.push('123456789'[Math.floor((Math.random() * 8))]);
    }

    return password.sort(() => .5 - Math.random()).join('');
}

export function randomString() {
    return Math.random().toString(36).slice(2, 10);
}

export function saveFile(file, fileName, openInNewTab = false) {
    const blob = (file instanceof Blob ) ? file : new Blob([file]);
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = url;

    if (openInNewTab) {
        link.setAttribute('target', '_blank');
    } else {
        link.setAttribute('download', fileName);
    }

    link.click();
    URL.revokeObjectURL(url);
}

export function toCamelCase(string) {
    return string.split('_')
        .map((word, index) => index === 0 ? word : `${word.charAt(0).toUpperCase()}${word.slice(1)}`)
        .join('');
}

export function toSnakeCase(string) {
    const splitString = string
        .replace(/([a-z])([A-Z]+)/g, (m, s1, s2) => `${s1} ${s2}`); // split between a lowercase and one or more uppercase characters

    return splitString
        .split(/ /) // split on spaces and non-word characters (read: underscores) followed by an uppercase character
        .map(word => word.toLowerCase())
        .join('_');
}

export function kebabToSnakeCase(string) {
    return string.toLowerCase().replace(/-/g, '_');
}

export function snakeToKebabCase(string) {
    return string.toLowerCase().replace(/_/g, '-');
}

export function kebabToCamelCase(string) {
    return toCamelCase(kebabToSnakeCase(string));
}

export function camelToKebabCase(string) {
    return snakeToKebabCase(toSnakeCase(string));
}

/**
 * Converts a string to sentence case where only the first character is capitalized.
 * Not to confuse with "capitalize" where the first character in every word in a string is capitalized and can be done with CSS `text-transform: capitalize`.
 * @param {string} value
 * @returns {string}
 */
export function toSentenceCase(value) {
    if (!value) {
        return '';
    }

    const stringValue = String(value).replace(/([A-Z])/g, ' $1');

    return stringValue.charAt(0).toUpperCase() + stringValue.substring(1);
}

export function nl2br(str) {
    return String(str).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br />$2');
}

/**
 * Returns currency symbol for a given locale and currency code
 * @param {string} locale
 * @param {string} currency
 * @return {string}
 */
export function getCurrencySymbol(locale, currency) {
    return new Intl.NumberFormat(locale, { style: 'currency', currency }).formatToParts().find(({ type }) => type === 'currency').value;
}

/**
 * Formats number in provided locale
 * @param {number} number
 * @param {string} languageTag
 * @param {Object} [options]
 * @returns {string}
 */
export function localisedNumberFormat(number, languageTag = 'en-US', options = {}) {
    if (number.constructor.name !== 'Number') {
        throw new TypeError(`Provided '${number}' is not of JavaScript Number type`);
    }

    const normalisedLocaleTag = languageTag.includes('_') ? languageTag.replace('_', '-') : languageTag; // TODO: remove once back-end starts using standards compliant locales

    return new Intl.NumberFormat(normalisedLocaleTag, options).format(number);
}

/**
 * Creates a locale aware date string
 * @param {Date} date
 * @param {string} languageTag
 * @param {Object} [options]
 * @return {string}
 */
export function localisedDateFormat(date, languageTag = 'en-US', options = {}) {
    if (date.constructor.name !== 'Date') {
        throw new TypeError(`Provided '${date}' is not of JavaScript Date type`);
    }

    if (!options.timeZone) {
        options.timeZone = 'UTC';
    }

    return date.toLocaleDateString(languageTag, options);
}

/**
 * Creates a locale aware date-time string
 * @param {Date} date
 * @param {string} languageTag
 * @param {Object} [options]
 * @return {*}
 */
export function localisedDateTimeFormat(date, languageTag = 'en-US', options = {}) {
    if (date.constructor.name !== 'Date') {
        console.warn('Provided `date` is not a JavaScript Date object');

        return date;
    }

    return date.toLocaleString(languageTag, options);
}

/**
 * Formats numbers as currency in a locale aware format
 * @param {string|number} value
 * @param {string} localeTag
 * @param {string} currency
 * @return {string|*}
 */
export function localisedCurrencyFormat(value, localeTag = 'en-US', currency = 'USD') {
    const number = Number(value);
    
    if (Number.isNaN(number)) {
        return value;
    }

    let normalisedLocaleTag;

    switch (currency) {
        case 'COP':
            normalisedLocaleTag = 'es-CO';
            break;
        case 'CLP':
            normalisedLocaleTag = 'es-CL';
            break;
        default:
            normalisedLocaleTag = localeTag.includes('_') ? localeTag.replace('_', '-') : localeTag; // TODO: remove once back-end starts using standards compliant locales
    }

    return number.toLocaleString(normalisedLocaleTag, {
        style: 'currency',
        currency,
    });
}

/**
 * Converts JS date to date string compatible with database format
 * fr-CA is used only because it returns the same format as databases: yyyy-mm-dd
 * @param {Date} date
 * @param {string|null} timeZone
 * @returns {string}
 */
export function toDatabaseDateFormat(date, timeZone = null) {
    return localisedDateFormat(date, 'fr-CA', { timeZone });
}

/**
 * Returns today's date as a string in a given timezone
 * Example today(this.$store.state.subsidiary.timezone)
 * @param {string} timeZone
 * @return {string}
 */
export function today(timeZone) {
    return toDatabaseDateFormat(new Date(), timeZone);
}

/**
 * Adds a number of days to a given date and returns a new date
 * @param {Date|string} date
 * @param {number} number
 * @returns {Date}
 */
export function addDays(date, number) {
    const dateCopy = new Date(date);

    dateCopy.setDate(date.getDate() + number);

    return dateCopy;
}

/**
 * Removes a number of days from a given date and returns a new date
 * @param {Date} date
 * @param {number} number
 * @returns {Date}
 */
export function subtractDays(date, number) {
    const dateCopy = new Date(date);

    dateCopy.setDate(date.getDate() - number);

    return dateCopy;
}

/**
 * Adds a number of months to a given date and returns a new date
 * @param {Date} date
 * @param {number} number
 * @returns {Date}
 */
export function addMonths(date, number) {
    const dateCopy = new Date(date);

    dateCopy.setMonth(date.getMonth() + number);

    return dateCopy;
}

/**
 * Subtracts a number of months from a given date and returns a new date
 * @param {Date} date
 * @param {number} number
 * @returns {Date}
 */
export function subtractMonths(date, number) {
    const dateCopy = new Date(date);

    dateCopy.setMonth(date.getMonth() - number);

    return dateCopy;
}

/**
 * Returns date in SQL format. This is a legacy format and should really be deprecated on the backend
 * Example toSqlDateFormat('2022-10-12') => '20221012'
 * @param {string} dateString
 * @return {string}
 */
export function toSqlDateFormat(dateString) {
    const { year, month, day } = toDateParts(dateString);

    return `${year}${String(month + 1).padStart(2, '0')}${String(day).padStart(2, '0')}`;
}

/**
 * Converts yyyymmdd date to yyyy-mm-dd format. This is the reverse of `toSqlDateFormat()`
 * @param {string} dateString
 * @return {string}
 */
export function fromSqlDateFormat(dateString) {
    const { year, month, day } = toDateParts(dateString);

    return `${year}-${String(month + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
}

/**
 * Creates an object with year, month and date from dateString which can be used when creating a `new Date(year, month, day)`
 * `dateString` can be in 2 possible formats: 2022-02-05 or 20220205. The latter should actually be fixed on the backend but oh well...
 * @param {string} dateString
 * @returns {{month: number, year: number, day: number}}
 */
export function toDateParts(dateString) {
    const normalisedDateString = dateString.replaceAll('-', '');
    const year = Number(normalisedDateString.substring(0, 4));
    const month = Number(normalisedDateString.substring(4, 6)) - 1;
    const day = Number(normalisedDateString.substring(6));

    return {
        year,
        month,
        day,
    };
}

/**
 * Formats given date in relative time, as in "yesterday", "2 days ago", "in 3 hours".
 * @param {Date} date
 * @param {string} languageTag
 * @param {Object} options
 * @param {boolean=false} inFuture
 * @returns {string}
 */
export function toRelativeDateFormat(date, languageTag = 'en-US', options, inFuture = false) {
    const now = new Date(localisedDateTimeFormat(new Date(), 'en-US', options)).getTime();
    const diff = Math.abs(date.getTime() - now);
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const relativeTimeFormat = new Intl.RelativeTimeFormat(languageTag, { numeric: 'auto' });
    const getTimeValue = timeUnit => inFuture ? Math.floor(diff / timeUnit) : -Math.floor(diff / timeUnit);

    switch (true) {
        case (diff < minute):
            return relativeTimeFormat.format(getTimeValue(second), 'second');
        case (diff < hour):
            return relativeTimeFormat.format(getTimeValue(minute), 'minute');
        case (diff < day):
            return relativeTimeFormat.format(getTimeValue(hour), 'hour');
        default:
            return localisedDateTimeFormat(date, languageTag, options);
    }
}

export function debounce(func, wait, immediate) {
    let timeout;

    return (...args) => {
        const callback = func.bind(null, ...args);
        const later = () => {
            timeout = null;

            if (!immediate) {
                callback();
            }
        };

        const callNow = immediate && !timeout;

        clearTimeout(timeout);

        timeout = setTimeout(later, wait);

        if (callNow) {
            callback();
        }
    };
}

/**
 * Converts size in bytes to a human-readable size unit
 * @param {Number} bytes
 * @param {Number} fractionalDigits
 * @returns {string}
 */
export function bytesToSize(bytes, fractionalDigits = 2) {
    if (bytes === 0) {
        return String(bytes);
    }

    return (bytes / Math.pow(1024, 2)).toFixed(fractionalDigits);
}

/**
 * Returns display name for a given season and subsidiary
 * @param {string} seasonName
 * @param {Object} subsidiary
 * @returns {string}
 */
export function getSeasonDisplayName(seasonName, subsidiary) {
    const regex = /^[A-Z]{2}[0-9]{4}$/;

    if (seasonName.match(regex)) {
        return seasonName
            .replace('AW', subsidiary.autumnWinterSeasonCode)
            .replace('SS', subsidiary.springSummerSeasonCode);
    }

    return seasonName;
}

/**
 * Creates a nested object from string segments where each segment represents object key. Example: `A|cps|5016` => `{ A: { cps: { 5016: {} } } }`
 * @param accumulator
 * @param string
 * @returns {*}
 */
export function stringSegmentsToObject(accumulator, string) {
    const [key, ...subKeys] = string.split('|');

    if (!accumulator[key]) {
        accumulator[key] = {};
    }

    if (subKeys.length) {
        const subKey = subKeys.shift();

        accumulator[key][subKey] = subKeys.length ? subKeys.reduce(stringSegmentsToObject, accumulator[key][subKey]) : {};
    }

    return accumulator;
}

/**
 * Checks if user's browser supports certain features and returns true if at least 1 feature is not supported
 * @returns {boolean}
 */
export function isLegacyBrowser() {
    const browserFeatures = {
        'window.structuredClone()': () => typeof window.structuredClone === 'function',
        'MediaQueryList.addEventListener()': () => {
            const mediaQueryList = window.matchMedia('(min-width: 1rem)');

            return typeof mediaQueryList.addEventListener === 'function';
        },
    };

    return !Object.entries(browserFeatures).every(([feature, featureCheck]) => {
        const featureSupported = featureCheck();

        if (!featureSupported) {
            console.warn(`This browser does not support "${feature}" feature. Browser: ${navigator.userAgent}`);
        }

        return featureSupported;
    });
}

export function getOrderJourneyMap() {
    return {
        [Const.ORDER_JOURNEY.AT_ONCE]: {
            translationKey: 'orderJourney.atOnce',
            color: 'bg-secondary',
        },
        [Const.ORDER_JOURNEY.PRE_ORDER]: {
            translationKey: 'orderJourney.preOrder',
            color: 'bg-green-dark',
        },
        [Const.ORDER_JOURNEY.FASHION_CONTRACT]: {
            translationKey: 'orderJourney.fashionContract',
            color: 'bg-green-dark',
        },
    };
}

/**
 * Normalizes and removes diacritic characters from string
 * TODO: find JS alternative for lodash `deburr`
 * @param {string} string
 * @returns {string}
 */
export function normalizeAndRemoveDiacritics(string) {
    return deburr(string).normalize('NFKC');
}

/**
 * Returns a language name for a given locale and language code
 * @param {string} locale BCP 47 (ISO 15924) code: en-US, en-AU, ja-JP, etc.
 * @param {string} languageCode a two-letter ISO 639-1 language code: US, AU, JP, etc.
 * @return {string}
 */
export function formatLanguageName(locale, languageCode) {
    return new Intl.DisplayNames(locale, { type: 'language' }).of(languageCode);
}

/**
 * Returns a region name for a given locale and region code
 * @param {string} locale
 * @param {string} regionCode
 * @return {string}
 */
export function formatRegionName(locale, regionCode) {
    return new Intl.DisplayNames(locale, { type: 'region' }).of(regionCode);
}

/**
 * Parses localStorage value as JSON if it should be a JS object. Otherwise, returns a string value or null
 * @param {string} key
 * @param {boolean} isObject
 * @returns {Object|string}
 */
export function parseJsonFromLocalStorage(key, isObject = true) {
    const storageValue = localStorage.getItem(key) ?? null;
    let value = storageValue;

    if (isObject) {
        try {
            value = JSON.parse(storageValue);
        } catch (error) {
            localStorage.removeItem(key);
        }
    }

    return value;
}

export function roundToNearest(number, nearest, trim) {
    const divisor = 1 / (nearest || 1);
    const decimalCount = (function (split) {
        return split.length === 2 ? split[1].length : 0;
    })(String(nearest).split('.'));

    if (divisor > 0) {
        number = (Math.round(number * divisor) / divisor).toFixed(decimalCount);
    }

    if (typeof trim === 'undefined' || trim) {
        number = parseFloat(number);
    }

    return number;
}

export function roundDiscount(number, shouldRoundToNearest = false) {
    const toNearest = shouldRoundToNearest || 0.01;

    if (toNearest) {
        return roundToNearest(number, toNearest);
    }

    return number;
}
