/**
 * Wraps component dynamic import function and handles chunk load error.
 * @param {Array} routes
 * @param {Object} route
 * @returns {Array}
 */
export function wrapDynamicImport(routes, route) {
    if (Array.isArray(route.children)) {
        route.children = route.children.reduce(wrapDynamicImport, []);
    }

    if (typeof route.component === 'function') {
        const componentImportFn = route.component;

        route.component = () => new Promise((resolve, reject) => {
            componentImportFn().then(component => {
                // Reset session storage value to `false`. It is then checked in router.js onError handler
                sessionStorage.setItem('ASICS_B2B.page-refreshed', 'false');
                resolve(component);
            }).catch(reject);
        });
    }

    return routes.concat(route);
}
