<template>
    <div>
        <CustomersDropdownMenu />
        <OrdersDropdownMenu />
        <DiscountsDropdownMenu v-if="subsidiarySettings.tradeTermsHarmonizationDiscountsEnabled" />
        <StatisticsDropdownMenu v-if="freeOfChargeUtilizationReportEnabled" />
        <ToolboxDropdownMenu />
        <SupportMenu />
        <UserProfileMenu
            mobile
            @closeMenu="$emit('closeMenu')"
        />
    </div>
</template>

<script>
import StatisticsDropdownMenu from '@/components/menu/internal-user/StatisticsDropdownMenu.vue';
import CustomersDropdownMenu from '@/components/menu/internal-user/CustomersDropdownMenu.vue';
import DiscountsDropdownMenu from '@/components/menu/internal-user/DiscountsDropdownMenu.vue';
import OrdersDropdownMenu from '@/components/menu/internal-user/OrdersDropdownMenu.vue';
import ToolboxDropdownMenu from '@/components/menu/internal-user/ToolboxDropdownMenu.vue';
import SupportMenu from '@/components/menu/SupportMenu.vue';
import UserProfileMenu from '@/components/menu/user-profile-menu/UserProfileMenu.vue';
import { Const } from '@/utils/constants';

export default {
    name: 'InternalUserMenu',

    components: {
        StatisticsDropdownMenu,
        CustomersDropdownMenu,
        DiscountsDropdownMenu,
        OrdersDropdownMenu,
        ToolboxDropdownMenu,
        SupportMenu,
        UserProfileMenu,
    },

    props: {
        subsidiarySettings: {
            type: Object,
            required: true,
        },
    },

    computed: {
        freeOfChargeUtilizationReportEnabled() {
            return this.$store.state.subsidiary.freeOfChargeUtilizationReportEnabled && this.$store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_FOC);
        },
    },
};
</script>
