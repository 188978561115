<template>
    <router-link
        :to="`/orders/${basket.no}`"
        class="flex flex-col h-[17rem] overflow-hidden border rounded-3xl"
        :class="basketCardTheme.card"
    >
        <div
            class="flex flex-none items-center justify-between gap-4 h-[4.5rem] px-8 text-lg sm:text-xl font-bold"
            :class="basketCardTheme.header"
        >
            <span class="shrink-0">{{ $t('baskets.basket') }} #{{ basket.no }}</span>
            <span class="truncate">{{ formatDate(basket.createdAt) }}</span>
        </div>
        <div class="flex flex-col justify-center gap-6 h-full px-8">
            <div class="flex flex-col leading-snug">
                <span>{{ basket.shipTo.name }} - {{ basket.shipTo.customerNo }}</span>
                <span>{{ basket.shipTo.address1 }} - {{ basket.shipTo.no }}</span>
            </div>
            <div class="flex flex-wrap items-center justify-between font-bold">
                <div class="flex items-center gap-3">
                    <i
                        class="fa fa-lg"
                        :class="basketCardTheme.icon"
                    />
                    <span class="flex items-center gap-2 capitalize">
                        <span>{{ $t(basketCardTheme.translationKey) }}</span>
                        <span class="font-normal">({{ $t(basket.focStatus ? 'orderDetails.typeFOC' : `orderType.${basket.orderType}`) }})</span>
                    </span>
                </div>
                <div class="flex items-center gap-3">
                    <span>{{ basket.totalQuantity }} {{ $t(unitTranslation) }}</span>
                    <span>|</span>
                    <span>{{ formatPrice(basket.totalGrossAmount, basket.currencyCode) }}</span>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script setup>
import { computed, reactive } from 'vue';
import { Const } from '@/utils/constants.js';
import store from '@/store/store.js';
import { localisedCurrencyFormat, localisedDateTimeFormat } from '@/utils/generic.js';

const unitTranslation = computed(() => props.basket.totalQuantity === 1 ? 'basket.unit' : 'basket.units');
const formatDate = date => localisedDateTimeFormat(new Date(date), store.getters.userLocale, { dateStyle: 'medium', timeStyle: 'short' });
const formatPrice = (value, currencyCode) => localisedCurrencyFormat(value, store.state.subsidiary.locale, currencyCode);

const props = defineProps({
    basket: {
        type: Object,
        required: true,
    },

    isPreorderTemplatesEnabled: {
        type: Boolean,
        required: true,
    },
});

const state = reactive({
    basketCardTheme: {
        [Const.ORDER_JOURNEY.AT_ONCE]: {
            card: 'border-secondary-lightest hover:!text-primary hover:border-secondary',
            header: 'bg-secondary-lightest',
            icon: 'fa-check-circle text-secondary',
            translationKey: 'orderJourney.atOnce',
        },
        [Const.ORDER_JOURNEY.PRE_ORDER]: {
            card: 'border-green-lightest hover:!text-primary hover:border-green',
            header: 'bg-green-lightest',
            icon: 'fa-forward text-green-dark',
            translationKey: 'orderJourney.preOrder',
        },
    },
});

const basketCardTheme = computed(() => props.isPreorderTemplatesEnabled && Boolean(props.basket.offerId)
    ? state.basketCardTheme[Const.ORDER_JOURNEY.PRE_ORDER]
    : state.basketCardTheme[props.basket.orderJourney]);

</script>
