<template>
    <ul>
        <li
            v-for="subsidiary in countrySubsidiaries"
            :key="subsidiary.id"
            class="flex items-center justify-between px-4 py-2 mb-1"
            :class="{'bg-secondary-lightest rounded-md': subsidiary.id === storedSubsidiaryId}"
        >
            <ButtonPlain
                :text="formatCountryName(subsidiary)"
                class="hover:underline"
                @click.native="selectSubsidiary(subsidiary)"
            />
            <i
                v-if="subsidiary.id === storedSubsidiaryId"
                class="fa fa-check"
            />
        </li>
        <li
            v-if="nonCountrySubsidiaries.length"
            class="mx-4 mb-6 pt-3 border-b w-4"
        />
        <li
            v-for="subsidiary in nonCountrySubsidiaries"
            :key="subsidiary.id"
            class="flex items-center justify-between px-4 py-2 mb-1"
            :class="{'bg-secondary-lightest rounded-md': subsidiary.id === storedSubsidiaryId}"
        >
            <ButtonPlain
                :text="formatCountryName(subsidiary)"
                class="hover:underline"
                @click.native="selectSubsidiary(subsidiary)"
            />
            <i
                v-if="subsidiary.id === storedSubsidiaryId"
                class="fa fa-check"
            />
        </li>
    </ul>
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import { useI18n } from '@/composables/use-i18n.js';
import store from '@/store/store.js';
import { formatRegionName } from '@/utils/generic.js';
import { computed } from 'vue';

const props = defineProps({
    storedSubsidiary: {
        type: Object,
        default: null,
    },

    subsidiaries: {
        type: Array,
        required: true,
    },
});

const emit = defineEmits([
    'select',
]);

const { t } = useI18n();
const storedSubsidiaryId = computed(() => props.storedSubsidiary?.id);
const countrySubsidiaries = computed(() => props.subsidiaries
    .filter(({ isCountry }) => isCountry)
    .map(subsidiary => ({ ...subsidiary, countryName: formatCountryName(subsidiary) }))
    .sort((a, b) => a.countryName.localeCompare(b.countryName)));

const nonCountrySubsidiaries = computed(() => props.subsidiaries
    .filter(({ isCountry }) => !isCountry)
    .map(subsidiary => ({ ...subsidiary, countryName: formatCountryName(subsidiary) }))
    .sort((a, b) => a.countryName.localeCompare(b.countryName)));

const formatCountryName = ({ countryCode, translationKey }) => countryCode ? formatRegionName(store.getters['globalStore/userLocale'], countryCode) : t(translationKey);

const selectSubsidiary = subsidiary => {
    emit('select', subsidiary);
};

</script>
