import api, { createTransformingApiInstance } from '@/api/api.js';

export function getRegions() {
    const api = createTransformingApiInstance();

    return api.get('public/geolocations');
}

export function getFrontendThirdPartyConfigs() {
    const api = createTransformingApiInstance();

    return api.get('public/frontend-configs');
}

export function logIn(payload) {
    const api = createTransformingApiInstance();

    return api.post('authentication/login', payload);
}

export function logOut() {
    return api.post('authentication/logout');
}

/**
 * Used to verify Laravel Sanctum access token sent by Gotenberg PDF service
 * @param {Object} payload
 * @returns {*}
 */
export function verifyAccessToken(payload) {
    const api = createTransformingApiInstance();

    return api.post('authentication/access-token-verification', payload);
}

export function initializeCsrfProtection() {
    return api.get('sanctum/csrf-cookie');
}

export function getAuthUserState() {
    return api.get('auth-user-state');
}

export function createAuthenticationMfaChallenge(payload, config) {
    const api = createTransformingApiInstance();

    return api.post('authentication/mfa-challenges', payload, config);
}

export function verifyAuthenticationMfaChallenge(id, payload) {
    const api = createTransformingApiInstance();

    return api.patch(`authentication/mfa-challenges/${id}`, payload);
}
