<template>
    <ModalDialog
        :closable="closable"
        @close="close"
    >
        <template #header-title>
            <div class="flex flex-col items-center gap-4">
                <AsicsLogo />
                <h1 class="md:heading-lg !m-0">
                    {{ $t('authentication.heading') }}
                </h1>
                <span class="mt-4">{{ $t('general.subsidiarySelectionDialog.title') }}</span>
            </div>
        </template>
        <template #body>
            <Regions
                :regions="regions"
                :stored-subsidiary="storedSubsidiary"
                @select="close"
            />
        </template>
    </ModalDialog>
</template>

<script setup>
import AsicsLogo from '@/components/asics-logo/AsicsLogo.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import Regions from '@/modules/unauthenticated/partials/Regions.vue';
import store from '@/store/store.js';
import { computed } from 'vue';

defineProps({
    regions: {
        type: Array,
        required: true,
    },
});

const emit = defineEmits([
    'close',
]);

const storedSubsidiary = computed(() => store.getters['globalStore/subsidiary']);
const closable = computed(() => Boolean(storedSubsidiary.value));

const close = (subsidiary = null) => {
    if (!closable.value && !subsidiary) {
        return;
    }

    emit('close', subsidiary);
};

</script>
