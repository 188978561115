import Echo from 'laravel-echo';
import socketIoClient from 'socket.io-client';

let laravelEchoClient = null;

function setupLaravelEcho(subsidiaryKey) {
    laravelEchoClient = new Echo({
        client: socketIoClient,
        broadcaster: 'socket.io',
        host: ['80', '443'].includes(window.location.port)
            ? window.location.hostname
            : `${window.location.hostname}:${window.location.port}`,
        transports: [
            'websocket',
        ],
    });

    laravelEchoClient.connector.options.auth.headers['x-subsidiary'] = subsidiaryKey;
}

export {
    setupLaravelEcho,
    laravelEchoClient,
};
