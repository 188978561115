<template>
    <div>
        <div class="asics-table-container clickable-rows">
            <div class="asics-table-toolbar">
                <div class="asics-table-toolbar-item">
                    <select
                        id="items-per-page"
                        v-model="perPage"
                        class="page-item-count"
                        @change="onChangePage(1)"
                    >
                        <option
                            v-for="option in pageSizeOptions"
                            :key="option"
                            :value="option"
                        >
                            {{ option }}
                        </option>
                    </select>
                    <label for="items-per-page">
                        {{ $t('adminCustomers.customersPerPage') }}
                    </label>
                </div>
                <div class="asics-table-toolbar-item">
                    <SearchInput
                        id="customers-search"
                        v-model="searchQuery"
                        :placeholder="$t('general.labelSearch')"
                        @input="onFilterSet"
                    />
                </div>
            </div>

            <Vuetable
                ref="vuetable"
                :api-mode="apiMode"
                :per-page="perPage"
                :fields="fields"
                :data-manager="dataManager"
                track-by="customerNo"
                :show-sort-icons="true"
                :css="css.table"
                :sort-order="sortOrder"
                :no-data-template="noDataMessage"
                :row-class="onRowClass"
                pagination-path="pagination"
                @vuetable:row-clicked="onActionClicked"
                @vuetable:pagination-data="onPaginationData"
            />

            <div class="asics-pagination-container">
                <VuetablePaginationInfo
                    ref="paginationInfo"
                    :no-data-template="''"
                    :info-template="$t('general.paginationInfo')"
                />
                <VuetablePagination
                    ref="pagination"
                    :css="css.pagination"
                    @vuetable-pagination:change-page="onChangePage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { bugsnag } from '@/libs/bugsnag.js';
import asicsTableMixin from '@/components/data-tables/data-table.mixin.js';
import { getCustomers } from '@/modules/admin/customers/customer/customer.api.js';
import { Vuetable, VuetablePagination, VuetablePaginationInfo } from 'vuetable-2';
import SearchInput from '@/components/search-input/SearchInput.vue';
import { CustomerSearcher } from '@/components/search/fuzzy-search.js';

export default {
    name: 'CustomerList',

    components: {
        Vuetable,
        VuetablePagination,
        VuetablePaginationInfo,
        SearchInput,
    },

    mixins: [asicsTableMixin],

    props: {
        hidden: {
            type: Array,
            default: null,
        },

        cacheNewKey: {
            type: String,
            default: 'table_customer',
        },

        defaultPerPage: {
            type: Number,
            default: 25,
        },
    },

    data() {
        return {
            sortOrder: [
                {
                    field: 'no',
                    direction: 'desc',
                },
            ],
            fields: [
                {
                    name: 'no',
                    title: () => this.$t('general.labelCustomerNo'),
                    sortField: 'no',
                },
                {
                    name: 'oldCustomerNo',
                    title: () => this.$t('general.labelOldCustomerNo'),
                    sortField: 'oldCustomerNo',
                    visible: this.$store.getters.getSubsidiarySettings.showOldCustomerNo,
                },
                {
                    name: 'name',
                    title: () => this.$t('general.labelCustomerName'),
                    sortField: 'name',
                },
                {
                    name: 'name2',
                    title: () => `${this.$t('general.labelCustomerName')} 2`,
                    sortField: 'name2',
                },
                {
                    name: 'address1',
                    title: () => this.$t('general.labelAddress1'),
                    sortField: 'address1',
                },
                {
                    name: 'address2',
                    title: () => this.$t('general.labelAddress2'),
                    sortField: 'address2',
                },
                {
                    name: 'city',
                    title: () => this.$t('general.labelCity'),
                    sortField: 'city',
                },
                {
                    name: 'mainDivisionName',
                    title: () => this.$t('general.labelState'),
                    sortField: 'mainDivisionName',
                },
                {
                    name: 'postCode',
                    title: () => this.$t('general.labelPostCode'),
                    sortField: 'postCode',
                },
                {
                    name: 'countryCode',
                    title: () => this.$t('general.labelCountry'),
                    sortField: 'countryCode',
                },
                {
                    name: 'accountTypeValue',
                    title: () => this.$t('general.labelAccountType'),
                    sortField: 'accountTypeValue',
                },
                {
                    name: 'statusName',
                    title: () => this.$t('general.labelStatus'),
                    sortField: 'statusName',
                },
            ],
            fuzzyCompareFunc: new CustomerSearcher(),
            selectedRow: null,
            retainSearchQueryInput: false,
            tableCacheName: this.cacheNewKey,
            perPage: this.defaultPerPage,
        };
    },

    created() {
        this.loadData();
    },

    mounted() {
        if (this.hidden) {
            this.fields.forEach(field => {
                if (this.hidden.includes(field.name)) {
                    field.visible = false;
                }
            });

            this.$refs.vuetable.normalizeFields();
        }
    },

    methods: {
        async loadData() {
            try {
                const response = await getCustomers();

                this.data = response.data.data;
                this.onFilterSet();

                try {
                    // save to cache (sessionStorage)
                    if (this.cacheKey && Array.isArray(this.data)) {
                        sessionStorage.setItem(this.cacheKey, JSON.stringify(this.data));
                    }
                } catch (error) {
                    sessionStorage.removeItem(this.cacheKey);
                }
            } catch (error) {
                bugsnag.notify(error);
            }
        },

        filterFunc(parts, row) {
            return this.fuzzyCompareFunc.evaluate(row, parts);
        },

        onActionClicked(data) {
            this.selectedRow = data;
            this.$emit('customerSelected', data);
        },

        onRowClass(dataItem) {
            return (dataItem === this.selectedRow) ? 'selected-row' : '';
        },
    },
};
</script>
