<template>
    <div>
        <CustomerDownloadMenu />
        <CommunicationsMenu v-if="state.blogEnabled" />
        <ToolboxMenu v-if="toolboxEnabled" />
        <SupportMenu />
    </div>
</template>

<script setup>
import CustomerDownloadMenu from '@/components/footer-menu/customer/CustomerDownloadMenu.vue';
import CommunicationsMenu from '@/components/footer-menu/common/CommunicationsMenu.vue';
import SupportMenu from '@/components/footer-menu/common/SupportMenu.vue';
import ToolboxMenu from '@/components/footer-menu/customer/ToolboxMenu.vue';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { reactive, computed } from 'vue';

const state = reactive({
    blogEnabled: store.state.subsidiary.blogEnabled,
    distributionChannel: store.getters.getCustomerDistributionChannel,
    isAjpCustomer:
        store.state.subsidiary.id === Const.SUBSIDIARIES.JAPAN &&
        store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER,
    isAebCustomer:
        store.state.subsidiary.id === Const.SUBSIDIARIES.DISTRIBUTORS &&
        store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER,
});

// AJP customer whether distributor or not should under no circumstances have access to create their own e-catalogs or see the toolbox menu.
const toolboxEnabled = computed(() => {
    return !state.isAjpCustomer &&
        !state.isAebCustomer &&
        state.distributionChannel === '30';
});

</script>
