import { bugsnag } from '@/libs/bugsnag.js';
import { parseJsonFromLocalStorage } from '@/utils/generic.js';

export function persistState(key, value, stringify = false) {
    localStorage.setItem(`ASICS_B2B.${key}`, stringify ? JSON.stringify(value) : value);
}

export function getPersistedState(key, parseJson = false) {
    return parseJsonFromLocalStorage(`ASICS_B2B.${key}`, parseJson);
}

/**
 * Determines the most relevant locale on user's first interaction based on the following criteria:
 * 1. use preferred locale if it's in xx-XX format
 * 2. if preferred locale is in xx format, use another locale in xx-XX format from preferred list that starts with xx
 * 3. use constructed locale where xx becomes xx-XX (as in de -> de-DE), as long as it's supported by user's system
 * 4. else, fallback to en-GB
 * @returns {string}
 */
export function resolveUserLocale() {
    const navigatorLanguage = navigator.language;
    const navigatorLanguages = navigator.languages;

    if (navigatorLanguage.length === 5) {
        return navigatorLanguage;
    }

    const oneOfPreferredLocales = navigatorLanguages.find(language => language.startsWith(navigatorLanguage) && language.length === 5);
    const constructedLocale = `${navigatorLanguage}-${navigatorLanguage.toUpperCase()}`;

    let supportedLocales = null;

    try {
        supportedLocales = Intl.DisplayNames.supportedLocalesOf([constructedLocale]);
    } catch (error) {
        bugsnag.notify(error);
    }

    return oneOfPreferredLocales ?? (Array.isArray(supportedLocales) ? constructedLocale : 'en-GB');
}
