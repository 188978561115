<template>
    <div>
        <ValidationObserver
            v-slot="{ invalid }"
            tag="form"
            role="form"
            autocomplete="off"
            novalidate
            @submit.prevent="update"
        >
            <TextInput
                id="mfa-email"
                v-model="state.email"
                :label="$t('mfa.mfaEmail')"
                :rules="{required: mfaEmailRequired, email: true, max:255}"
                :autofocus="autofocus"
            />
            <div class="action-buttons">
                <Button
                    :disabled="invalid || state.submitInProgress"
                    type="submit"
                    class="ml-auto"
                >
                    <i :class="state.submitInProgress ? 'fa fa-spinner fa-spin' : 'fa fa-save'" />
                    <span>{{ $t('userMfaEmail.buttonAccept') }}</span>
                </Button>
            </div>
        </ValidationObserver>

        <ErrorDialog
            v-if="state.showErrorDialog"
            @close="hideErrorDialog"
        />

        <ToastNotification />
    </div>
</template>

<script setup>

import Button from '@/components/button/Button.vue';
import ToastNotification from '@/components/toast-notification/ToastNotification.vue';
import { reactive } from 'vue';
import TextInput from '@/components/text-input/TextInput.vue';
import { updateMfaEmail } from '@/modules/employees/users.api';
import ErrorDialog from '@/components/dialogs/ErrorDialog.vue';
import { bugsnag } from '@/libs/bugsnag';

const props = defineProps({
    username: {
        type: String,
        required: true,
    },

    mfaEmail: {
        type: String,
        default: null,
    },

    mfaEmailRequired: {
        type: Boolean,
        default: false,
    },

    autofocus: {
        type: Boolean,
        default: false,
    },

    showSuccessSnackbarOnUpdated: {
        type: Boolean,
        default: true,
    },
});

const state = reactive({
    email: props.mfaEmail,
    showErrorDialog: false,
    submitInProgress: false,
});

const emit = defineEmits([
    'updated',
]);

const update = async () => {
    state.submitInProgress = true;

    try {
        await updateMfaEmail(props.username, state.email);

        emit('updated', state.email);
    } catch (error) {
        bugsnag.notify(error);

        state.showErrorDialog = true;
    }

    state.submitInProgress = false;
};

const hideErrorDialog = () => {
    state.showErrorDialog = false;
};

</script>
