<template>
    <div class="page-wrapper">
        <LoggedOutModal v-if="isLoggedOut" />
        <template v-else>
            <NotificationBanner v-show="!state.mobileMenuExpanded" />
            <PageHeader :class="{'!z-[30]': state.mobileMenuExpanded}" />
            <main>
                <section class="w-full h-full 2xl:mx-auto 2xl:container">
                    <router-view /><!-- THIS IS THE ENTRY POINT FOR ALL ROUTES -->
                </section>
                <PageFooter />
            </main>
            <template v-if="!isBrowserControlledByAutomation">
                <CookieConsent v-if="cookieConsentRequired" />
                <NewsAlertAcknowledgment v-if="canAcknowledgeNewsAlerts" />
            </template>
            <HelpButton v-if="showFloatingHelpButton" />
        </template>
    </div>
</template>

<script setup>
import LoggedOutModal from '@/components/dialogs/LoggedOutModal.vue';
import HelpButton from '@/components/help-button/HelpButton.vue';
import PageFooter from '@/components/layout/PageFooter.vue';
import PageHeader from '@/components/layout/PageHeader.vue';
import NewsAlertAcknowledgment from '@/components/news-alert-acknowledgment/NewsAlertAcknowledgment.vue';
import NotificationBanner from '@/components/notification-banner/NotificationBanner.vue';
import basketService from '@/modules/baskets/basket.service.js';
import CookieConsent from '@/modules/unauthenticated/partials/CookieConsent.vue';
import { getPersistedState } from '@/store/store-helpers.js';
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { emitter } from '@/utils/event-emitter.js';
import { computed, onBeforeUnmount, reactive } from 'vue';

const MIN_DESKTOP_VIEWPORT_SIZE = '48rem'; // The same as `md:` screen size in tailwind.config.js

const state = reactive({
    mediaQueryList: null,
    mobileMenuExpanded: false,
});

const newsAlertsAcknowledgedPerSession = getPersistedState('newsAlertsAcknowledgedPerSession', true);
const isLoggedOut = computed(() => store.getters.isLoggedOut);
const selectedSubsidiary = computed(() => store.getters['globalStore/subsidiary']);
const cookieConsentRequired = computed(() => Boolean(selectedSubsidiary.value) && ![Const.SUBSIDIARIES.JAPAN, Const.SUBSIDIARIES.NISHI].includes(selectedSubsidiary.value.key));
const isCustomer = computed(() => store.getters.getUserType === Const.ACCOUNT_TYPE_CUSTOMER);
const canAcknowledgeNewsAlerts = computed(() => isCustomer.value && store.state.subsidiary.blogEnabled && !newsAlertsAcknowledgedPerSession);
const showFloatingHelpButton = computed(() => isCustomer.value && store.state.subsidiary.showFloatingHelpButton);
const isBrowserControlledByAutomation = computed(() => store.getters.isBrowserControlledByAutomation);

const setMobileViewport = value => {
    store.commit('setMobileViewport', value);
};

const toggleMobileMenuExpanded = value => {
    state.mobileMenuExpanded = value;
};

state.mediaQueryList = window.matchMedia(`(min-width: ${MIN_DESKTOP_VIEWPORT_SIZE})`);
state.mediaQueryList.addEventListener('change', event => setMobileViewport(!event.matches));

setMobileViewport(!state.mediaQueryList.matches);
basketService.setSubsidiary(store.state.subsidiary);

emitter.$on('mobileMenuExpanded', toggleMobileMenuExpanded);

onBeforeUnmount(() => {
    state.mediaQueryList.removeEventListener('change', setMobileViewport);
    emitter.$off('mobileMenuExpanded', toggleMobileMenuExpanded);
});

</script>
