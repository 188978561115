<template>
    <ModalDialog
        size="small"
        :closable="false"
    >
        <template #header-title>
            {{ $t('general.mfa') }}
        </template>
        <template #body>
            <div class="flex flex-col gap-4">
                <div class="leading-snug">
                    <span>{{ $t('mfa.verificationCodeDialog.info') }}</span>
                    <span v-if="mfaEmail">
                        <a
                            href=""
                            class="text-secondary underline"
                            @click.prevent="changeMfaEmailAddress"
                        >
                            {{ $t('mfa.verificationCodeDialog.changeMfaEmail') }}
                        </a>
                    </span>
                </div>
                <ValidationObserver
                    v-slot="{ invalid }"
                    tag="form"
                    novalidate
                    @submit.prevent="verifyMfaCode"
                >
                    <TextInput
                        id="verification-code"
                        v-model="state.verificationCode"
                        :max-length="6"
                        :label="$t('mfa.verificationCode')"
                        rules="required|digits:6"
                        @keyup.native="resetErrorMessage"
                    >
                        <template
                            v-if="state.verificationErrorMessage"
                            #errors
                        >
                            {{ $t(state.verificationErrorMessage) }}
                        </template>
                    </TextInput>

                    <p
                        v-if="state.timer"
                        class="flex gap-2"
                    >
                        <span>{{ $t('mfa.verificationCodeDialog.codeExpiresIn') }}</span>
                        <time class="font-bold">{{ formatRemainingTime(state.timer) }}</time>
                    </p>
                    <p v-if="state.verificationCodeExpired">
                        {{ $t('mfa.verificationCodeDialog.codeExpired') }}
                    </p>
                    <div class="action-buttons mt-4">
                        <Button
                            v-if="state.verificationCodeExpired"
                            kind="primary-alt"
                            :text="$t('mfa.verificationCodeDialog.buttonResend')"
                            @click.native="sendMfaCode"
                        >
                            <i :class="['fa', state.mfaCodeSendInProgress ? 'fa-spinner fa-spin' : 'fa-paper-plane']" />
                        </Button>
                        <Button
                            :disabled="invalid || !canVerifyCode"
                            :text="$t('mfa.verify')"
                            type="submit"
                        >
                            <i :class="state.submitInProgress ? 'fa fa-spinner fa-spin' : 'fa fa-unlock'" />
                        </Button>
                    </div>
                </ValidationObserver>
            </div>
        </template>
    </ModalDialog>
</template>

<script setup>
import Button from '@/components/button/Button.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import { createAuthenticationMfaChallenge, verifyAuthenticationMfaChallenge } from '@/modules/unauthenticated/login/login.api.js';
import store from '@/store/store.js';
import { localisedDateTimeFormat } from '@/utils/generic.js';
import { computed, reactive } from 'vue';
import TextInput from '@/components/text-input/TextInput.vue';
import { bugsnag } from '@/libs/bugsnag';
import { Const } from '@/utils/constants';

const abortController = new AbortController();

const props = defineProps({
    subsidiary: {
        type: String,
        required: true,
    },

    username: {
        type: String,
        required: true,
    },

    mfaEmail: {
        type: String,
        default: null,
    },
});

const emit = defineEmits([
    'verified',
    'emailChangeRequested',
]);

const state = reactive({
    verificationCode: null,
    verificationErrorMessage: null,
    mfaChallenge: {},
    submitInProgress: false,
    mfaCodeSendInProgress: false,
    verificationCodeExpired: false,
    timer: 0,
});

const canVerifyCode = computed(() => !state.mfaCodeSendInProgress && !state.submitInProgress && !state.verificationCodeExpired);

const changeMfaEmailAddress = () => {
    if (!abortController.signal.aborted) {
        abortController.abort();
    }

    emit('emailChangeRequested');
};

const resetErrorMessage = () => {
    state.verificationErrorMessage = null;
};

const startCountDown = timeUntilExpiry => {
    state.timer = new Date(timeUntilExpiry).getTime() - Date.now();

    const intervalId = setInterval(() => {
        if (state.timer <= 0) {
            clearInterval(intervalId);

            state.verificationCodeExpired = true;

            return;
        }

        state.timer = state.timer < 1000 ? 0 : state.timer - 1000;
    }, 1000);
};

const formatRemainingTime = time => localisedDateTimeFormat(new Date(time), store.getters['globalStore/userLocale'], { minute: 'numeric', second: 'numeric' });

const sendMfaCode = async () => {
    state.mfaCodeSendInProgress = true;

    try {
        const { data: challenge } = await createAuthenticationMfaChallenge({
            subsidiary: props.subsidiary,
            username: props.username,
            mfaEmail: props.mfaEmail,
        }, {
            signal: abortController.signal,
        });

        state.mfaChallenge = challenge;
        state.verificationCodeExpired = false;

        startCountDown(challenge.verifiableUntil);
    } catch (error) {
        bugsnag.notify(error);
    } finally {
        state.mfaCodeSendInProgress = false;
    }
};

const verifyMfaCode = async () => {
    resetErrorMessage();

    state.submitInProgress = true;

    try {
        await verifyAuthenticationMfaChallenge(state.mfaChallenge.id, {
            subsidiary: props.subsidiary,
            verificationCode: state.verificationCode,
        });

        emit('verified', { mfaChallengeId: state.mfaChallenge.id });
    } catch (error) {
        switch (error?.response?.status) {
            case Const.HTTP_STATUS.UNPROCESSABLE_CONTENT:
                state.verificationErrorMessage = 'mfa.verificationCodeIncorrect';
                break;
            case Const.HTTP_STATUS.TOO_MANY_ATTEMPTS:
                state.verificationErrorMessage = 'mfa.tooManyAttempts';
                break;
            default:
                state.verificationErrorMessage = 'general.error';

                bugsnag.notify(error);
        }
    } finally {
        state.submitInProgress = false;
    }
};

sendMfaCode();

</script>
