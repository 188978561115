<template>
    <DropDownMenu>
        <template #menu-title-link>
            <router-link to="/customers">
                <span>{{ $t('employeeMainMenu.customers') }}</span>
                <i class="fa fa-users text-sm ml-4" />
            </router-link>
        </template>
    </DropDownMenu>
</template>

<script>
import DropDownMenu from '@/components/menu/DropDownMenu.vue';

export default {
    name: 'CustomersDropdownMenu',

    components: {
        DropDownMenu,
    },
};
</script>
