<template>
    <ul
        :class="['menu', {'absolute top-0 md:relative h-full': state.expanded}]"
        role="tree"
        @mouseenter="expandMenu"
        @mouseleave="collapseMenu"
    >
        <li
            role="treeitem"
            tabindex="0"
            :aria-expanded="state.expanded"
            class="w-full"
        >
            <div
                class="menu-title"
                :class="state.expanded ? expandedMenuColors : 'justify-between'"
                @click="toggleMenu"
            >
                <i
                    v-if="isMobileViewport"
                    :class="['fa', state.expanded ? 'fa-chevron-left' : 'fa-chevron-right order-last']"
                />
                {{ $t(orderJourney.translationKey) }}
            </div>
            <div class="relative">
                <ul
                    v-show="state.expanded"
                    class="sub-menu left-0 rounded-tr-xl"
                >
                    <slot
                        name="menu-options"
                        v-bind="{collapseMenu}"
                    />
                </ul>
            </div>
        </li>
    </ul>
</template>

<script setup>
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { computed, reactive } from 'vue';

const props = defineProps({
    orderJourney: {
        type: Object,
        required: true,
    },

    isOffer: {
        type: Boolean,
        default: false,
    },
});

const state = reactive({
    expanded: false,
});

const isMobileViewport = computed(() => store.getters.isMobileViewport);
const expandedMenuColors = computed(() => props.orderJourney.value === ORDER_JOURNEY.AT_ONCE ? 'bg-secondary text-white' : 'bg-green-dark text-white');
const ORDER_JOURNEY = Const.ORDER_JOURNEY;

const expandMenu = () => {
    if (isMobileViewport.value) {
        return;
    }

    state.expanded = true;
};

const collapseMenu = (force = false) => {
    if (isMobileViewport.value && !force) {
        return;
    }

    state.expanded = false;
};

const toggleMenu = () => {
    if (!isMobileViewport.value) {
        return;
    }

    state.expanded = !state.expanded;
};

</script>

<style lang="scss" scoped>
.menu {
    @apply w-full md:h-full md:w-max whitespace-nowrap capitalize list-none m-0 p-0 bg-white;

    li {
        @apply md:h-full;

        > a {
            @apply block;
        }
    }

    .menu-title,
    .menu-title-link {
        @apply flex gap-x-8 items-center w-full h-full min-h-[4.4rem] font-bold cursor-pointer px-8;

        > a {
            @apply w-full;
        }
    }

    .secondary-menu-title {
        @apply flex items-center w-full h-full font-bold cursor-pointer hover:bg-secondary;
    }

    [aria-expanded='true'] .menu-title-link a {
        @apply text-white;
    }

    .divider {
        @apply border-t border-secondary-lightest;
    }

    .sub-menu {
        > li {
            @apply flex md:flex-col items-start px-8;
        }

        @apply flex flex-col md:flex-row md:gap-y-4 font-normal bg-white absolute divide-y divide-grey-light md:divide-none md:border-t border-grey-light md:rounded-br-xl md:rounded-bl-xl overflow-auto w-full md:w-auto min-w-[20rem] h-screen md:h-auto max-h-[calc(100vh_-_7rem)] md:py-8 px-0 top-0 z-[100] md:shadow-[0_0.4rem_0.4rem_rgba(0,0,0,0.25)];
    }
}
</style>
