import api, { createTransformingApiInstance } from '@/api/api.js';

export function getCustomerByNumber(number) {
    return api.get(`customerCtrl/getCustomerByNoWithBuyingGroupDetails/${number}`);
}

export function getB2bUsersByCustomerNumber(customerNo, params) {
    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.get(`customers/${customerNo}/users`, { params });
}

export function impersonateCustomerUser(username) {
    return api.post(`customers/users/${username}/impersonation`);
}
