<template>
    <OrderJourneyMenu :order-journey="orderJourney">
        <template #menu-options="{collapseMenu}">
            <li
                v-if="filters.productTypes.length"
                class="menu-group"
            >
                <div
                    class="menu-group-title"
                    @click="toggleExpanded('products')"
                >
                    {{ $t('general.products') }}
                </div>
                <ul
                    v-show="!isMobileViewport || state.expanded.products"
                    class="menu-group-items"
                >
                    <li
                        v-for="item in filters.productTypes"
                        :key="item.id"
                        class="menu-group-item"
                    >
                        <ButtonPlain
                            :text="$t(item.label)"
                            class="font-normal"
                            @click.native="onFilterSelect('productType', item, collapseMenu)"
                        />
                    </li>
                </ul>
            </li>
            <li
                v-if="filters.assortments.length"
                class="menu-group"
            >
                <div
                    class="menu-group-title"
                    @click="toggleExpanded('assortments')"
                >
                    {{ $t('general.assortments') }}
                </div>
                <ul
                    v-show="!isMobileViewport || state.expanded.assortments"
                    class="menu-group-items"
                >
                    <li
                        v-for="item in filters.assortments"
                        :key="item.id"
                        class="menu-group-item"
                    >
                        <ButtonPlain
                            :text="$t(item.label)"
                            class="font-normal"
                            @click.native="onFilterSelect('brand', item, collapseMenu)"
                        />
                    </li>
                </ul>
            </li>
            <li
                v-if="filters.seasons.length"
                class="menu-group"
            >
                <div
                    class="menu-group-title"
                    @click="toggleExpanded('seasons')"
                >
                    {{ $t('general.seasons') }}
                </div>
                <ul
                    v-show="!isMobileViewport || state.expanded.seasons"
                    class="menu-group-items"
                >
                    <li
                        v-for="item in filters.seasons"
                        :key="item.id"
                        class="menu-group-item"
                    >
                        <ButtonPlain
                            :text="item.label"
                            class="font-normal"
                            @click.native="onFilterSelect('seasons', item, collapseMenu)"
                        />
                    </li>
                </ul>
            </li>
            <li class="menu-group">
                <div class="menu-group-title">
                    <ButtonPlain @click.native="onShopAllAtOnce(collapseMenu)">
                        <span>{{ $t('topNavMenu.shopAllAtOnce') }}</span>
                        <i class="fa fa-arrow-right" />
                    </ButtonPlain>
                </div>
            </li>
        </template>
    </OrderJourneyMenu>
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import OrderJourneyMenu from '@/components/menu/customer/OrderJourneyMenu.vue';
import { Const } from '@/utils/constants.js';
import { reactive } from 'vue';

const props = defineProps({
    availableOrderJourneys: {
        type: Array,
        required: true,
    },

    isMobileViewport: {
        type: Boolean,
        required: true,
    },

    filters: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits([
    'onSelect',
]);

const orderJourney = props.availableOrderJourneys.find(({ value }) => value === Const.ORDER_JOURNEY.AT_ONCE);

const state = reactive({
    expanded: {
        products: false,
        assortments: false,
        seasons: false,
    },
});

const onFilterSelect = (group, { id }, collapseMenu) => {
    emit('onSelect', {
        orderJourney: orderJourney.value,
        filter: {
            group,
            id,
        },
    });
    collapseMenu();
};

const toggleExpanded = type => {
    if (!props.isMobileViewport) {
        return;
    }

    state.expanded[type] = !state.expanded[type];
};

const onShopAllAtOnce = collapseMenu => {
    emit('onSelect', { orderJourney: orderJourney.value });
    collapseMenu();
};

</script>

<style scoped>
.menu-group {
    @apply flex flex-col;
}

.menu-group-title {
    @apply items-start font-bold py-4 w-full;
}

.menu-group-items {
    @apply divide-y divide-grey-light md:divide-none w-full;
}

.menu-group-item {
    @apply py-4 md:py-1;
}
</style>
