<template>
    <div>
        <AtOnceOrderJourneyMenu
            v-if="atOnceOrderJourneyEnabled && atOnceProductFilters"
            :available-order-journeys="availableOrderJourneys"
            :is-mobile-viewport="isMobileViewport"
            :filters="atOnceProductFilters"
            @onSelect="goToProducts"
        />
        <PreOrderOrderJourneyMenu
            v-if="preOrderOrderJourneyEnabled && preOrderProductFilters"
            :available-order-journeys="availableOrderJourneys"
            :is-mobile-viewport="isMobileViewport"
            :filters="preOrderProductFilters"
            @onSelect="goToProducts"
        />
        <CustomizationDropdownMenu v-if="canOrderCustomisedProducts" />
        <UserProfileMenu
            :mobile="true"
            @closeMenu="$emit('closeMenu')"
        />
    </div>
</template>

<script setup>
import AtOnceOrderJourneyMenu from '@/components/menu/customer/AtOnceOrderJourneyMenu.vue';
import CustomizationDropdownMenu from '@/components/menu/customer/CustomizationDropdownMenu.vue';
import PreOrderOrderJourneyMenu from '@/components/menu/customer/PreOrderOrderJourneyMenu.vue';
import UserProfileMenu from '@/components/menu/user-profile-menu/UserProfileMenu.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { getCurrentBasket } from '@/modules/baskets/baskets.api.js';
import router from '@/router/router.js';
import store from '@/store/store.js';
import { Const } from '@/utils/constants';
import { computed } from 'vue';

defineEmits([
    'closeMenu',
]);

const availableOrderJourneys = computed(() => {
    const orderJourneys = store.getters.availableOrderJourneys.slice();

    if (canUseOffers.value) {
        orderJourneys.push({
            value: Const.ORDER_JOURNEY.PRE_ORDER,
            translationKey: 'orderJourney.preOrder',
            isOffer: true,
        });
    }

    return orderJourneys;
});

const atOnceProductFilters = computed(() => getStoredFilters(store.getters.atOnceProductFilters));
const preOrderProductFilters = computed(() => getStoredFilters(store.getters.preOrderProductFilters));
const atOnceOrderJourneyEnabled = computed(() => availableOrderJourneys.value.some(({ value }) => value === Const.ORDER_JOURNEY.AT_ONCE));
const preOrderOrderJourneyEnabled = computed(() => availableOrderJourneys.value.some(({ value }) => value === Const.ORDER_JOURNEY.PRE_ORDER));
const canUseOffers = computed(() => store.getters.getSubsidiarySettings.offersEnabled && store.getters.getUserCan(Const.PERMISSION_CAN_USE_OFFERS));
const canOrderCustomisedProducts = store.getters.getUserCan(Const.PERMISSIONS.CAN_ORDER_CUSTOMIZED_PRODUCTS) && store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER;
const isMobileViewport = computed(() => store.getters.isMobileViewport);
const getFilterParams = filter => filter ? { filters: `${filter.group}:${filter.id}` } : null;
const getStoredFilters = storedFilters => {
    const filters = {
        productTypes: [],
        assortments: [],
        seasons: [],
    };

    if (storedFilters) {
        const productTypeMap = {
            3100: 'general.categoryFootwear',
            3200: 'general.categoryApparel',
            3300: 'general.categoryAccessories',
        };

        filters.productTypes = Object.keys(storedFilters.productTypes).map(key => ({
            id: key.toString(),
            label: productTypeMap[key],
        }));

        filters.assortments = storedFilters.brands.A
            ? Object.keys(storedFilters.brands.A).map(key => ({
                id: `A|${key}`,
                label: `segmentsCategory.${key}`,
            }))
            : [];

        filters.seasons = storedFilters.seasons.slice(0, 4).map(season => ({
            id: season,
            label: season,
        }));
    }

    return filters;
};

const goToProducts = async ({ orderJourney, filter }) => {
    const basketInStore = store.getters.getCurrentBasket || store.getters.customizedBasket;
    const isCustomizedOrder = basketInStore.orderType === Const.ORDER_TYPE.CUSTOMIZED_ORDER;
    const orderJourneyHasChanged = basketInStore.orderJourney !== orderJourney || isCustomizedOrder;

    try {
        const { data: basket } = orderJourneyHasChanged ? await getCurrentBasket({ orderJourney }) : { data: store.getters.getCurrentBasket };

        if (orderJourneyHasChanged) {
            await store.dispatch('updateBasket', basket);
        }

        await router.push({
            path: `/orders/${basket.no}`,
            query: getFilterParams(filter),
        });
    } catch (error) {
        bugsnag.notify(error);
    }
};

</script>
