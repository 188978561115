import api, { createTransformingApiInstance } from '@/api/api.js';

export function getAddressItemCollectionForSearch(payload) {
    const { customerNo, addressNo, season, query, partnerNo } = payload;

    return api.get(
        `itemCollection/getAddressItemCollectionForSearch/${customerNo}/${addressNo}/${season}`,
        {
            params: {
                query,
                partnerNo,
            },
        },
    );
}

export function getFashionContractSeasons(customerNo, params) {
    const transformingApi = createTransformingApiInstance(); // Used here due to circular dependency issue

    return transformingApi.get(`customers/${customerNo}/fashion-contract-seasons`, {
        params,
    });
}
