<template>
    <div
        v-if="state.isLegacyBrowser && state.warningVisible"
        class="flex items-center bg-yellow-lightest text-primary text-sm leading-6 font-bold py-4 px-4 md:px-8 print:hidden"
    >
        <span class="text-left md:text-center mr-2 w-full">
            {{ $t('general.legacyBrowserWarning') }}
        </span>
        <ButtonPlain
            class="ml-auto"
            @click.native="close"
        >
            <i class="fa fa-remove fa-lg" />
        </ButtonPlain>
    </div>
    <NewsSlider
        v-else
        :key="uniqueUserKey"
    />
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import NewsSlider from '@/modules/news-alerts/viewer/NewsSlider.vue';
import { isLegacyBrowser } from '@/utils/generic.js';
import { computed, onMounted, reactive } from 'vue';
import store from '@/store/store.js';

const state = reactive({
    isLegacyBrowser: false,
    warningVisible: true,
});

const uniqueUserKey = computed(() => `${store.state.subsidiary.id}-${store.getters.username}`);

const close = () => {
    state.warningVisible = false;
};

onMounted(() => {
    state.isLegacyBrowser = isLegacyBrowser();
});

</script>
