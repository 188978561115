<template>
    <div class="text-base">
        <ModalDialog
            v-if="state.dialogVisible"
            :closable="false"
        >
            <template #header-title>
                {{ $t('adminBlogs.newsAlertAcknowledgmentDialog.title') }}
            </template>
            <template #body>
                <ul class="flex flex-col gap-4">
                    <li
                        v-for="(newsAlert, index) in state.newsAlerts"
                        :key="newsAlert.id"
                        class="flex flex-col gap-4 border border-grey rounded-md p-4"
                    >
                        <ButtonPlain
                            class="justify-between w-full"
                            @click.native="toggleExpanded(index)"
                        >
                            <span>
                                {{ newsAlert.title }}
                                <i
                                    v-if="newsAlert.read"
                                    class="fa fa-check text-green-dark ml-4"
                                />
                            </span>
                            <i :class="['fa', newsAlert.expanded ? 'fa-minus' : 'fa-plus']" />
                        </ButtonPlain>
                        <div
                            v-show="newsAlert.expanded"
                            v-html="newsAlert.content"
                        />
                    </li>
                </ul>
            </template>
            <div class="action-buttons">
                <Button
                    :text="$t('general.buttonOk')"
                    :disabled="!canAcknowledge"
                    @click.native="confirm"
                />
            </div>
        </ModalDialog>
    </div>
</template>

<script setup>
import Button from '@/components/button/Button.vue';
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { acknowledgeNewsAlert, getPosts } from '@/modules/news-alerts/viewer/viewer.blog.api.js';
import { persistState } from '@/store/store-helpers.js';
import { Const } from '@/utils/constants.js';
import { computed, reactive } from 'vue';

const state = reactive({
    dialogVisible: false,
    newsAlerts: [],
    submitting: false,
});

const newsAlertsToAcknowledgeOnce = computed(() => state.newsAlerts.filter(({ user_acknowledgement_requirement }) => user_acknowledgement_requirement === Const.NEWS_ALERT_ACKNOWLEDGEMENT_TYPE.ONCE));
const allNewsAlertsRead = computed(() => state.newsAlerts.every(({ read }) => read));
const canAcknowledge = computed(() => !state.submitting && allNewsAlertsRead.value);

const closeDialog = () => {
    state.dialogVisible = false;
};

const confirm = async () => {
    state.submitting = true;

    if (newsAlertsToAcknowledgeOnce.value.length > 0) {
        await acknowledgeNewsAlerts();
    }

    persistState('newsAlertsAcknowledgedPerSession', true);
    closeDialog();

    state.submitting = false;
};

const toggleExpanded = index => {
    state.newsAlerts[index].expanded = !state.newsAlerts[index].expanded;

    if (state.newsAlerts[index].expanded && !state.newsAlerts[index].read) {
        state.newsAlerts[index].read = true;
    }
};

const acknowledgeNewsAlerts = async () => {
    try {
        await Promise.all(newsAlertsToAcknowledgeOnce.value.map(({ id }) => acknowledgeNewsAlert(id)));
    } catch (error) {
        bugsnag.notify(error);
    }
};

const fetchNewsAlertsRequiringAcknowledgement = async () => {
    try {
        const { data } = await getPosts({ requireAcknowledgement: true });

        state.newsAlerts = data.data.map(newsAlert => ({
            ...newsAlert,
            content: newsAlert.content.replaceAll('\n', '<br />'),
            expanded: false,
            read: false,
        }));
        state.dialogVisible = data.data.length > 0;
    } catch (error) {
        bugsnag.notify(error);
    }
};

fetchNewsAlertsRequiringAcknowledgement();

</script>
