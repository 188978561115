<template>
    <div class="flex h-[6.5rem] border-b border-secondary-lightest">
        <div
            class="main-header-top flex gap-4 w-full 2xl:mx-auto 2xl:container"
            :class="{ 'is-collapsed': menuCollapsed, 'is-searching': isSearching, 'bg-red': isProductionSap }"
        >
            <div
                class="branding flex-none"
                :class="menuCollapsed ? 'flex' : 'hidden sm:flex'"
            >
                <div class="flex flex-col gap-1 items-center">
                    <router-link
                        class="branding-logo"
                        :title="$t('general.homePage')"
                        to="/"
                    >
                        <AsicsLogo />
                    </router-link>
                    <SubsidiarySelector v-if="!isCustomer" />
                </div>
            </div>

            <ButtonPlain
                v-if="canSearchProducts"
                class="menu-btn btn-close-search"
                @click.native="toggleSearchState"
            >
                <i class="fa fa-arrow-left fa-lg" />
            </ButtonPlain>

            <AdminMenu
                v-if="isAdmin"
                class="primary-navigation"
                @closeMenu="collapseMenu"
            />
            <InternalUserMenu
                v-if="isSalesRep"
                :subsidiary-settings="subsidiarySettings"
                class="primary-navigation"
                @closeMenu="collapseMenu"
            />
            <CustomerMenu
                v-if="isCustomer"
                class="primary-navigation"
                @closeMenu="collapseMenu"
            />

            <div
                v-if="canSearchProducts"
                class="flex items-center w-full sm:pl-8 season-search"
            >
                <SeasonSearch
                    :ship-to-address="shipToAddress"
                    :customer-no="customerNo"
                    :is-sales-rep="isSalesRep"
                    :is-customer="isCustomer"
                />
            </div>

            <MiniBasket
                v-if="canSeeMiniBasket"
                :key="basket.no"
                :basket="basket"
                :delivery-addresses="deliveryAddresses"
                class="mini-basket"
            />

            <CustomerLogo v-if="isCustomer" />

            <div
                v-show="menuCollapsed"
                class="secondary-navigation gap-2 md:gap-0 ml-auto pr-8"
            >
                <div
                    v-if="canSearchProducts"
                    class="menu-btn-container hidden-on-desktop"
                >
                    <button
                        class="menu-btn btn-open-search"
                        @click="toggleSearchState"
                    >
                        <i class="fa fa-search fa-lg" />
                    </button>
                </div>

                <NotificationsMenu />

                <UserProfileMenu />

                <div class="menu-btn-container hidden-on-desktop ml-auto">
                    <button
                        class="menu-btn btn-toggle-navbar"
                        @click="openMenu"
                    >
                        <i class="fa fa-bars" />
                    </button>
                </div>
            </div>

            <SupportChat v-if="supportChatEnabled" />

            <LegalAgreementModal
                :show-modal="showLegalAgreementModal"
                :current-approval="currentApproval"
                @close="closeAgreementModal"
            />

            <UserMfaEmailSetupModal
                v-if="showUserMfaEmailDialog"
                :username="username"
                :mfa-email="mfaEmail"
            />
        </div>
    </div>
</template>

<script>
import AsicsLogo from '@/components/asics-logo/AsicsLogo.vue';
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import AdminMenu from '@/components/menu/admin/AdminMenu.vue';
import CustomerMenu from '@/components/menu/customer/CustomerMenu.vue';
import SubsidiarySelector from '@/components/menu/SubsidiarySelector.vue';
import MiniBasket from '@/modules/baskets/mini-basket/MiniBasket.vue';
import UserProfileMenu from '@/components/menu/user-profile-menu/UserProfilleMenu.vue';
import SupportChat from '@/components/menu/SupportChat.vue';
import SeasonSearch from '@/components/search/SeasonSearch.vue';
import CustomerLogo from '@/modules/admin/customers/customer/logo/CustomerLogo.vue';
import LegalAgreementModal from '@/modules/admin/customers/LegalAgreementModal.vue';
import _MainMenuEventBus from '@/components/menu/_MainMenuEventBus.js';
import NotificationsMenu from '@/modules/notifications/navigation-menu/NotificationsMenu.vue';
import { Const } from '@/utils/constants.js';
import { emitter } from '@/utils/event-emitter.js';
import InternalUserMenu from '@/components/menu/internal-user/InternalUserMenu.vue';
import UserMfaEmailSetupModal from '@/components/user-mfa-email/UserMfaEmailSetupModal.vue';

export default {
    name: 'MainMenu',

    components: {
        SubsidiarySelector,
        UserMfaEmailSetupModal,
        AsicsLogo,
        ButtonPlain,
        MiniBasket,
        InternalUserMenu,
        LegalAgreementModal,
        AdminMenu,
        SupportChat,
        CustomerMenu,
        UserProfileMenu,
        SeasonSearch,
        CustomerLogo,
        NotificationsMenu,
    },

    data() {
        return {
            menuCollapsed: true,
            isSearching: false,
            showLegalAgreementModal: false,
            shouldUseCustomizedBasket: false,
            Const,
        };
    },

    computed: {
        userType() {
            return this.$store.getters.getUserType;
        },

        isAdmin() {
            return this.userType === Const.ACCOUNT_TYPE_ADMIN;
        },

        isSalesRep() {
            return this.userType === Const.ACCOUNT_TYPE_SALES_REP;
        },

        isCustomer() {
            return this.userType === Const.ACCOUNT_TYPE_CUSTOMER;
        },

        customerNo() {
            return this.$store.getters.getBasketOrRealCustomerNo;
        },

        rights() {
            return this.$store.getters.getRights;
        },

        subsidiarySettings() {
            return this.$store.getters.getSubsidiarySettings;
        },

        username() {
            return this.$store.getters.username;
        },

        mfaEmail() {
            return this.$store.getters.getUserMfaEmail;
        },

        isLegalApproverForCustomer() {
            return this.$store.getters.isLegalApproverForCustomer;
        },

        currentApproval() {
            return this.$store.getters.getLegalApproval;
        },

        showUserMfaEmailDialog() {
            return !this.isAdmin && this.$store.state.subsidiary.basketMfaEnabled && !this.mfaEmail;
        },

        supportChatEnabled() {
            return this.$store.state.subsidiary.hasSupportChat;
        },

        deliveryAddresses() {
            return this.$store.getters.customerDeliveryAddresses[this.customerNo] || [];
        },

        shipToAddress() {
            return this.deliveryAddresses.find(address => address.no === this.shipToAddressNo);
        },

        shipToAddressNo() {
            if (this.basket?.shipTo) {
                return this.basket.shipTo.no;
            }

            return this.defaultShipToAddressNo;
        },

        defaultShipToAddressNo() {
            const address = this.deliveryAddresses.find(address => address != null);

            return address ? address.no : null;
        },

        basket() {
            if (this.shouldUseCustomizedBasket && this.$store.getters.customizedBasket) {
                return this.$store.getters.customizedBasket;
            } else if (!this.shouldUseCustomizedBasket && this.$store.getters.getCurrentBasket) {
                return this.$store.getters.getCurrentBasket;
            }

            return null;
        },

        isCustomerContext() {
            return this.isSalesRep ? Boolean(this.$route.params?.basketNo) && this.$route.name !== 'offerHistory' : this.isCustomer;
        },

        canSeeMiniBasket() {
            return !this.isAdmin && Boolean(this.basket);
        },

        canSearchProducts() {
            return this.isCustomerContext && this.subsidiarySettings.showSearchAcrossSeasons && this.basket?.orderType === Const.ORDER_TYPE.SALES_ORDER
                && Boolean(this.customerNo) && Boolean(this.shipToAddress);
        },

        isAlreadyApproved() {
            return Boolean(this.$store.getters.getLegalApproval.customerNo);
        },

        isProductionSap() {
            return this.$store.getters.isProductionSap; // Used only during development with SAP production credentials
        },
    },

    watch: {
        $route() {
            this.collapseMenu();
            this.onLocationHashChange();
        },
    },

    async created() {
        _MainMenuEventBus.$on('openAgreementModal', this.openAgreementModal);

        // check if approver must approve in this window (exclude policy-viewing windows)
        const showLegalAgreementModal = this.isLegalApproverForCustomer && !/policy\//.test(this.$route.fullPath);

        this.showLegalAgreementModal = showLegalAgreementModal && !this.isAlreadyApproved;
        this.shouldUseCustomizedBasket = this.qualifiesAsCustomizedOrder();
    },

    beforeDestroy() {
        _MainMenuEventBus.$off('openAgreementModal', this.openAgreementModal);
    },

    methods: {
        onLocationHashChange() {
            this.shouldUseCustomizedBasket = this.qualifiesAsCustomizedOrder();
        },

        qualifiesAsCustomizedOrder() {
            return this.$route.path.startsWith(Const.PRODUCT_CUSTOMIZATION_BASE_PATH) || Boolean(this.$route.query.isCustomizedOrder);
        },

        closeAgreementModal() {
            this.showLegalAgreementModal = false;
        },

        toggleSearchState() {
            this.isSearching = !this.isSearching;
        },

        openAgreementModal() {
            this.showLegalAgreementModal = true;
        },

        openMenu() {
            this.menuCollapsed = false;

            emitter.$emit('mobileMenuExpanded', !this.menuCollapsed);
        },

        collapseMenu() {
            this.menuCollapsed = true;

            emitter.$emit('mobileMenuExpanded', !this.menuCollapsed);
        },
    },
};
</script>

<style lang="scss" scoped>
@use '~@variables' as variables;

.main-header-top {
    .btn-toggle-navbar,
    .btn-open-search {
        display: flex;
        align-items: center;
        justify-content: center;

        @media #{variables.$md-and-up} {
            display: none;
        }
    }

    .btn-close-search {
        display: none;
    }

    .branding {
        @apply items-center pl-8;

        @media #{variables.$md-and-up} {
            min-width: 15rem;
            padding-left: 2rem;
        }
    }

    .primary-navigation {
        @apply absolute top-[6.5rem] md:grid md:grid-flow-col md:auto-cols-fr w-full h-screen md:h-full divide-y divide-secondary-lightest md:divide-none bg-white z-10;
    }

    .secondary-navigation {
        display: flex;
        flex-direction: row;

        .menu-title,
        .menu-linked-title {
            padding: 1rem;
            white-space: nowrap;
        }
    }

    .season-search {
        display: none;
    }

    .language-select {
        width: max-content;
        display: none;
    }

    @media #{variables.$md-and-up} {
        .primary-navigation {
            flex: auto;
            order: unset;
            position: unset;
        }

        .season-search {
            display: flex;
        }

        .language-select {
            display: block;
        }
    }

    @media #{variables.$xl-and-up} {
        .season-search {
            display: flex;
        }
    }

    &.is-collapsed {
        // When header is collapsed we still want to show the navigation
        .primary-navigation {
            display: none;

            @media #{variables.$md-and-up} {
                display: flex;
            }
        }
    }

    // Showing and hiding of elements when the search is active
    &.is-searching {
        .branding,
        .branding-logo,
        .btn-toggle-navbar,
        .primary-navigation,
        .secondary-navigation,
        .mini-basket {
            display: none;
        }

        .btn-close-search {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 0.5rem 0 2rem;
        }

        .season-search {
            display: flex;
            padding-right: 2rem;
        }
    }
}

.menu-btn-container,
:deep(.menu-btn-container) {
    @apply flex items-center justify-center h-full md:min-w-[6.5rem] md:border-l border-secondary-lightest hover:cursor-pointer;

    &.hidden-on-desktop {
        @apply md:hidden;
    }

    .menu-btn {
        @apply flex items-center justify-center rounded-full border-transparent bg-grey-light w-[3.7rem] h-[3.7rem] p-4;
    }
}
</style>
