<template>
    <ModalDialog
        v-show="showModal"
        size="small"
        @close="cancel"
    >
        <template #header-title>
            {{ $t('logout.title') }}
        </template>
        <template #body>
            {{ $t('logout.areYousure') }}
        </template>
        <div class="action-buttons">
            <Button
                kind="primary-alt"
                :text="$t('general.buttonNo')"
                @click.native.prevent="cancel"
            />
            <Button
                :text="$t('logout.buttonYes')"
                @click.native.prevent="confirm"
            />
        </div>
    </ModalDialog>
</template>

<script setup>
import Button from '@/components/button/Button.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { logOut } from '@/modules/unauthenticated/login/login.api.js';
import router from '@/router/router.js';
import store from '@/store/store.js';

defineProps({
    showModal: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits([
    'close',
]);

const cancel = () => {
    emit('close');
};

const redirect = async url => {
    try {
        await router.push(url);
    } catch (error) {
        bugsnag.notify(error);
    }
};

const confirm = async () => {
    try {
        const { data } = await logOut();

        // In case user was impersonated, current session is still active, so we just refresh auth user state and redirect
        if (data.authenticated) {
            emit('close');

            await store.dispatch('globalStore/setUpAuthenticatedState');
            await redirect('/');

            return;
        }

        await store.dispatch('globalStore/setUpUnauthenticatedState');
        await redirect('/authentication/login');
    } catch (error) {
        bugsnag.notify(error);
    }
};

</script>
