import api from '@/api/api.js';
import { getCustomerDistributionCenters } from '@/modules/admin/customers/customer/customer.api.js';
import { basketIsNonEditableFOC } from '@/modules/baskets/basket.service.js';
import { getCustomerAddresses } from '@/modules/customer/customer.api.js';
import { markNotificationAsRead } from '@/modules/notifications/notifications.api.js';
import { getProductFilters } from '@/modules/plp/plp.api.js';
import { Const } from '@/utils/constants.js';

export default {
    async setNotificationRead({ commit }, id) {
        await markNotificationAsRead(id, { read: true });

        commit('setNotificationRead', id);
    },

    async findOrCreateBasketIfNotEditableByUser({ dispatch, getters }) {
        const basket = getters.getCurrentBasket;

        if (!basket) {
            return;
        }

        if (basket.status === Const.BASKET_STATUS.PENDING && !basketIsNonEditableFOC(basket, getters.getUserType)) {
            return;
        }

        if (basket.offerId) {
            return;
        }

        await dispatch('fetchCustomerBasketByAddressNo', {
            addressNo: basket.shipToNo,
            distributionCenterNo: basket.distributionCenterNo,
            orderJourney: basket.orderJourney,
        });
    },

    async fetchCustomerBasketByAddressNo({ dispatch, getters }, payload) {
        const customerNo = getters.getBasketOrRealCustomerNo;
        const { addressNo, distributionCenterNo, orderJourney, isCustomized = false } = payload;

        if (!customerNo) {
            throw new Error('Cannot fetch basket without customer number');
        }

        if (!addressNo) {
            throw new Error('Cannot fetch basket without address number');
        }

        const { data: customerBasket } = await api.get(`basket/find-or-create-basket/${customerNo}/${addressNo}`, {
            params: {
                distributionCenterNo,
                orderJourney,
                isCustomized,
            },
        });

        await dispatch('updateBasket', customerBasket);
    },

    async updatePreferredSizeChart({ commit }, size) {
        await api.patch('userCtrl/preferences/size-chart', { size });

        commit('updatePreferredSizeChart', size);
    },

    async updatePreferredPriceDisplay({ commit }, priceDisplayMode) {
        await api.patch('userCtrl/preferences/price-display', { priceDisplayMode });

        commit('updatePreferredPriceDisplay', priceDisplayMode);
    },

    async updateCustomerDeliveryAddresses({ commit, getters }, customerNo) {
        const customerDeliveryAddresses = getters.customerDeliveryAddresses[customerNo] ?? [];

        if (customerDeliveryAddresses.length) {
            return;
        }

        const { data } = await getCustomerAddresses(customerNo, { excludeDoors: true });

        commit('updateCustomerDeliveryAddresses', { customerNo, addresses: data });
    },

    async updateCustomerDistributionCenters({ commit, getters }, customerNo) {
        const customerDistributionCenters = getters.customerDistributionCenters[customerNo] ?? [];

        if (customerDistributionCenters.length) {
            return;
        }

        const { data } = await getCustomerDistributionCenters(customerNo);

        commit('updateCustomerDistributionCenters', { customerNo, distributionCenters: data.data });
    },

    async updateProductFilters({ commit }, basket) {
        const { data: { data: filters } } = await getProductFilters({ basketNo: basket.no });

        commit('updateProductFilters', {
            filters,
            orderJourney: basket.orderJourney,
        });
    },

    async updateBasket({ dispatch, commit, getters }, basket) {
        const customerNo = basket.customerNo;
        const canUseDistributors = getters.getSubsidiarySettings.usePartnerDistributors;
        const actions = [];

        if (basket.orderType === Const.ORDER_TYPE.CUSTOMIZED_ORDER) {
            if (basket.no !== getters.customizedBasket?.no) {
                actions.push(() => dispatch('updateCustomerDeliveryAddresses', customerNo));
            }

            if (canUseDistributors && basket.no !== getters.customizedBasket?.no) {
                actions.push(() => dispatch('updateCustomerDistributionCenters', customerNo));
            }

            await Promise.all(actions.map(action => action()));

            commit('updateCustomizedBasket', basket);
        } else {
            if (basket.no !== getters.getCurrentBasket?.no) {
                actions.push(() => dispatch('updateCustomerDeliveryAddresses', customerNo), () => dispatch('updateProductFilters', basket));
            }

            if (canUseDistributors && basket.no !== getters.getCurrentBasket?.no) {
                actions.push(() => dispatch('updateCustomerDistributionCenters', customerNo));
            }

            await Promise.all(actions.map(action => action()));

            commit('updateBasket', basket);
        }
    },
};
