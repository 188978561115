<template>
    <div class="w-full border-y md:border-y-0 my-6 md:my-0">
        <CustomerFooterMenu
            v-if="isCustomer"
            class="footer-menu"
        />
    </div>
</template>

<script setup>
import CustomerFooterMenu from '@/components/footer-menu/customer/CustomerFooterMenu.vue';
import { Const } from '@/utils/constants.js';
import { computed } from 'vue';
import store from '@/store/store.js';

const isCustomer = computed(() => store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER);

</script>

<style scoped>
.footer-menu {
    @apply flex flex-col md:grid md:grid-flow-col md:gap-x-10 list-none divide-y divide-primary md:divide-none;
}

.footer-menu :deep() > menu {
    @apply block;
}
</style>
