<template>
    <ModalDialog
        size="small"
        :closable="false"
    >
        <template #header-title>
            {{ $t('sessionEndedModal.title') }}
        </template>
        <template #message>
            {{ $t('sessionEndedModal.description') }}
        </template>
        <div class="action-buttons">
            <Button
                :text="$t('general.buttonLogIn')"
                @click.native="redirectToLogin"
            />
        </div>
    </ModalDialog>
</template>

<script setup>
import Button from '@/components/button/Button.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import router from '@/router/router.js';

const redirectToLogin = async () => {
    try {
        await router.push('/authentication/login');
    } catch (error) {
        bugsnag.notify(error);

        await router.go(0);
    }
};

</script>
