<template>
    <ModalDialog
        v-if="data"
        @close="closeModalInstance"
    >
        <template #header-title>
            <span class="modal-title text-uppercase">
                {{ $t('general.addressScoring') }}
            </span>
            <div>
                {{ selectedAddress.no }} - {{ selectedAddress.name }}
                <br>
                {{ selectedAddress.city }} {{ selectedAddress.postCode }} {{ selectedAddress.countryCode }}
            </div>
        </template>
        <template #body>
            <div
                v-for="rating in data"
                :key="rating.level"
            >
                <h3
                    class="rating-level mb-2"
                >
                    {{ $t(`segmentsLevels.${rating.level}`) }}
                </h3>
                <div
                    v-for="sport in rating.sports"
                    :key="sport.descriptionKey"
                    class="rating-sports"
                >
                    <div class="flex flex-col">
                        <p class="font-bold mt-4">
                            {{ $t(`segmentsDescriptions.${sport.descriptionKey}`) }}
                        </p>
                        <p
                            v-if="showSdpGroupValidFrom(sport.validFrom)"
                            class="flex items-center gap-2 italic text-sm"
                        >
                            <span>{{ $t('general.labelFrom') }}</span>
                            <span>{{ formatDate(sport.validFrom) }}</span>
                        </p>
                        <p
                            v-if="showSdpGroupValidTo(sport.validTo)"
                            class="flex items-center gap-2 italic text-sm"
                        >
                            <span>{{ $t('general.labelUntil') }}</span>
                            <span>{{ formatDate(sport.validTo) }}</span>
                        </p>
                    </div>
                </div>
            </div>
        </template>
        <div class="action-buttons">
            <Button
                :text="$t('general.close')"
                @click.native="closeModalInstance"
            />
        </div>
    </ModalDialog>
</template>

<script>
import Button from '@/components/button/Button.vue';
import { getCustomerAddressRatings } from '@/modules/admin/customers/customer/customer.api.js';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { localisedDateFormat, toDatabaseDateFormat } from '@/utils/generic';

export default {
    name: 'CustomerOverviewSDP',

    components: {
        Button,
        ModalDialog,
    },

    props: {
        selectedAddress: {
            type: Object,
            required: true,
        },
    },

    emits: [
        'close',
    ],

    data() {
        return {
            data: null,
        };
    },

    watch: {
        selectedAddress() {
            this.loadData();
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        closeModalInstance() {
            this.$emit('close');
        },

        async loadData() {
            try {
                this.data = await getCustomerAddressRatings(this.selectedAddress.customerNo, this.selectedAddress.no, {
                    distributionChannel: this.selectedAddress.channel,
                });
            } catch (error) {
                bugsnag.notify(error);
            }
        },

        formatDate(date) {
            return localisedDateFormat(new Date(date), this.$store.getters.userLocale);
        },

        showSdpGroupValidFrom(validFrom) {
            return toDatabaseDateFormat(new Date(), this.$store.state.subsidiary.timezone) < validFrom;
        },

        showSdpGroupValidTo(validTo) {
            return validTo !== '9999-12-31';
        },
    },
};
</script>

<style scoped>
.rating-level {
    padding: 5px;
    color: white;
    width: 100%;
    text-align: center;
    background-color: #0B1D4F;
}

.rating-sports {
    display: inline-flex;
    flex: 15;
    width: 15%;
    text-align: justify;
    margin-left: 5%;
}
</style>
