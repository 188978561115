import api from '@/api/api.js';

export function getTrainings() {
    return api.get('training-material/trainings');
}

export function getTraining(id) {
    return api.get(`training-material/trainings/${id}`);
}

export function createTraining(payload) {
    return api.post('training-material/trainings', payload);
}

export function updateTraining(id, payload) {
    return api.patch(`training-material/trainings/${id}`, payload);
}

export function deleteTraining(id) {
    return api.delete(`training-material/trainings/${id}`);
}

export function getTrainingAvailability() {
    return api.get('training-material/availability');
}
