<template>
    <ul
        :class="['menu', {'flex': secondary, 'absolute top-0 md:relative h-full': expanded}]"
        role="tree"
        @mouseenter="expandMenu"
        @mouseleave="collapseMenu"
    >
        <li
            role="treeitem"
            tabindex="0"
            :aria-expanded="expanded"
            class="w-full"
        >
            <div
                v-if="$slots.title"
                class="menu-title"
                :class="expanded ? expandedMenuColors : 'justify-between'"
                @click="toggleMenu"
            >
                <i
                    v-if="isMobileViewport"
                    :class="['fa', expanded ? 'fa-chevron-left' : 'fa-chevron-right order-last']"
                />
                <slot name="title" />
            </div>

            <div
                v-if="$slots['secondary-menu-title']"
                :class="['secondary-menu-title', {'bg-secondary text-primary': expanded}]"
            >
                <slot name="secondary-menu-title" />
            </div>

            <div
                v-if="$slots['menu-title-link']"
                class="menu-title-link"
                :class="{[expandedMenuColors]: expanded}"
                @click="collapseMenu(true)"
            >
                <slot name="menu-title-link" />
            </div>
            <div
                v-if="$slots.dropdown"
                class="relative"
            >
                <ul
                    v-show="expanded"
                    role="group"
                    class="sub-menu"
                    :class="[secondary ? 'right-0 rounded-tl-xl' : 'left-0 rounded-tr-xl', dropdownCss]"
                >
                    <slot name="dropdown" />
                </ul>
            </div>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'DropDownMenu',

    props: {
        secondary: {
            type: Boolean,
            default: false,
        },

        expandedMenuColors: {
            type: String,
            default: 'bg-secondary text-white',
        },

        dropdownCss: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            expanded: false,
        };
    },

    computed: {
        isMobileViewport() {
            return this.$store.getters.isMobileViewport;
        },
    },

    watch: {
        $route() {
            this.collapseMenu();
        },
    },

    methods: {
        collapseMenu(force = false) {
            if (this.isMobileViewport && !force) {
                return;
            }

            this.expanded = false;
        },

        expandMenu() {
            if (this.isMobileViewport) {
                return;
            }

            this.expanded = true;
        },

        toggleMenu() {
            if (!this.isMobileViewport) {
                return;
            }

            this.expanded = !this.expanded;
        },
    },
};
</script>

<style lang="scss" scoped>
.menu {
    @apply w-full md:h-full md:w-max whitespace-nowrap capitalize list-none m-0 p-0 bg-white;

    li {
        @apply md:h-full;

        > a {
            @apply block;
        }
    }

    .menu-title,
    .menu-title-link {
        @apply flex gap-x-8 items-center w-full h-full min-h-[4.4rem] font-bold cursor-pointer px-8;

        > a {
            @apply flex items-center w-full h-full;
        }
    }

    .secondary-menu-title {
        @apply flex items-center w-full h-full font-bold cursor-pointer hover:bg-secondary;
    }

    [aria-expanded='true'] .menu-title-link a {
        @apply text-white;
    }

    .divider {
        @apply border-t border-secondary-lightest;
    }

    .sub-menu {
        > li {
            @apply px-8;
        }

        @apply flex flex-col gap-y-4 font-normal bg-white absolute border-t border-grey-light rounded-br-xl rounded-bl-xl overflow-auto w-full md:w-auto min-w-[20rem] h-screen md:h-auto max-h-[calc(100vh_-_7rem)] py-8 px-0 top-0 z-[100] md:shadow-[0_0.4rem_0.4rem_rgba(0,0,0,0.25)];
    }
}
</style>
