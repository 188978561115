import { guards } from '@/router/middleware/middleware-runner.js';
import {
    authenticationMiddleware,
    authorizationMiddleware,
    currentBasketMiddleware,
    orderJourneyMiddleware,
    pageTitleMiddleware,
    redirectMiddleware,
    subsidiaryMiddleware,
} from '@/router/middleware/middlewares.js';
import routes from '@/router/routes/routes.js';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes,
});

// NOTE: the order in which middlewares are processed is important; `subsidiaryMiddleware` should always run first
router.beforeEach(guards([
    subsidiaryMiddleware,
    authenticationMiddleware,
    authorizationMiddleware,
    redirectMiddleware,
    orderJourneyMiddleware,
    currentBasketMiddleware,
    pageTitleMiddleware,
]));

export default router;
