<template>
    <div
        v-if="posts.length"
        class="news-slider flex gap-x-8 sm:gap-x-8 items-center justify-center w-full bg-secondary-lightest text-primary text-sm leading-6 font-bold py-4 px-8 z-10 print:hidden"
    >
        <ButtonPlain
            v-if="showArrows"
            class="w-full max-w-[5rem]"
            @click.native="previous"
        >
            <i class="fa fa-chevron-left" />
        </ButtonPlain>
        <VueSlickCarousel
            v-bind="settings"
            ref="carousel"
            class="max-w-[28rem] sm:max-w-[94rem] w-full text-center"
        >
            <a
                v-for="post in posts"
                :key="post.id"
                :href="post.link"
            >
                {{ post.title }}
            </a>
        </VueSlickCarousel>
        <ButtonPlain
            v-if="showArrows"
            class="w-full max-w-[5rem]"
            @click.native="next"
        >
            <i class="fa fa-chevron-right" />
        </ButtonPlain>
    </div>
</template>

<script>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import VueSlickCarousel from 'vue-slick-carousel';
import { getHomepagePosts } from '@/modules/news-alerts/viewer/viewer.blog.api.js';

export default {
    name: 'NewsSlider',

    components: {
        ButtonPlain,
        VueSlickCarousel,
    },

    data() {
        return {
            posts: [],
            settings: {
                arrows: false,
                autoplay: true,
                autoplaySpeed: 10000,
                speed: 1000,
            },
        };
    },

    computed: {
        showArrows() {
            return this.posts.length > 1;
        },
    },

    created() {
        this.loadData();
    },

    methods: {
        async loadData() {
            try {
                const { data } = await getHomepagePosts();

                this.posts.push(...data.data);
            } catch (error) {
                bugsnag.notify(error);
            }
        },

        previous() {
            this.$refs.carousel.prev();
        },

        next() {
            this.$refs.carousel.next();
        },
    },
};
</script>

<style scoped>
:deep(.slick-list) {
    @apply overflow-hidden;
}

:deep(.slick-track) {
    @apply flex;
}
</style>
