<template>
    <div class="flex flex-col gap-4">
        <h1 class="heading-md sm:heading-lg">
            {{ $t('general.quickActions') }}
        </h1>
        <div class="grid grid-cols-2 sm:grid-cols-[repeat(auto-fit,minmax(26rem,1fr))] gap-8 sm:gap-12 items-center capitalize">
            <router-link
                to="/orders-overview/order-history"
                class="flex flex-col gap-4 items-center justify-center h-[10rem] p-4 sm:p-8 border rounded-3xl text-lg sm:text-xl font-bold"
            >
                <i class="fa fa-history" />
                <span class="text-center truncate w-full">{{ $t('footerMenu.orderHistory') }}</span>
            </router-link>
            <router-link
                v-if="preOrderFromTemplateEnabled"
                to="/orders-overview/pre-orders/pre-orders-from-template"
                class="flex flex-col gap-4 items-center justify-center h-[10rem] p-4 sm:p-8 border rounded-3xl text-lg sm:text-xl font-bold"
            >
                <i class="fa fa-shopping-cart" />
                <span class="text-center truncate w-full">{{ $t('offers.offers') }}</span>
            </router-link>
            <router-link
                v-if="oldDisputesEnabled"
                to="/orders-overview/disputes-old"
                class="flex flex-col gap-4 items-center justify-center h-[10rem] p-4 sm:p-8 border rounded-3xl text-lg sm:text-xl font-bold"
            >
                <i class="fa fa-comments" />
                <span class="text-center truncate w-full">{{ $t('customerMainMenu.disputes') }}</span>
            </router-link>
            <router-link
                v-if="canManageDisputes"
                class="flex flex-col gap-4 items-center justify-center h-[10rem] p-4 sm:p-8 border rounded-3xl text-lg sm:text-xl font-bold"
                to="/orders-overview/disputes/submitted"
            >
                <i class="fa fa-comments" />
                <span class="text-center truncate w-full">{{ $t('customerMainMenu.disputes') }}</span>
            </router-link>
            <router-link
                v-if="canUploadOrder"
                :to="`/customers/${currentBasket.customerNo}/orders/new?type=salesOrder`"
                class="flex flex-col gap-4 items-center justify-center h-[10rem] p-4 sm:p-8 border rounded-3xl text-lg sm:text-xl font-bold"
            >
                <i class="fa fa-upload" />
                <span class="text-center truncate w-full">{{ $t('customerOrders.menuImport') }}</span>
            </router-link>
            <router-link
                v-if="blogEnabled"
                to="/blog"
                class="flex flex-col gap-4 items-center justify-center h-[10rem] p-4 sm:p-8 border rounded-3xl text-lg sm:text-xl font-bold"
            >
                <i class="fa fa-bullhorn" />
                <span class="text-center truncate w-full">{{ $t('adminMainMenu.blog') }}</span>
            </router-link>
        </div>
    </div>
</template>

<script setup>
import store from '@/store/store.js';
import { Const } from '@/utils/constants.js';
import { computed } from 'vue';

const currentBasket = computed(() => store.getters.getCurrentBasket);
const preOrderFromTemplateEnabled = computed(() => store.getters.getSubsidiarySettings.offersEnabled);
const oldDisputesEnabled = computed(() => store.getters.getSubsidiarySettings.oldDisputesEnabled);
const blogEnabled = computed(() => store.state.subsidiary.blogEnabled);
const canManageDisputes = computed(() => store.getters.getUserCan(Const.PERMISSIONS.CAN_MANAGE_DISPUTES));
const canUploadOrder = computed(() => store.getters.getUserCan(Const.PERMISSION_CAN_UPLOAD_SALES_ORDER));

</script>
