import { setupAxios } from '@/api/api.js';
import { setupBugsnag } from '@/libs/bugsnag.js';
import { setupGtm } from '@/libs/google-tag-manager.js';
import { getFrontendThirdPartyConfigs } from '@/modules/unauthenticated/login/login.api.js';
import Vue from 'vue';
import router from '@/router/router.js';
import store from '@/store/store.js';
import i18n from '@/libs/i18n/i18n.js';
import { setupVeeValidate } from '@/libs/vee-validate.js';
import { setupVCalendar } from '@/libs/calendar.js';

import '@/styles/app.scss';
import App from '@/App.vue';

setupVeeValidate();
setupVCalendar();
setupAxios();

try {
    const { data: { bugsnag, gtmContainerId, customization, cspNonce, isProductionSap } } = await getFrontendThirdPartyConfigs();

    setupBugsnag(bugsnag);
    setupGtm(gtmContainerId, cspNonce);

    store.commit('setCustomizationConfig', customization);
    store.commit('setIsProductionSap', isProductionSap);
} catch (error) {
    console.error(error);
}

const app = new Vue({
    name: 'AppWrapper',

    router,

    store,

    i18n,

    render(createElement) {
        return createElement(App);
    },
});

app.$mount('#app');
