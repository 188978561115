<template>
    <div
        :class="[
            'menu-btn-container hover:!cursor-default',
            {'relative text-primary': !isMobileViewport && state.expanded, 'hover:bg-secondary hover:!cursor-pointer': !isMobileViewport && hasNotifications}
        ]"
        @mouseenter="expand"
        @mouseleave="collapse"
    >
        <button
            :class="[
                'relative flex items-center h-[3.7rem] menu-btn hover:!cursor-default',
                {'!bg-white': !isMobileViewport && state.expanded, 'hover:!cursor-pointer': !isMobileViewport && hasNotifications}
            ]"
            @click="toggleExpanded"
        >
            <span
                v-show="hasUnreadNotifications"
                class="absolute top-0 -right-2"
            >
                <span
                    v-show="state.indicateNewNotification"
                    class="animate-ping absolute top-0 right-0 h-full w-full rounded-full opacity-70 bg-red"
                />
                <span class="min-w-[1.7rem] h-[1.7rem] flex items-center justify-center text-white text-xs rounded-full p-2 leading-6 bg-red">
                    {{ unreadNotificationCount }}
                </span>
            </span>
            <i class="fa fa-lg fa-bell" />
        </button>

        <NotificationsDropDown
            v-show="state.expanded"
            :has-unread-notifications="hasUnreadNotifications"
            @collapse="collapse"
        />
    </div>
</template>

<script setup>
import { bugsnag } from '@/libs/bugsnag.js';
import { laravelEchoClient } from '@/libs/laravel-echo.js';
import NotificationsDropDown from '@/modules/notifications/navigation-menu/NotificationsDropDown.vue';
import { getNotifications } from '@/modules/notifications/notifications.api.js';
import store from '@/store/store.js';
import { computed, onBeforeUnmount, onMounted, reactive } from 'vue';

const state = reactive({
    expanded: false,
    indicateNewNotification: false,
});

const subsidiary = computed(() => store.getters.getSubsidiary);
const username = computed(() => store.getters.username.replaceAll('.', '#'));
const hasNotifications = computed(() => store.getters.notificationCount !== 0);
const hasUnreadNotifications= computed(() => store.getters.unreadNotificationCount !== 0);
const unreadNotificationCount = computed(() => store.getters.unreadNotificationCount);

const isMobileViewport = computed(() => store.getters.isMobileViewport);

const expand = () => {
    if (isMobileViewport.value || !hasNotifications.value) {
        return;
    }

    state.expanded = true;
};

const collapse = () => {
    state.expanded = false;
};

const toggleExpanded = () => {
    if (!isMobileViewport.value || !hasNotifications.value) {
        return;
    }

    state.expanded = !state.expanded;
};

const onNewNotification = notification => {
    store.commit('updateNotifications', { ...notification, read: false });

    state.indicateNewNotification = true;

    setTimeout(() => {
        state.indicateNewNotification = false;
    }, 5000);
};

const subscribeToPersonalNotifications = () => {
    laravelEchoClient.private(`user.${subsidiary.value}.${username.value}`).notification(onNewNotification);
};

const unsubscribeFromPersonalNotifications = () => {
    laravelEchoClient.leave(`user.${subsidiary.value}.${username.value}`);
};

const fetchNotifications = async () => {
    try {
        const { data } = await getNotifications();

        store.commit('setNotifications', data.notifications);
    } catch (error) {
        bugsnag.notify(error);
    }
};

onMounted(() => {
    subscribeToPersonalNotifications();
});

onBeforeUnmount(() => {
    unsubscribeFromPersonalNotifications();
});

fetchNotifications();
</script>
