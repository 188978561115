import api from '@/api/api.js';

export function updateSlide(id, formData) {
    return api.post(`homepage/slides/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function createNewSlide(formData) {
    return api.post('homepage/slides', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}

export function deleteSlide(id) {
    return api.delete(`homepage/slides/${id}`);
}

export function loadSlides() {
    return api.get('homepage/slides');
}

export function loadCards() {
    return api.get('homepage/getPresentationBlocks');
}

export function updateCard(formData) {
    return api.post('homepage/updatePresentationBlock', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}
