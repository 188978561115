<template>
    <DropDownMenu>
        <template #title>
            {{ $t('employeeMainMenu.orders') }}
        </template>
        <template #dropdown>
            <li>
                <router-link to="/pending-baskets">
                    {{ $t('employeeMainMenu.pendingOrders') }}
                </router-link>
            </li>
            <li>
                <router-link to="/orders-overview/order-history">
                    {{ $t('employeeMainMenu.orderHistory') }}
                </router-link>
            </li>
            <li v-if="canManageOffers">
                <router-link to="/orders-overview/offers">
                    {{ $t('offers.offerListing') }}
                </router-link>
            </li>
        </template>
    </DropDownMenu>
</template>

<script>
import DropDownMenu from '@/components/menu/DropDownMenu.vue';
import { Const } from '@/utils/constants.js';

export default {
    name: 'OrdersDropdownMenu',

    components: {
        DropDownMenu,
    },

    computed: {
        currentBasketNo() {
            return this.$store.getters.getCurrentBasket?.no;
        },

        canManageOffers() {
            return this.$store.getters.getUserCan(Const.PERMISSION_CAN_MANAGE_OFFERS);
        },
    },
};
</script>
