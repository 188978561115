import axios from 'axios';
import { requestInterceptor, responseSuccessInterceptor, responseErrorInterceptor } from '@/api/interceptors.js';
import { requestTransformInterceptor, responseTransformInterceptor } from '@/api/transformers.js';

const apiInstance = axios.create();

let transformingApiInstance;

apiInstance.defaults.headers.common.Accept = 'application/json';

export function setupAxios() {
    apiInstance.interceptors.request.use(requestInterceptor);
    apiInstance.interceptors.response.use(responseSuccessInterceptor, responseErrorInterceptor);
}

export function createCustomApiInstance(axiosConfig, interceptorConfig) {
    const options = Object.assign({
        transformRequest: false,
        transformResponse: false,
        wrappedResponse: true,
    }, interceptorConfig);

    const customApiInstance = apiInstance.create(axiosConfig);

    customApiInstance.interceptors.request.use(requestInterceptor);
    customApiInstance.interceptors.response.use(responseSuccessInterceptor, errorResponse => responseErrorInterceptor(errorResponse, options));

    if (options.transformRequest) {
        customApiInstance.interceptors.request.use(requestTransformInterceptor);
    }

    if (options.transformResponse) {
        customApiInstance.interceptors.response.use(response => responseTransformInterceptor(response, options));
    }

    return customApiInstance;
}

export function createTransformingApiInstance(axiosConfig) {
    if (!transformingApiInstance) {
        transformingApiInstance = createCustomApiInstance(axiosConfig, {
            transformRequest: true,
            transformResponse: true,
            wrappedResponse: false,
        });
    }

    return transformingApiInstance;
}

export default apiInstance;
