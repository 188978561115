<template>
    <div>
        <div
            v-if="isMobileViewport"
            class="absolute top-0 right-0 w-screen h-screen bg-black opacity-50 z-50"
            @click="collapse"
        />
        <div class="absolute top-0 md:top-[6.5rem] right-0 rounded-tl-xl rounded-br-xl rounded-bl-xl bg-white overflow-hidden w-full md:w-[40rem] z-[60] md:shadow-[0_0.4rem_0.4rem_rgba(0,0,0,0.25)]">
            <div
                v-if="isMobileViewport"
                class="flex items-center justify-between h-[6.5rem] p-8 text-white bg-primary"
            >
                <div class="flex items-center gap-4">
                    <button class="relative menu-btn bg-white text-primary">
                        <span
                            v-show="hasUnreadNotifications"
                            class="absolute top-0 right-0 w-[1.2rem] h-[1.2rem] text-center text-white bg-red border border-transparent rounded-full"
                        />
                        <i class="fa fa-lg fa-bell" />
                    </button>
                    <span class="font-bold">
                        {{ $t('notifications.title') }}
                    </span>
                </div>
                <i
                    class="fa fa-times fa-xl"
                    @click="collapse"
                />
            </div>
            <NotificationList
                :notifications="notifications"
                :shown-item-count="5"
                :can-show-more="true"
                @urlClick="collapse"
            >
                <template #no-results>
                    {{ $t('notifications.noResults') }}
                </template>
                <template #show-all>
                    <ButtonPlain
                        :text="$t('notifications.showAll')"
                        @click.native="showAll"
                    />
                </template>
            </NotificationList>
        </div>
    </div>
</template>

<script setup>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import NotificationList from '@/modules/notifications/partials/NotificationList.vue';
import router from '@/router/router.js';
import { computed } from 'vue';
import store from '@/store/store.js';

defineProps({
    hasUnreadNotifications: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits([
    'collapse',
]);

const notifications = computed(() => store.getters.notifications);
const isMobileViewport = computed(() => store.getters.isMobileViewport);

const collapse = () => {
    emit('collapse');
};

const showAll = async () => {
    collapse();

    try {
        await router.push('/notifications');
    } catch (error) {
        bugsnag.notify(error);
    }
};

</script>
