<template>
    <ModalDialog
        v-show="showModal"
        @close="onFormCancel"
    >
        <template #header-title>
            NOTICE OF NEW RETAILER TERMS AND CONDITIONS AND RELATED AGREEMENTS AND POLICIES
        </template>
        <template #body>
            <div class="text-grey-dark text-sm">
                <p>
                    ASICS America Corporation (“<b>ASICS</b>”) issued new ASICS <a
                        href="/policy/retailer-terms"
                        target="_blank"
                    >Authorized Retailer Terms and Conditions</a> effective July 1, 2021 (as same may be amended, restated, supplemented and otherwise modified from time to time by ASICS, “Terms”).
                    These Terms apply to Retailers of ASICS products in the United States of America and its territories. Please read the Terms carefully as they may affect Retailer’s legal rights
                    and responsibilities.
                </p>
                <p>
                    In an effort to serve our customers and Retailers better, and to ensure that our customers receive the genuine, quality products that they have come to expect from the ASICS brand
                    and its Retailers, ASICS’ prior terms and conditions with its Retailers have been replaced. ASICS provides new Terms to address growing needs of protecting the ASICS brands and
                    products, its sales channels, and Retailers. Please note that Retailer will be bound by the Terms upon whichever of the following occurs first: (a) Retailer confirming its
                    acceptance, as provided below; (b) ordering or purchasing ASICS product after receiving notice of the new Terms; and/or (c) selling ASICS product after receiving notice of the
                    new Terms.
                </p>
                <p>Some of the material provisions of the Terms include, and are not limited to:</p>
                <ul class="list-disc list-inside">
                    <li>
                        All orders placed (including, but not limited to, through ASICS’ B2B website, sales representatives, customer service, and EDI) and sales made shall be exclusively governed by
                        the Terms, except as otherwise provided therein.
                    </li>
                    <li>Retailers shall only purchase and sell ASICS products directly from ASICS and as approved by ASICS.</li>
                    <li>Retailers may only sell ASICS products to consumers of the products and not to resellers.</li>
                    <li>Retailers will not sell or ship any ASICS products outside of the United States and its territories.</li>
                    <li>
                        Retailers who want to sell ASICS products online, including through the Retailer’s own ecommerce site, must complete and submit the <a
                            :href="`/${subsidiary}/legal/online-seller-agreement`"
                            target="_self"
                        >ASICS Authorized Online Seller Application and Agreement</a> for review and approval in ASICS’ sole discretion, as discussed below. Please note that Retailers are not
                        permitted to sell ASICS products on any third-party sales platform, including Amazon, unless specifically authorized by ASICS in writing.
                    </li>
                    <li>Retailers must satisfy requirements for ASICS product care and quality controls.</li>
                    <li>All Retailer orders are subject to acceptance by ASICS in its discretion and in accordance with its inventory and sales requirements.</li>
                    <li>Retailers are prohibited from taking a deduction from payment prior to ASICS issuing a credit memo.</li>
                    <li>ASICS granted rights to terminate “Authorized Retailer” status and cancel, suspend, reject and/or withhold shipment of orders.</li>
                    <li>Updates other provisions including, without limitation, Retailer’s indemnification and insurance requirements, limitations of liability, and dispute resolution procedures.</li>
                </ul>
                <p>
                    Please note that existing credit applications and agreements, continuing personal guarantees, list of retail store locations, email consent forms, resale certificate
                    representations, routing authorizations, and other similar documents shall continue in full force and effect, unless otherwise provided or required by ASICS.
                </p>
                <p>
                    The Terms and any referenced and incorporated agreements embody the entire agreement between ASICS and Retailer in relation to the subject matter thereof, and supersede all prior
                    negotiations, representations, or agreements, either written or oral. If there is any conflict between the terms of the Terms and any other agreement or terms, including, but not
                    limited to the Independent Retailer Agreement, Internet Partner Policy, and any online or market authorizations, the Terms shall prevail except as otherwise provided by the Terms.
                </p>
                <p>
                    Please also review new
                    <a
                        href="https://legal.asics.com/en-us/legal/privacy-policy"
                        target="_blank"
                    >ASICS Privacy Policy</a>,
                    <a
                        href="https://legal.asics.com/en-us/legal/california-laws"
                        target="_blank"
                    >California Privacy Notice (for California residents)</a>,
                    <a
                        href="https://legal.asics.com/en-us/legal/cookie-policy"
                        target="_blank"
                    >Cookie Policy</a>,
                    <a
                        href="/policy/terms-of-use"
                        target="_blank"
                    >Terms of Use</a>,
                    <a
                        href="/policy/umap"
                        target="_blank"
                    >UMAP Policy ASICS</a>,
                    <a
                        href="/policy/advertising-policy"
                        target="_blank"
                    >Advertising Policy</a>, and
                    <a
                        href="/policy/brand-protection-policy"
                        target="_blank"
                    >Brand Protection Policy</a>.
                </p>
                <p>
                    If Retailer currently and/or intends to drop ship any ASICS products, Retailer shall review the <a
                        href="/policy/dropship-addendum"
                        target="_blank"
                    >ASICS Drop Ship Addendum</a> available here.
                </p>
                <p>
                    ASICS reserves the right to update, amend, or modify the Terms and related agreements or policies provided for in this notice upon written or electronic notice to Retailer
                    (including by posting the new documents online its B2B website), and such posting shall be considered valid and effective notice. Unless otherwise provided, such modifications
                    will take effect immediately and Retailer’s continued ordering, purchase and/or sale of ASICS’ products following notice will be deemed Retailer’s acceptance of the updated Terms
                    and related agreements.
                </p>
                <p>
                    Please contact your sales representative or the ASICS Legal Department at <a
                        href="mailto:legalANA@asics.com"
                    >legalANA@asics.com</a> with any questions.
                </p>

                <p class="my-4 font-bold">
                    By checking the boxes and clicking the accept button below, you represent and warrant that you have read, agree to, and have the authority to bind the Retailer to the Terms and
                    related agreements set forth below, and that all information you provide is accurate and complete.
                </p>

                <ValidationObserver
                    ref="validationObserver"
                    tag="form"
                    autocomplete="off"
                    novalidate
                    @submit.prevent="acceptTerms"
                >
                    <div class="flex flex-col gap-4">
                        <Checkbox
                            id="checkbox-1"
                            v-model="entry.checkbox1"
                            :disabled="isAlreadyApproved"
                            :rules="{required: {allowFalse: false}}"
                        >
                            <template #label>
                                <span class="ml-4">I am authorized by Retailer to accept the terms below on behalf of Retailer and such acceptance shall be binding on Retailer.</span>
                            </template>
                        </Checkbox>
                        <Checkbox
                            id="checkbox-2"
                            v-model="entry.checkbox2"
                            :disabled="isAlreadyApproved"
                            :rules="{required: {allowFalse: false}}"
                        >
                            <template #label>
                                <span class="ml-4">Retailer agree to be subject to and bound by the ASICS America Corporation <a
                                    href="/policy/retailer-terms"
                                    target="_blank"
                                >Authorized Retailer Terms and Conditions</a>.</span>
                            </template>
                        </Checkbox>
                        <Checkbox
                            id="checkbox-3"
                            v-model="entry.checkbox3"
                            :disabled="isAlreadyApproved"
                            :rules="{required: {allowFalse: false}}"
                        >
                            <template #label>
                                <span class="ml-4">If Retailer is currently and/or intends to drop ship any ASICS products, Retailer has reviewed the <a
                                    href="/policy/dropship-addendum"
                                    target="_blank"
                                >ASICS Drop Ship Addendum</a> and agrees to be bound by its terms.</span>
                            </template>
                        </Checkbox>
                        <Checkbox
                            id="checkbox-4"
                            v-model="entry.checkbox4"
                            :disabled="isAlreadyApproved"
                            :rules="{required: {allowFalse: false}}"
                        >
                            <template #label>
                                <span class="ml-4">Retailer agrees to the ASICS B2B <a
                                    href="/policy/terms-of-use"
                                    target="_blank"
                                >Terms of Use</a>.</span>
                            </template>
                        </Checkbox>
                        <Checkbox
                            id="checkbox-5"
                            v-model="entry.checkbox5"
                            :disabled="isAlreadyApproved"
                            :rules="{required: {allowFalse: false}}"
                        >
                            <template #label>
                                <span class="ml-4">Retailer agrees that any personal information will be processed according to the <a
                                    href="https://legal.asics.com/en-us/legal/privacy-policy"
                                    target="_blank"
                                >ASICS Privacy Policy</a>.</span>
                            </template>
                        </Checkbox>
                        <Checkbox
                            id="checkbox-6"
                            v-model="entry.checkbox6"
                            :disabled="isAlreadyApproved"
                            :rules="{required: {allowFalse: false}}"
                        >
                            <template #label>
                                <span class="ml-4">Retailer agrees that the
                                    <a
                                        href="/policy/retailer-terms"
                                        target="_blank"
                                    >Terms</a>,
                                    <a
                                        href="/policy/dropship-addendum"
                                        target="_blank"
                                    >Drop Ship Addendum</a>,
                                    <a
                                        href="/policy/terms-of-use"
                                        target="_blank"
                                    >Terms of Use</a>,
                                    <a
                                        href="https://legal.asics.com/en-us/legal/privacy-policy"
                                        target="_blank"
                                    >ASICS Privacy Policy</a>,
                                    <a
                                        href="https://legal.asics.com/en-us/legal/california-laws"
                                        target="_blank"
                                    >California Privacy Notice (for California residents)</a>,
                                    <a
                                        href="https://legal.asics.com/en-us/legal/cookie-policy"
                                        target="_blank"
                                    >Cookie Policy</a>, and the
                                    <a
                                        :href="`/${subsidiary}/legal/online-seller-agreement`"
                                        target="_self"
                                    >ASICS Authorized Online Seller Application and Agreement</a> (as discussed below) may be accepted by Retailer by electronic acceptance of its authorized
                                    representative and that such electronic acceptance shall have the same legal effect, validity and enforceability as an original signature, and Retailer hereby
                                    waives any objection to the contrary. Retailer consents to electronic communications and notices from ASICS including by posting to the B2B website and/or delivery
                                    to the registered email addresses of its authorized representatives.</span>
                            </template>
                        </Checkbox>

                        <p class="font-bold">
                            Select one of the following two:
                        </p>

                        <Checkbox
                            id="checkbox-7"
                            v-model="entry.checkbox7"
                            vid="checkbox7"
                            rules="required_if:checkbox8,false"
                            :disabled="isAlreadyApproved"
                            @change="onCheckboxChange('checkbox7')"
                        >
                            <template #label>
                                <span class="ml-4">If Retailer desires to sell any ASICS product online, and/or by any other Internet-enabled method or device, it shall complete and submit for ASICS
                                    review and approval, in ASICS’ sole discretion, the <a
                                        :href="`/${subsidiary}/legal/online-seller-agreement`"
                                        target="_self"
                                    >ASICS Authorized Online Seller Application and Agreement</a>. Retailer understands once it completes this Application and Agreement, it must save a copy and submit
                                    it to <a
                                        href="mailto:legalANA@asics.com"
                                    >LegalANA@asics.com</a> for review.  Until ASICS has reviewed and approved the submitted Application and Agreement, Retailer will not be authorized to sell ASICS
                                    product online until Retailer is specifically authorized by ASICS under that Application and Agreement.  Retailer acknowledges and agrees that ASICS may accept,
                                    reject, amend, or otherwise modify the Application and Agreement in its sole discretion.</span>
                            </template>
                        </Checkbox>
                        <Checkbox
                            id="checkbox-8"
                            v-model="entry.checkbox8"
                            vid="checkbox8"
                            rules="required_if:checkbox7,false"
                            :disabled="isAlreadyApproved"
                            @change="onCheckboxChange('checkbox8')"
                        >
                            <template #label>
                                <span class="ml-4">Retailer understands it is prohibited from selling online and does not sell or intend to sell online.</span>
                            </template>
                        </Checkbox>
                    </div>

                    <p class="my-4 font-bold">
                        The following mandatory fields must be completed by Retailer prior to accepting the above:
                    </p>

                    <div class="flex flex-col w-1/2">
                        <TextInput
                            id="customer-name"
                            v-model="customerName"
                            :readonly="true"
                            label="Retailer name"
                        />
                        <TextInput
                            id="customer-no"
                            v-model="entry.customerNo"
                            :readonly="true"
                            label="Retailer asics account"
                        />
                        <TextInput
                            id="full-name"
                            v-model="entry.fullName"
                            :readonly="isAlreadyApproved"
                            :max-length="50"
                            rules="required|min:3"
                            label="Individual name"
                            placeholder="Please enter your name (min. 3 characters)"
                        />
                        <TextInput
                            id="user-title"
                            v-model="entry.userTitle"
                            :readonly="isAlreadyApproved"
                            :max-length="50"
                            rules="required|min:3"
                            label="Individual title/position"
                            placeholder="Please enter your position/title (min. 3 characters)"
                        />
                        <TextInput
                            id="username"
                            v-model="entry.user"
                            label="Individual username"
                            :readonly="true"
                        />
                        <TextInput
                            id="email"
                            v-model="entry.email"
                            label="Individual email"
                            :readonly="true"
                        />
                        <div class="flex items-center gap-4 text-primary">
                            <strong>Date</strong>
                            <span>{{ entry.date }}</span>
                        </div>
                    </div>

                    <div class="action-buttons text-base">
                        <span
                            v-if="isAlreadyApproved && !isSubmitted"
                            class="text-primary"
                        >The form has already been submitted</span>
                        <span
                            v-if="isSubmitted"
                            class="text-primary"
                        >Your approval has been submitted</span>
                        <span
                            v-if="errorMsg"
                            class="text-red-dark"
                        >
                            {{ errorMsg }}
                        </span>
                        <Button
                            :text="$t('general.buttonClose')"
                            kind="primary-alt"
                            :disabled="submitInProgress"
                            @click.native.prevent="onFormCancel"
                        />
                        <Button
                            v-if="!isAlreadyApproved && !isSubmitted"
                            type="submit"
                            :text="$t('general.buttonAccept')"
                            :disabled="isAlreadyApproved || submitInProgress"
                        >
                            <i :class="['fa', submitInProgress ? 'fa-spinner fa-spin' : 'fa-check']" />
                        </Button>
                    </div>
                </ValidationObserver>
            </div>
        </template>
    </ModalDialog>
</template>

<script>
import Button from '@/components/button/Button.vue';
import Checkbox from '@/components/checkbox/Checkbox.vue';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import TextInput from '@/components/text-input/TextInput.vue';
import { sendAcceptTerms } from '@/modules/admin/customers/legal.api.js';
import { localisedDateTimeFormat } from '@/utils/generic.js';
import { ValidationObserver } from 'vee-validate';

export default {
    name: 'LegalAgreementModal',

    components: {
        Checkbox,
        TextInput,
        Button,
        ModalDialog,
        ValidationObserver,
    },

    props: {
        showModal: {
            type: Boolean,
            default: false,
        },

        currentApproval: {
            type: Object,
            default() {
                return {};
            },
        },
    },

    data() {
        return {
            errorMsg: '',
            isSubmitted: false,
            submitInProgress: false,

            entry: {
                checkbox1: false,
                checkbox2: false,
                checkbox3: false,
                checkbox4: false,
                checkbox5: false,
                checkbox6: false,
                checkbox7: false,
                checkbox8: false,
                fullName: '',
                userTitle: '',
                customerNo: '',
                date: null,
            },
        };
    },

    computed: {
        subsidiary() {
            return this.$store.getters.getSubsidiary;
        },

        isAlreadyApproved() {
            return Boolean(this.$store.getters.getLegalApproval.customerNo);
        },

        userLocale() {
            return this.$store.getters.userLocale;
        },

        timeZone() {
            return this.$store.state.subsidiary.timezone;
        },
    },

    created() {
        this.customerName = this.$store.getters.getCustomerName;

        this.entry.customerNo = this.$store.getters.getRealCustomerNo;
        this.entry.user = this.$store.getters.username;
        this.entry.email = this.$store.getters.getUserEmail;

        const date = this.$store.getters.getTodayForLegalApproval ? new Date(this.$store.getters.getTodayForLegalApproval) : new Date();

        this.entry.date = localisedDateTimeFormat(date, this.userLocale, { timeZone: this.timeZone });

        if (this.isAlreadyApproved) {
            const entry = {
                ...this.$store.getters.getLegalApproval,
            };

            entry.date = localisedDateTimeFormat(new Date(entry.created_at), this.userLocale, { timeZone: this.timeZone });

            this.entry = entry;
        }
    },

    methods: {
        onFormCancel() {
            this.$emit('close');
        },

        onCheckboxChange(name) {
            if (name === 'checkbox7' && this.entry[name]) {
                this.entry.checkbox8 = false;
            } else {
                this.entry.checkbox7 = false;
            }
        },

        async acceptTerms() {
            const isFormValid = await this.$refs.validationObserver.validate();

            if (!isFormValid) {
                return;
            }

            this.submitInProgress = true;

            try {
                await sendAcceptTerms(this.entry);

                this.isSubmitted = true;

                this.$store.commit('approvedLegalAgreementCustomerNo', this.entry.customerNo);
            } catch (error) {
                this.errorMsg = this.$t('general.acceptTermsFailed');
            }

            this.submitInProgress = false;
        },
    },
};
</script>
