<template>
    <div class="flex flex-col gap-4">
        <div class="flex gap-4 justify-between items-baseline">
            <h1 class="heading-md sm:heading-lg">
                {{ $t('general.baskets') }}
            </h1>
            <router-link
                to="/pending-baskets"
                class="underline capitalize"
            >
                {{ $t('general.allPendingBaskets') }}
            </router-link>
        </div>
        <div
            v-if="dataLoaded"
            class="grid grid-cols-[repeat(auto-fit,minmax(33rem,1fr))] gap-8 sm:gap-12 items-center"
        >
            <router-link
                :to="`/customers/${customerNo}/orders/new?type=salesOrder`"
                class="hidden sm:flex flex-col gap-8 items-center justify-center h-[15.5rem] p-4 sm:p-8 border rounded-3xl text-lg sm:text-xl font-bold"
            >
                <i class="fa fa-plus fa-xl" />
                <span class="text-center truncate w-full">{{ $t('baskets.createNewBasket') }}</span>
            </router-link>
            <router-link
                :to="`/customers/${customerNo}/orders/new?type=salesOrder`"
                class="sm:hidden"
            >
                <Button
                    :text="$t('baskets.createNewBasket')"
                    kind="primary-alt"
                    class="w-full"
                >
                    <i class="fa fa-plus" />
                </Button>
            </router-link>
            <router-link
                v-for="basket in currentBaskets"
                :key="basket.no"
                :to="`/orders/${basket.no}`"
                class="flex flex-col h-[15.5rem] overflow-hidden border rounded-3xl"
                :class="basketCardTheme[basket.orderJourney].card"
            >
                <div
                    class="flex flex-none items-center justify-between gap-4 h-[4.5rem] px-8 text-lg sm:text-xl font-bold"
                    :class="basketCardTheme[basket.orderJourney].header"
                >
                    <span class="shrink-0">{{ $t('baskets.basket') }} #{{ basket.no }}</span>
                    <span class="truncate">{{ formatDate(basket.createdAt) }}</span>
                </div>
                <div class="flex flex-col justify-center gap-6 h-full px-8">
                    <div class="flex flex-col leading-snug">
                        <span>{{ basket.shipTo.name }} - {{ basket.shipTo.customerNo }}</span>
                        <span>{{ basket.shipTo.address1 }} - {{ basket.shipTo.no }}</span>
                    </div>
                    <div class="flex items-center justify-between font-bold">
                        <div class="flex items-center gap-3">
                            <i
                                class="fa fa-lg"
                                :class="basketCardTheme[basket.orderJourney].icon"
                            />
                            <span class="flex items-center gap-2 capitalize">
                                <span>{{ $t(basketCardTheme[basket.orderJourney].translationKey) }}</span>
                                <span class="font-normal">({{ $t(basket.focStatus ? 'orderDetails.typeFOC' : `orderType.${basket.orderType}`) }})</span>
                            </span>
                        </div>
                        <div class="flex items-center gap-3">
                            <span>{{ basket.totalQuantity }} {{ unitTranslation }}</span>
                            <span>|</span>
                            <span>{{ formatPrice(basket.totalGrossAmount, basket.currencyCode) }}</span>
                        </div>
                    </div>
                </div>
            </router-link>
        </div>
        <Spinner
            v-else
            icon-css-class="fa-lg"
        />
    </div>
</template>

<script>
import Button from '@/components/button/Button.vue';
import Spinner from '@/components/spinner/Spinner.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { getCurrentBasket } from '@/modules/baskets/baskets.api.js';
import { Const } from '@/utils/constants.js';
import { localisedCurrencyFormat, localisedDateTimeFormat } from '@/utils/generic.js';

export default {
    name: 'DashboardBaskets',

    components: {
        Spinner,
        Button,
    },

    props: {
        customerNo: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            ORDER_TYPE: Const.ORDER_TYPE,
            dataLoaded: false,
            currentBaskets: null,
            ORDER_JOURNEY: Const.ORDER_JOURNEY,
            basketCardTheme: {
                [Const.ORDER_JOURNEY.AT_ONCE]: {
                    card: 'border-secondary-lightest hover:!text-primary hover:border-secondary',
                    header: 'bg-secondary-lightest',
                    icon: 'fa-check-circle text-secondary',
                    translationKey: 'orderJourney.atOnce',
                },
                [Const.ORDER_JOURNEY.PRE_ORDER]: {
                    card: 'border-green-lightest hover:!text-primary hover:border-green',
                    header: 'bg-green-lightest',
                    icon: 'fa-forward text-green-dark',
                    translationKey: 'orderJourney.preOrder',
                },
            },
        };
    },

    computed: {
        unitTranslation(units) {
            return units === 1 ? this.$t('basket.unit') : this.$t('basket.units');
        },
    },

    created() {
        this.fetchCurrentBasketPerOrderJourney();
    },

    methods: {
        formatDate(dateString) {
            return localisedDateTimeFormat(new Date(dateString), this.$store.getters.userLocale, { dateStyle: 'medium', timeStyle: 'short' });
        },

        formatPrice(value, currencyCode) {
            return localisedCurrencyFormat(value, this.$store.state.subsidiary.locale, currencyCode);
        },

        async fetchCurrentBasketPerOrderJourney() {
            try {
                const getCurrentBasketPerOrderJourney = this.$store.getters.availableOrderJourneys.map(({ value: orderJourney }) => getCurrentBasket({ orderJourney }));
                const currentBaskets = await Promise.all(getCurrentBasketPerOrderJourney);

                this.currentBaskets = currentBaskets.map(({ data }) => data).sort((a, b) => a.orderJourney.localeCompare(b.orderJourney));

                this.dataLoaded = true;
            } catch (error) {
                bugsnag.notify(error);
            }
        },
    },
};
</script>
