export default [
    {
        path: '/pdf-templates/sdp-scorecards/:id',
        component: () => import('@/modules/sdp-management/sdp-scorecard/pdf-template/SdpScorecardPdfTemplate.vue'),
        props: true,
    },
    {
        path: '/pdf-templates/e-catalogs/:id',
        component: () => import('@/modules/admin/toolbox/e-catalog-manager/e-catalog-download/pdf-template/ECatalogPdfTemplate.vue'),
        props: true,
    },
    {
        path: '/pdf-templates/digital-finance/customers/:customerNo/transactions/:id',
        component: () => import('@/modules/admin/customers/customer/credit-documents/payment-transactions/pdf-template/CustomerPaymentTransactionPdfTemplate.vue'),
        props: true,
    },
];
