import { Const } from '@/utils/constants.js';

export default {
    getSubsidiary: state => state.subsidiary.id,

    isAEGRegion: state => {
        return [
            Const.SUBSIDIARIES.AUSTRALIA,
            Const.SUBSIDIARIES.SOUTH_AFRICA,
            Const.SUBSIDIARIES.EASTERN_EUROPE,
            Const.SUBSIDIARIES.BENELUX,
            Const.SUBSIDIARIES.GERMANY,
            Const.SUBSIDIARIES.DENMARK,
            Const.SUBSIDIARIES.DISTRIBUTORS,
            Const.SUBSIDIARIES.ASICS_EUROPE_CENTRAL,
            Const.SUBSIDIARIES.FINLAND,
            Const.SUBSIDIARIES.FRANCE,
            Const.SUBSIDIARIES.IBERIA,
            Const.SUBSIDIARIES.ITALY,
            Const.SUBSIDIARIES.NORWAY,
            Const.SUBSIDIARIES.POLAND,
            Const.SUBSIDIARIES.SWEDEN,
            Const.SUBSIDIARIES.UNITED_KINGDOM,
        ].includes(state.subsidiary.id);
    },

    getSubsidiarySettings: state => state.subsidiary,

    getUserType: state => {
        return state.user?._type || null;
    },

    getCustomerName: state => {
        return state.customer?.customerName || null;
    },

    getRealCustomerNo: state => {
        return state.user?._customerNo || null;
    },

    getBasketOrRealCustomerNo: state => {
        if (state.basket?.customerNo) {
            return state.basket.customerNo;
        }

        if (state.customizedBasket?.customerNo) {
            return state.customizedBasket.customerNo;
        }

        if (state.user?._customerNo) {
            return state.user._customerNo;
        }

        return null;
    },

    getCustomerDistributionChannel: state => {
        return state.customer?.distributionChannel || null;
    },

    customerIsStore: state => state.customer.isStore,

    getRights: state => {
        return state.user?._rights || [];
    },

    getUserCan: state => id => {
        return Boolean(state.user._rights[id]);
    },

    username: state => state.user?.username || null,

    getUserEmail: state => {
        return state.user?._email || null;
    },

    getUserMfaEmail: state => {
        return state.user?.mfa_email || null;
    },

    getCurrentBasket: state => {
        return state.basket;
    },

    getCurrentOffer: state => {
        return state.offer;
    },

    getOfferQuantities: state => {
        const { basket } = state;

        if (!basket || !basket?.lines) {
            return [];
        }

        return basket.lines.map(({ itemNo, colorCode, month, sizeCode, quantity, netPrice, vatAmount }) => {
            return {
                itemNo,
                colorCode,
                month,
                sizeKey: sizeCode,
                quantity,
                price: netPrice,
                vatAmount,
            };
        });
    },

    unreadNotificationCount: state => state.notifications.length - state.notifications.filter(notification => Boolean(notification.read)).length,

    notificationCount: state => state.notifications.length,

    notifications: state => state.notifications,

    isLegalApproverForCustomer: state => {
        return Boolean(state.legal?.isLegalApprover);
    },

    getLegalApproval: state => {
        return state.legal?.legalApproval || {};
    },

    getTodayForLegalApproval: state => {
        return state.legal?.today || '';
    },

    userLocale: state => {
        const [language, region] = state.user.locale.split('-');

        return region ? state.user.locale : language;
    },

    preferredSizeChart: state => state.preferences.sizeChart,

    customizedBasket: state => {
        return state.customizedBasket;
    },

    orderGridClipboard: state => {
        return state.orderGridClipboard;
    },

    isLoggedOut: state => state.isLoggedOut,

    apiErrors: state => state.apiErrors,

    isMobileViewport: state => state.isMobileViewport,

    plpScrollTop: state => state.plp.scrollTop,

    customerDeliveryAddresses: state => state.customerDeliveryAddresses,

    customerDistributionCenters: state => state.customerDistributionCenters,

    availableOrderJourneys: state => state.availableOrderJourneys,

    toastNotificationExists: state => state.toastNotifications.length > 0,

    atOnceProductFilters: state => state.atOnceProductFilters,

    preOrderProductFilters: state => state.preOrderProductFilters,

    isProductionSap: state => state.isProductionSap,
};
