<template>
    <ul class="flex items-center text-sm capitalize h-10 px-8">
        <li
            v-for="({title, link, nonTranslatable}, index) in breadcrumbs"
            :key="`${title}-${index}`"
            class="breadcrumb-item"
        >
            <router-link
                v-if="link && (index < breadcrumbs.length - 1) || (breadcrumbs.length === 1)"
                :to="link"
                class="text-grey-dark hover:text-secondary hover:underline"
            >
                {{ nonTranslatable ? title : $t(title) }}
            </router-link>
            <span
                v-else
                class="text-grey-dark"
            >
                {{ nonTranslatable ? title : $t(title) }}
            </span>
        </li>
    </ul>
</template>

<script>
const breadcrumbItemHome = {
    title: 'general.titleHome',
    link: '/',
};

export default {
    name: 'Breadcrumbs',

    props: {
        items: {
            type: Array,

            default() {
                return [];
            },

            validator(value) {
                const isValid = value.every(item => Object.entries(item).length !== 0);

                if (!isValid) {
                    console.warn('Breadcrumbs array must contain non-empty item objects');
                }

                return isValid;
            },
        },
    },

    computed: {
        breadcrumbs() {
            return [breadcrumbItemHome, ...this.items];
        },
    },
};
</script>

<style lang="scss" scoped>
.breadcrumb-item {
    display: flex;

    &:not(:last-child):after {
        content: '/';
        padding: 0 0.5rem;
    }

    > * {
        width: max-content;
    }
}
</style>
