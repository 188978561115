<template>
    <menu role="menu">
        <li
            role="menuitem"
            tabindex="0"
            :aria-expanded="expanded"
        >
            <button
                v-if="$slots.title"
                class="flex items-center justify-between p-4 md:p-0 md:mb-2 font-bold w-full md:pointer-events-none"
                aria-controls="menu"
                @click="toggleMenu()"
            >
                <slot name="title" />
                <i
                    v-if="$slots.dropdown"
                    :class="['fa', expanded ? 'fa-chevron-up' : 'fa-chevron-down']"
                />
            </button>
            <ul
                v-if="$slots.dropdown"
                v-show="expanded"
                role="menu"
                :class="['px-4 pb-4 md:p-0 flex flex-col md:block',
                         {
                             'md:columns-2 md:gap-x-10': columns === 2,
                             'md:columns-3 md:gap-x-10': columns === 3,
                         }
                ]"
            >
                <slot name="dropdown" />
            </ul>
        </li>
    </menu>
</template>

<script>
export default {
    name: 'FooterDropDownMenu',

    props: {
        columns: {
            type: Number,
            default: 1,
        },
    },

    data() {
        return {
            expanded: false,
            mediaQueryList: null,
        };
    },

    computed: {
        isDesktopSize() {
            return this.mediaQueryList.matches;
        },
    },

    watch: {
        $route() {
            if (this.isDesktopSize) {
                return;
            }

            this.collapseMenu();
        },
    },

    mounted() {
        this.mediaQueryList = window.matchMedia('(min-width: 48rem)'); // matches when the viewport width is at least 48rem which is the same as Tailwind's `md:`

        this.mediaQueryList.addEventListener('change', event => this.handleWidthChange(event));
        this.toggleMenu(this.mediaQueryList.matches);
    },

    beforeDestroy() {
        this.mediaQueryList.removeEventListener('change', this.handleWidthChange);
    },

    methods: {
        collapseMenu() {
            this.expanded = false;
        },

        toggleMenu(value) {
            this.expanded = value == null ? !this.expanded : value;
        },

        handleWidthChange(event) {
            this.toggleMenu(event.matches);
        },
    },
};
</script>

<style scoped>
a {
    @apply text-primary hover:text-white opacity-100;
}

.fa.fa-chevron-up,
.fa.fa-chevron-down {
    @apply md:hidden;
}

ul[role='menu'] li {
    @apply py-1 md:py-0;
}
</style>
