import AbstractRouterView from '@/components/layout/AbstractRouterView.vue';
import Authenticated from '@/components/layout/Authenticated.vue';
import NotFound from '@/components/layout/NotFound.vue';
import Unauthenticated from '@/modules/unauthenticated/Unauthenticated.vue';
import adminRoutes from '@/router/routes/admin-routes.js';
import {
    accountRoutes,
    adminAndEmployeeCustomerRoutes,
    blogManagerRoutes,
    communicationsRoutes,
    customerDeliveryNotesRoutes,
    customerOrderCreationRoutes,
    customerOrdersRoutes,
    notificationRoutes,
    ordersOverviewRoutes,
    productCustomizationRoutes,
    statisticsRoutes,
    supportRoutes,
    toolboxRoutes,
} from '@/router/routes/common-routes.js';
import customerRoutes from '@/router/routes/customer-routes.js';
import employeeRoutes from '@/router/routes/employee-routes.js';
import pdfTemplateRoutes from '@/router/routes/pdf-template-routes.js';
import { unauthenticatedRoutes } from '@/router/routes/unauthenticated-routes.js';

const routes = [
    {
        path: '',
        component: Authenticated,
        name: 'authenticated',
        meta: {
            auth: true,
        },
        children: [
            ...adminRoutes, // Available to admin users only
            ...employeeRoutes, // Available to employee users only
            ...customerRoutes, // Available to customer users only

            // Not available to customer users (except for /toolbox/e-catalog-management in some cases)
            ...adminAndEmployeeCustomerRoutes,
            ...toolboxRoutes,
            ...blogManagerRoutes,
            ...statisticsRoutes,

            // Not available to admin users
            ...ordersOverviewRoutes,
            ...customerOrderCreationRoutes,
            ...productCustomizationRoutes,

            // Available to all users
            ...accountRoutes,
            ...customerOrdersRoutes,
            ...customerDeliveryNotesRoutes,
            ...supportRoutes,
            ...communicationsRoutes,
            ...notificationRoutes,
            ...pdfTemplateRoutes,
        ],
    },
    {
        path: '',
        component: AbstractRouterView,
        name: 'unauthenticated',
        meta: {
            auth: false,
        },
        redirect: '/authentication/login',
        children: [
            {
                path: '/authentication',
                component: Unauthenticated,
                redirect: '/authentication/login',
                children: [
                    ...unauthenticatedRoutes,
                ],
            },
        ],
    },
    {
        path: '/cookie-policy',
        name: 'cookiePolicy',
        component: () => import('@/modules/support/policy/AsicsCookiePolicy.vue'),
        props: {
            isAuthenticationRoute: true,
        },
    },
    {
        path: '*',
        name: 'Unauthorized',
        component: () => import('@/components/layout/Unauthorized.vue'),
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',
        component: NotFound,
    },
];

export default routes;
