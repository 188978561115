import { bugsnag } from '@/libs/bugsnag.js';
import { saveFile } from '@/utils/generic.js';
import { reactive } from 'vue';

/**
 * Reusable file download
 * @param {Function} downloadFn
 * @param {Function} onError
 * @returns {{download: ((function(): Promise<void>)|*), downloadState: {inProgress: boolean, errorMessage: null, failed: boolean}}}
 */
export const useFileDownload = (downloadFn, onError = null) => {
    const downloadState = reactive({
        inProgress: false,
        failed: false,
        errorMessage: null,
    });

    const download = async () => {
        downloadState.inProgress = true;
        downloadState.failed = false;
        downloadState.errorMessage = null;

        try {
            const { data, headers } = await downloadFn();
            const [, fileName] = /filename=(.+)/.exec(headers['content-disposition']);

            saveFile(data, fileName.replaceAll('"', ''));
        } catch (error) {
            downloadState.failed = true;
            downloadState.errorMessage = error?.message ?? error;

            bugsnag.notify(error);

            if (onError) {
                onError(error);
            }
        } finally {
            downloadState.inProgress = false;
        }
    };

    return {
        downloadState,
        download,
    };
};
