<template>
    <DropDownMenu>
        <template #title>
            {{ $t('customerMainMenu.supportMenu') }}
        </template>
        <template #dropdown>
            <li v-if="canShowFaq">
                <router-link
                    to="/help"
                    target="_blank"
                    role="menuitem"
                >
                    {{ $t('menu.faq') }}
                </router-link>
            </li>
            <li v-if="subsidiary !== SUBSIDIARIES.UNITED_STATES">
                <router-link
                    to="/policy/cookie"
                    target="_blank"
                    role="menuitem"
                >
                    {{ $t('menu.cookiePolicy') }}
                </router-link>
            </li>
            <template v-if="subsidiary === SUBSIDIARIES.UNITED_STATES">
                <li>
                    <a
                        href="https://legal.asics.com/en-us/legal/cookie-policy"
                        target="_blank"
                        role="menuitem"
                    >
                        {{ $t('menu.cookiePolicy') }}
                    </a>
                </li>
                <li>
                    <a
                        href="https://legal.asics.com/en-us/legal/privacy-policy"
                        target="_blank"
                        role="menuitem"
                    >
                        ASICS Privacy Policy (Updated)
                    </a>
                </li>
                <li>
                    <a
                        href="https://legal.asics.com/en-us/legal/california-laws"
                        target="_blank"
                        role="menuitem"
                    >
                        California Privacy Notice (for California residents)
                    </a>
                </li>
                <li>
                    <router-link
                        to="/policy/terms-of-use"
                        target="_blank"
                        role="menuitem"
                    >
                        Terms of Use
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/policy/umap"
                        target="_blank"
                        role="menuitem"
                    >
                        UMAP Policy ASICS
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/policy/advertising-policy"
                        target="_blank"
                        role="menuitem"
                    >
                        Advertising Policy
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/policy/brand-protection-policy"
                        target="_blank"
                        role="menuitem"
                    >
                        {{ $t('legal.menuBrandProtectionPolicy') }}
                    </router-link>
                </li>
                <li>
                    <router-link
                        to="/policy/retailer-terms"
                        target="_blank"
                        role="menuitem"
                    >
                        {{ $t('customerMainMenu.termAndConditions') }}
                    </router-link>
                </li>
            </template>
            <template v-if="subsidiary !== SUBSIDIARIES.UNITED_STATES">
                <li>
                    <router-link
                        to="/policy/sales-conditions"
                        target="_blank"
                        role="menuitem"
                    >
                        {{ $t('orderDetails.linkToTerms') }}
                    </router-link>
                </li>
            </template>
            <template v-if="showLegalDocuments">
                <li v-if="showSignableLegalDocument">
                    <ButtonPlain
                        :text="$t('legal.menuToLegalAgreementModal')"
                        role="menuitem"
                        class="font-normal"
                        @click.native="openLegalApprovementModal"
                    />
                </li>
                <li>
                    <ButtonPlain
                        :text="$t('legal.menuToLegalAgreementDownload')"
                        role="menuitem"
                        class="font-normal"
                        @click.native="downloadAgreementPdf"
                    />
                </li>
            </template>
            <template v-if="subsidiary === SUBSIDIARIES.UNITED_STATES">
                <li>
                    <router-link
                        to="/policy/dropship-addendum"
                        target="_blank"
                        role="menuitem"
                    >
                        Drop Ship Addendum
                    </router-link>
                </li>
            </template>
            <template v-if="subsidiary === SUBSIDIARIES.CANADA">
                <li>
                    <router-link
                        to="/user-agreement"
                        target="_blank"
                        role="menuitem"
                    >
                        User agreement
                    </router-link>
                </li>
            </template>
            <template v-if="subsidiary === SUBSIDIARIES.UNITED_STATES">
                <li>
                    <router-link
                        to="/contact-us"
                        target="_blank"
                        role="menuitem"
                    >
                        Contact Us
                    </router-link>
                </li>
            </template>
            <template v-if="[SUBSIDIARIES.CHILE, SUBSIDIARIES.COLOMBIA].includes(subsidiary)">
                <li>
                    <router-link
                        :to="`/policy/privacy-policy/${subsidiary.id}`"
                        target="_blank"
                        role="menuitem"
                    >
                        {{ $t('menu.privacyPolicy') }}
                    </router-link>
                </li>
            </template>
            <template v-if="showTicketManagement">
                <li>
                    <router-link
                        to="/support/service-portal"
                        role="menuitem"
                    >
                        {{ $t('adminTickets.servicePortal') }}
                    </router-link>
                </li>
            </template>
        </template>
    </DropDownMenu>
</template>

<script>
import api from '@/api/api.js';
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import DropDownMenu from '@/components/menu/DropDownMenu.vue';
import { useFileDownload } from '@/composables/use-file-download.js';
import { Const } from '@/utils/constants';
import { emitter } from '@/utils/event-emitter.js';

export default {
    name: 'SupportMenu',

    components: {
        ButtonPlain,
        DropDownMenu,
    },

    data() {
        return {
            SUBSIDIARIES: Const.SUBSIDIARIES,
        };
    },

    computed: {
        userType() {
            return this.$store.getters.getUserType;
        },

        subsidiary() {
            return this.$store.getters.getSubsidiary;
        },

        canShowFaq() {
            if (this.userType === Const.ACCOUNT_TYPE_CUSTOMER) {
                return ['adg', 'aag', 'apo', 'abx', 'ase', 'ano', 'adk', 'auk', 'afr', 'aib', 'ait', 'aop', 'aac', 'aca'].includes(this.subsidiary);
            }

            if (this.userType === Const.ACCOUNT_TYPE_SALES_REP) {
                return this.subsidiary === Const.SUBSIDIARIES.CANADA;
            }

            return false;
        },

        showLegalDocuments() {
            return this.subsidiary === this.SUBSIDIARIES.UNITED_STATES && (this.isLegalApproverForCustomer || this.canViewLegalDocuments);
        },

        showSignableLegalDocument() {
            if (this.subsidiary !== this.SUBSIDIARIES.UNITED_STATES) {
                return false;
            }

            if (this.isLegalApproverForCustomer) {
                return true;
            }

            return this.canViewLegalDocuments && this.legalDocumentHasBeenSigned;
        },

        legalDocumentHasBeenSigned() {
            return Boolean(this.$store.getters.getLegalApproval.customerNo);
        },

        isLegalApproverForCustomer() {
            return this.subsidiary === Const.SUBSIDIARIES.UNITED_STATES && this.$store.getters.isLegalApproverForCustomer;
        },

        canViewLegalDocuments() {
            return this.$store.getters.getUserCan(Const.PERMISSIONS.CAN_VIEW_LEGAL_DOCUMENTS);
        },

        showTicketManagement() {
            return this.$store.state.subsidiary.ticketsEnabled;
        },
    },

    methods: {
        openLegalApprovementModal() {
            emitter.$emit('openAgreementModal');
        },

        async downloadAgreementPdf() {
            const { download } = useFileDownload(() => api.get('legal/online-seller-agreement', {
                responseType: 'blob',
                headers: {
                    Accept: Const.MIME_TYPE.PDF,
                },
            }));

            await download();
        },
    },
};
</script>
