import { getCurrentInstance } from 'vue';
import i18n from '@/libs/i18n/i18n.js';

/**
 * NOTE: this is a workaround to have translation function (t) available when using composition API. This will not be needed after upgrade to Vue3
 * @returns {Object}
 */
export const useI18n = () => {
    if (!i18n) {
        throw new Error('vue-i18n is not initialized');
    }

    const instance = getCurrentInstance();
    const vm = instance.proxy;

    return {
        t: vm.$t.bind(vm),
        tc: vm.$tc.bind(vm),
        d: vm.$d.bind(vm),
        te: vm.$te.bind(vm),
        n: vm.$n.bind(vm),
    };
};
