<template>
    <FooterDropDownMenu>
        <template #title>
            {{ $t('footerMenu.download') }}
        </template>

        <template #dropdown>
            <li v-if="canDownloadMediaAssets">
                <router-link
                    to="/downloads/assets/asics"
                    role="menuitem"
                >
                    {{ $t('footerMenu.asicsAssets') }}
                </router-link>
            </li>
            <li v-if="canDownloadMediaAssets">
                <router-link
                    to="/downloads/assets/onitsuka-tiger"
                    role="menuitem"
                >
                    {{ $t('footerMenu.onitsukaTigerAssets') }}
                </router-link>
            </li>
            <li>
                <router-link
                    to="/downloads/e-catalogs"
                    role="menuitem"
                >
                    {{ $t('footerMenu.eCatalog') }}
                </router-link>
            </li>
            <li>
                <router-link
                    to="/downloads/product-collection-data"
                    role="menuitem"
                >
                    Product Collection Data
                </router-link>
            </li>
        </template>
    </FooterDropDownMenu>
</template>

<script>
import FooterDropDownMenu from '@/components/footer-menu/FooterDropDownMenu.vue';
import { Const } from '@/utils/constants.js';

export default {
    name: 'AdminDownloadMenu',

    components: {
        FooterDropDownMenu,
    },

    computed: {
        canDownloadMediaAssets() {
            return this.$store.getters.getUserCan(Const.PERMISSION_CAN_DOWNLOAD_MEDIA_ASSETS);
        },
    },
};
</script>
