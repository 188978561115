<template>
    <div class="divide-y divide-grey">
        <div
            v-if="!basket.isPreOrderFromTemplate"
            class="flex items-center justify-between w-full p-6 font-bold"
        >
            <span>{{ $t('baskets.subtotal') }}</span>
            <div class="flex items-center gap-3">
                <span>{{ formatUnits(basket.totalQuantity) }}</span>
                <template v-if="shouldShowPrice">
                    <span>|</span>
                    <span>{{ formatPrice(basket.totals.netAmount) }}</span>
                </template>
            </div>
        </div>
        <div class="flex flex-col items-center gap-4 p-6 sm:pb-8 bg-white">
            <slot>
                <Button
                    v-if="canOrder"
                    :text="$t('baskets.viewBasketAndCheckout')"
                    :disabled="basketIsEmpty"
                    @click.native="viewBasket"
                />
                <Alert
                    v-if="containsItemsSuppliedByDistributor"
                    kind="warning"
                    size="small"
                    :message="$t('general.totalAmountExcludesSuppliedByDistribitors')"
                />
            </slot>
        </div>
    </div>
</template>

<script setup>
import Alert from '@/components/alert/Alert.vue';
import Button from '@/components/button/Button.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import router from '@/router/router.js';
import store from '@/store/store.js';
import { computed } from 'vue';

const props = defineProps({
    basket: {
        type: Object,
        required: true,
    },

    shouldShowPrice: {
        type: Boolean,
        required: true,
    },

    basketIsEmpty: {
        type: Boolean,
        required: true,
    },

    isCustomizedOrder: {
        type: Boolean,
        required: true,
    },

    formatNumber: {
        type: Function,
        required: true,
    },

    formatPrice: {
        type: Function,
        required: true,
    },

    formatUnits: {
        type: Function,
        required: true,
    },
});

const emit = defineEmits([
    'hideMiniBasketSidebar',
]);

const containsItemsSuppliedByDistributor = computed(() => props.basket.lines.some(({ partnerNo }) => Boolean(partnerNo)));
const canOrder = computed(() => store.state.preferences.canOrder);
const viewBasket = async () => {
    try {
        emit('hideMiniBasketSidebar');

        await router.push(`/orders/${props.basket.no}/basket-contents${props.isCustomizedOrder ? '?isCustomizedOrder=true' : ''}`);
    } catch (error) {
        bugsnag.notify(error);
    }
};

</script>
