<template>
    <ModalDialog
        size="medium"
        @close="modalCancel"
    >
        <template #header-title>
            {{ $t('baskets.copyBasket') }}
        </template>

        <template #body>
            <div
                v-if="step === 1"
                class="flex flex-col gap-2"
            >
                <label>
                    {{ $t('general.selectCustomer') }}
                </label>
                <CustomerList
                    :hidden="['statusName', 'oldCustomerNo', 'accountType']"
                    cache-new-key="popupCustomers"
                    :default-per-page="10"
                    @customerSelected="onCustomerSelected"
                />
            </div>
            <div
                v-else-if="step === 2"
                class="flex flex-col gap-2"
            >
                <label>
                    {{ $t('general.selectDeliveryAddress') }}
                </label>
                <AddressList
                    :customer-no="selectedCustomerNo"
                    :hidden="['__slot:scoring']"
                    :default-per-page="10"
                    @addressSelected="onAddressSelected"
                />
            </div>
            <Spinner
                v-else-if="step === 3"
                align-center
                icon-css-class="fa-xl"
                class="h-12"
            />
            <div v-else-if="step === 4">
                <div
                    :style="responseMessageStyle"
                    v-html="responseMessageHtml"
                />
            </div>
        </template>

        <div class="action-buttons">
            <Button
                v-if="step < 4"
                kind="primary-alt"
                :text="$t('general.buttonCancel')"
                @click.native="modalCancel"
            />
            <Button
                v-if="canShowBack"
                kind="primary-alt"
                :text="$t('general.buttonPrevious')"
                :disabled="!canGoBack"
                @click.native="step = step - 1"
            />
            <Button
                v-if="canShowNext"
                :text="$t('general.buttonNext')"
                :disabled="!canGoNext"
                @click.native="step = step + 1"
            />
            <Button
                v-if="step === 2 && displayOrderTypeChoice"
                :text="$t('orderNew.labelCreateFreeOfCharge')"
                :disabled="!canConfirm"
                @click.native="copyBasket('ZFD')"
            />
            <Button
                v-if="step === 2"
                :text="isCustomizedBasket ? $t('orderNew.labelCreateCustomizedOrder') : $t('orderNew.labelCreateSalesOrder')"
                :disabled="!canConfirm"
                @click.native="copyBasket('ZOR')"
            />
            <Button
                v-if="step === 4"
                :text="$t('general.buttonOk')"
                @click.native="modalCancel"
            />
        </div>
    </ModalDialog>
</template>

<script>
import Button from '@/components/button/Button.vue';
import Spinner from '@/components/spinner/Spinner.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import ModalDialog from '@/components/dialogs/ModalDialog.vue';
import CustomerList from '@/components/data-tables/CustomerList.vue';
import AddressList from '@/components/data-tables/AddressList.vue';
import { getCustomerFreeOfChargeSettings } from '@/modules/account/account.api.js';
import { copyBasket } from '@/modules/baskets/baskets.api.js';
import { Const } from '@/utils/constants';

export default {
    name: 'CopyBasketModal',

    components: {
        Spinner,
        Button,
        AddressList,
        CustomerList,
        ModalDialog,
    },

    props: {
        basket: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            step: 1,
            selectedCustomer: null,
            selectedAddress: null,
            responseMessageHtml: '',
            responseMessageStyle: {},
            loadingFOCSettings: false,
            focSettings: {},
        };
    },

    computed: {
        canGoBack() {
            return (this.step === 2);
        },

        canGoNext() {
            return (this.step === 1 && !!this.selectedCustomer);
        },

        canShowBack() {
            return (!this.customerNo && this.step === 2);
        },

        canShowNext() {
            return (!this.customerNo && this.step === 1);
        },

        canConfirm() {
            return !!this.selectedAddress;
        },

        selectedCustomerNo() {
            if (this.customerNo) {
                return this.customerNo;
            }

            return this.selectedCustomer?.no;
        },

        selectedAddressNo() {
            return this.selectedAddress?.no;
        },

        displayOrderTypeChoice() {
            return (this.basket.orderType === 'S') && (this.canOrderZFD);
        },

        canOrderZFD() {
            if (this.loadingFOCSettings) {
                return false;
            }

            if (!this.focSettings.showSelectOrderTypeInAdvance) {
                return false;
            }

            if (this.isSalesRep) {
                return !!this.focSettings.canCustomerCreateFOC;
            } else {
                return !!this.$store.state?.subsidiary?.canUserOrderFOC;
            }
        },

        isSalesRep() {
            return (this.$store.getters.getUserType === 'M');
        },

        isCustomizedBasket() {
            return this.basket.orderType === Const.ORDER_TYPE.CUSTOMIZED_ORDER;
        },

        customerNo() {
            return this.$store.state.user._type === Const.ACCOUNT_TYPE_CUSTOMER ? this.$store.getters.getRealCustomerNo : null;
        },
    },

    created() {
        this.step = 1;

        if (this.customerNo) {
            this.selectedCustomer = { no: this.customerNo };
            this.step = 2;
        }

        this.loadCustomerFOCSettings();
    },

    methods: {
        async copyBasket(orderType) {
            try {
                this.step = 3;

                this.responseMessageStyle = {};
                this.responseMessageHtml = this.$t('copyBasket.copyingBasket');

                const { data } = await copyBasket({
                    basketNo: this.basket.no,
                    customerNo: this.selectedCustomerNo,
                    addressNo: this.selectedAddressNo,
                    orderType,
                });

                if (data.error) {
                    this.responseMessageStyle = { color: 'red' };
                    this.responseMessageHtml = 'BASKET COPY FAILED <br/>';
                    data.notExist.forEach(item => {
                        this.responseMessageHtml += `The item ${item.itemNo}-${item.colorCode} doesn't exist<br/>`;
                    });
                    data.SDP.forEach(item => {
                        this.responseMessageHtml += `The item ${item.itemNo}-${item.colorCode} ${item.itemName} was rejected by SDP<br/>`;
                    });
                } else {
                    this.responseMessageStyle = { color: 'green' };
                    this.responseMessageHtml = this.$t('copyBasket.successMessage');

                    this.$emit('basketCopied', data.basket);
                }
            } catch (error) {
                bugsnag.notify(error);

                this.responseMessageStyle = { color: 'red' };
                this.responseMessageHtml = 'An error occurred';
            } finally {
                this.step = 4;
            }
        },

        modalCancel() {
            this.$emit('close');
        },

        onCustomerSelected(customer) {
            this.selectedCustomer = customer;

            this.loadCustomerFOCSettings();
        },

        onAddressSelected(address) {
            this.selectedAddress = address;
        },

        async loadCustomerFOCSettings() {
            try {
                if (!this.selectedCustomerNo || (this.basket.orderType !== 'S')) {
                    return false; // ignore
                }

                this.loadingFOCSettings = true;

                const response = await getCustomerFreeOfChargeSettings(this.selectedCustomerNo);

                this.focSettings = response.data || {};
            } catch (error) {
                bugsnag.notify(error);
                this.focSettings = {};
            } finally {
                this.loadingFOCSettings = false;
            }
        },
    },
};
</script>
