<template>
    <div
        v-click-outside="hideResultsContainer"
        class="w-full min-w-max max-w-[44rem] ml-auto"
    >
        <form
            class="flex items-center"
            @submit.prevent="openSearchResults"
        >
            <select
                v-model="selectedOrderJourney"
                class="appearance-none text-white font-bold border rounded-tl-full rounded-bl-full h-[3.4rem] pl-6 pr-4 py-2.5 capitalize"
                :class="orderJourneyColor"
                @change="onOrderJourneyChange"
            >
                <option
                    v-for="orderJourney in orderJourneys"
                    :key="orderJourney.value"
                    :value="orderJourney.value"
                >
                    {{ $t(orderJourney.translationKey) }}
                </option>
            </select>
            <div class="w-full relative">
                <i class="absolute top-4 left-6 text-primary text-lg fa fa-search" />
                <input
                    v-model.trim="searchText"
                    :placeholder="$t('seasonSearch.placeholderSearch')"
                    class="bg-grey-light placeholder:text-primary border border-grey-light border-l-0 rounded-tr-full rounded-br-full focus:placeholder:text-grey h-[3.4rem] pl-16 pr-6 py-2 w-full"
                    type="search"
                    autocomplete="off"
                    @keyup.esc="hideResultsContainer"
                    @input="debouncedSearch"
                    @focus="showResultsContainer"
                >
            </div>
        </form>

        <div
            v-if="searchResultsVisible"
            class="absolute flex flex-col gap-6 text-primary bg-grey-lightest rounded-lg p-6 mr-1 overflow-hidden z-10 shadow-xl"
        >
            <p
                v-if="shipToAddress"
                class="capitalize"
            >
                <strong class="pr-2">{{ $t('seasonSearch.showingResultsForCustomer') }}:</strong>{{ shipToAddress.name }} {{ shipToAddress.no }}
            </p>

            <Spinner
                v-show="fetchingItemCollection"
                class="py-4"
                icon-css-class="fa-lg"
            />

            <div
                v-if="filteredItemCollection.length > 0"
                class="flex max-h-[calc(100vh-26rem)]"
            >
                <ul class="flex flex-col gap-4 w-full pb-4 overflow-auto">
                    <li
                        v-for="item in filteredItemCollection"
                        :key="`${item.itemNo}-${item.colorCode}`"
                        class="flex items-center gap-4 py-2 px-4 text-grey-dark cursor-pointer bg-white border border-grey-light hover:shadow"
                        @click.prevent.stop="goToProduct(item)"
                    >
                        <ProductImage
                            :brand="item.brand"
                            :url="item.imageUrl"
                            decoding="async"
                            loading="lazy"
                            :width="150"
                            :height="85"
                        />
                        <div>
                            <h2 class="h5 text-primary font-bold">
                                {{ item.itemName }}
                            </h2>
                            <p>{{ $t(`general.gender${item.gender}`) }} {{ item.itemNo }} {{ item.colorCode }}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <ButtonPlain
                v-if="filteredItemCollection.length > 0"
                class="w-full py-2 underline"
                @click.native.prevent="onShowAllResults"
            >
                Show all results
            </ButtonPlain>

            <p
                v-if="showNoResultsFoundMessage"
                class="text-center"
            >
                {{ $t('general.tableFilterNoResults') }}
            </p>
        </div>
    </div>
</template>

<script>
import ButtonPlain from '@/components/button/ButtonPlain.vue';
import { bugsnag } from '@/libs/bugsnag.js';
import { getCurrentBasket } from '@/modules/baskets/baskets.api.js';
import { debounce } from '@/utils/generic.js';
import { Const } from '@/utils/constants.js';
import _ from 'lodash';
import vClickOutside from 'v-click-outside';
import Spinner from '@/components/spinner/Spinner.vue';
import { getAddressItemCollectionForSearch } from '@/components/search/season-search.api.js';
import FuzzySearch, { CollectionSeasonSearcher } from '@/components/search/fuzzy-search.js';
import ProductImage from '@/components/product-image/ProductImage.vue';

const fuzzySearch = new FuzzySearch();

export default {
    name: 'SeasonSearch',

    components: {
        ButtonPlain,
        Spinner,
        ProductImage,
    },

    directives: {
        clickOutside: vClickOutside.directive,
    },

    props: {
        shipToAddress: {
            type: Object,
            required: true,
        },

        customerNo: {
            type: String,
            required: true,
        },

        isSalesRep: {
            type: Boolean,
            required: true,
        },

        isCustomer: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            fetchCount: 0,
            fetchingItemCollection: false,
            itemCollection: [],
            maxResults: 5,
            minSearchTermLength: 2,
            searchResultsVisible: false,
            searchActive: false,
            searchText: '',
            selectedOrderJourney: null,
            debouncedSearch: null,
            fuzzyCompareFunc: null,
        };
    },

    computed: {
        orderJourneys() {
            if (!this.preOrderFromTemplateEnabled) {
                return this.availableOrderJourneys;
            }

            return this.availableOrderJourneys.filter(item => item.value !== Const.ORDER_JOURNEY.PRE_ORDER);
        },

        availableOrderJourneys() {
            return this.$store.getters.availableOrderJourneys;
        },

        orderJourneyColor() {
            const orderJourneyMap = {
                [Const.ORDER_JOURNEY.AT_ONCE]: 'bg-secondary border-secondary',
                [Const.ORDER_JOURNEY.PRE_ORDER]: 'bg-green-dark border-green-dark',
            };

            return this.preOrderFromTemplateEnabled ? orderJourneyMap[Const.ORDER_JOURNEY.AT_ONCE] : orderJourneyMap[this.selectedOrderJourney];
        },

        basket() {
            return this.$store.getters.getCurrentBasket;
        },

        filteredItemCollection() {
            if (!this.searchText) {
                return [];
            }

            let result = this.itemCollection;

            if (this.itemCollection.length === 1) {
                return this.itemCollection;
            }

            const parts = fuzzySearch.splitText(this.searchText);

            if (parts.length) {
                result = result.filter(item => (item.itemMatch = this.fuzzyCompareFunc.evaluate(item, parts)) > 0);
                result = _.orderBy(result, 'itemMatch', 'desc');
            }

            return result.slice(0, this.maxResults);
        },

        showNoResultsFoundMessage() {
            return this.searchActive && !this.fetchingItemCollection && this.filteredItemCollection.length === 0;
        },

        preOrderFromTemplateEnabled() {
            return this.$store.getters.getSubsidiarySettings.offersEnabled;
        },
    },

    watch: {
        'basket.orderJourney'() {
            this.resolveOrderJourney();
        },
    },

    async created() {
        this.resolveOrderJourney();

        try {
            const translateCache = {};

            this.fuzzyCompareFunc = new CollectionSeasonSearcher(text => {
                const { locale } = this.$i18n;

                if (!_.has(translateCache, `${locale}.${text}`)) {
                    _.set(translateCache, `${locale}.${text}`, this.$t(text));
                }

                return _.get(translateCache, `${locale}.${text}`);
            });

            await this.doSearch();

            this.debouncedSearch = debounce(this.doSearch, 300);
        } catch (error) {
            bugsnag.notify(error);
        }
    },

    methods: {
        resolveOrderJourney() {
            this.selectedOrderJourney = this.basket.offerId ? Const.ORDER_JOURNEY.AT_ONCE : this.basket.orderJourney;
        },

        clearSearchResults() {
            this.searchActive = false;
            this.itemCollection = [];
        },

        showResultsContainer() {
            document.querySelector('body').classList.add('no-scroll');

            this.searchResultsVisible = true;
        },

        hideResultsContainer() {
            document.querySelector('body').classList.remove('no-scroll');

            this.searchResultsVisible = false;
            this.searchText = '';

            this.clearSearchResults();
        },

        async doSearch() {
            // Don't show search results for pre-order from template as we don't want users to navigate to PDP. TODO: we should have a better long term solution
            if ((this.preOrderFromTemplateEnabled && this.selectedOrderJourney === Const.ORDER_JOURNEY.PRE_ORDER) || this.searchText.length < this.minSearchTermLength) {
                return;
            }

            this.clearSearchResults();
            this.showResultsContainer();

            await this.getItemCollection();
        },

        async getItemCollection() {
            if (!this.searchText || !this.selectedOrderJourney) {
                return;
            }

            this.clearSearchResults();

            this.fetchingItemCollection = true;
            this.searchActive = true;

            try {
                const { data: { collection } } = await getAddressItemCollectionForSearch({
                    customerNo: this.customerNo,
                    addressNo: this.shipToAddress.no,
                    season: this.selectedOrderJourney,
                    query: this.searchText,
                    partnerNo: this.basket.partnerNo,
                });

                this.itemCollection = collection;
            } catch (error) {
                bugsnag.notify(error);
            } finally {
                this.fetchingItemCollection = false;
            }
        },

        async goToProduct(item) {
            try {
                if (this.basket.orderJourney !== this.selectedOrderJourney) {
                    const { data } = await getCurrentBasket({ orderJourney: this.selectedOrderJourney });

                    await this.$store.dispatch('updateBasket', data);
                }

                const routePath = `/orders/${this.basket.no}/products/${item.itemNo}?colorCode=${item.colorCode}`;

                if (routePath === this.$route.path) {
                    return;
                }

                this.hideResultsContainer();

                this.searchText = '';

                await this.$router.push(routePath);
            } catch (error) {
                bugsnag.notify(error);
            }
        },

        async onOrderJourneyChange() {
            try {
                const { data } = await getCurrentBasket({ orderJourney: this.selectedOrderJourney, customerNo: this.isSalesRep ? this.customerNo : null });

                await this.$store.dispatch('updateBasket', data);
                await this.getItemCollection();
            } catch (error) {
                bugsnag.notify(error);
            }
        },

        onShowAllResults() {
            this.openSearchResults();
        },

        async openSearchResults() {
            try {
                this.$store.commit('updateSearchState', this.searchText);

                this.hideResultsContainer();

                const routePath = `/orders/${this.basket.no}`;

                if (routePath === this.$route.path) {
                    return;
                }

                this.searchText = '';

                await this.$router.push(routePath);
            } catch (error) {
                bugsnag.notify(error);
            }
        },
    },
};
</script>

<style>
.no-scroll {
    height: 100%;
    overflow: hidden;
}
</style>
